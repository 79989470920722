import Reflection from '@/stores/models/reflection'

const title = '"Impossible" Lists'
const html = `<div>I struggled to think of an idea to write about this week so I googled a random topic generator.</div><div><br/></div><div>The output was the question, "What are some things you want to accomplish before you die?"</div><div><br/></div><div>A couple years ago I made an "impossible list" with a friend.</div><div><br/></div><div>It's exactly what it sounds like, a list of aspirational goals that seem impossible.</div><div><br/></div><div>Instead of just adding easy things to a bucket list like traveling to a foreign country, the impossible list pushes you to think about personal growth.</div><div><br/></div><div>Here are a few items on my list:</div><ul><li><div>Speak a foreign language fluently</div></li><li><div>Give commencement address at Michigan</div></li><li><div>Write a book</div></li><li><div>Build a school</div></li></ul><div>I'm nowhere close to any of them, but they'll always be on my list.</div><div><br/></div><div>I can take small steps toward some of them.</div><div><br/></div><div>Someday I'll check one off.</div><div><br/></div><div>What was once impossible will have become reality.</div><div><br/></div><div>What do you think is impossible now that you want to do before you die?</div>`

export default new Reflection({
  html,
  id: 'impossible-lists',
  tags: null,
  title,
  words: 166,
  written: '4/12/22'
})
