import Reflection from '@/stores/models/reflection'
import {PHILOSOPHY} from '@/tags'

const title = 'Infinite Learning'
const html = `<div>Human beings are learning machines.</div><div><br/></div><div>We have the unique, powerful ability to create explanations.</div><div><br/></div><div>I just finished <i>The Beginning of Infinity</i> by David Deutsch, and it's probably the most inspiring book I've ever read.</div><div><br/></div><div>My favorite word in it is fallibilism, the concept that there are no authoritative sources of knowledge.</div><div><br/></div><div>No explanation is absolutely true.</div><div><br/></div><div>To continue to learn, we can't blindly accept the ideological status quo.</div><div><br/></div><div>We need a culture of criticism.</div><div><br/></div><div>The motto of the Royal Society in London is <i>Nullius in verba</i>: 'Take no one's word for it.'</div><div><br/></div><div>Human creativity, conjecture, and error correction lead us forward.</div><div><br/></div><div>We will always be at the very beginning of the journey.</div>`

export default new Reflection({
  html,
  id: 'infinite-learning',
  tags: [PHILOSOPHY],
  title,
  words: 112,
  written: '9/6/22'
})
