import React from 'react'
import cx from 'classnames'
import { Loader } from '@/components'
import PropTypes from 'prop-types'

import './Button.scss'

const Button = (props) => {
  const {
    isLoading,
    text,
    ...restProps
  } = props
  const className = cx('Button', props.className)
  return (
    <button {...restProps} className={className}>
      {isLoading ? (
        <Loader isBlack size={14} />
      ) : (
        text || props.children
      )}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string
}

export default Button
