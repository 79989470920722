import Reflection from '@/stores/models/reflection'

const title = 'Tunnel Vision'
const html = `<div>What seems ubiquitous to you may just result from having a narrow, human vantage point.</div><div><br/></div><div>We ignore our blind spots, everything outside of our awareness.</div><div><br/></div><div>Daniel Kahneman coined the abbreviation WYSIATI: What You See Is All There Is.</div><div><br/></div><div>We're limited by our senses, our ability to process information, and time.</div><div><br/></div><div>Large sample sizes are required to prove statistical significance, but each perspective always corresponds to an N of 1.</div><div><br/></div><div>Even if you look for other perspectives, you see them through your own eyes.</div><div><br/></div><div>The websites you visit, the people you talk to, what you pay attention to — they're all unique to you.</div><div><br/></div><div>AI systems tailor digital content specifically for you.</div><div><br/></div><div>The world you see is not the world I see.</div><div><br/></div><div>We can leverage technology to squeeze as much information out of the world as possible, but we're still limited by our biological capabilities.</div><div><br/></div><div>Everyone has a sort of tunnel vision.</div><div><br/></div><div>What you see is not all there is.</div><div><br/></div><div>Widen your aperture.</div>`

export default new Reflection({
  html,
  id: 'tunnel-vision',
  tags: null,
  title,
  words: 162,
  written: '2/8/23'
})
