import Reflection from '@/stores/models/reflection'

const title = '200-Hour Yoga Teacher Training'
const html = `<div>I flew across the Atlantic last month to take Dylan Werner's 200-Hour yoga teacher training in Germany.</div><div><br/></div><div>I couldn't have imagined a better experience.</div><div><br/></div><div>Over the last 9 years since I went to my first class in 2014, yoga has changed my life.</div><div><br/></div><div>I chose to take this training to overcome my public speaking phobia, develop a self practice, and share my practice with others.</div><div><br/></div><div>Dylan says that humility is not thinking less of yourself, it's thinking of yourself less.</div><div><br/></div><div>I met incredible people, formed lifelong friendships, and have overflowing love and gratitude in my heart.</div>`

export default new Reflection({
  html,
  id: '200-hour-yoga-teacher-training',
  tags: null,
  title,
  words: 96,
  written: '7/4/23'
})
