import Reflection from '@/stores/models/reflection'

const title = 'Jet Lag'
const html = `<div>I flew from Los Angeles to Munich yesterday.</div><div><br/></div><div>The plane took off at 5pm in LA and landed 11 hours later around 1pm local time in Germany.</div><div><br/></div><div>I couldn't sleep on the plane, and by the evening I was pretty delirious.</div><div><br/></div><div>I passed out around 8pm but was only able to sleep for a fragmented 4 hours.</div><div><br/></div><div>There's no way to completely avoid jet lag, but you can minimize the harm by getting as much sleep before the trip as possible.</div><div><br/></div><div>After this experience, business class is so much more enticing.</div><div><br/></div><div>Getting some shuteye on the flight would have mitigated the worst aspects of international travel.</div><div><br/></div><div>Losing sleep takes a serious toll on your health.</div>`

export default new Reflection({
  html,
  id: 'jet-lag',
  tags: null,
  title,
  words: 115,
  written: '6/11/23'
})
