import Reflection from '@/stores/models/reflection'
import {PHYSICS} from '@/tags'

const title = 'James Webb Space Telescope'
const html = `<img src="https://i.imgur.com/n4r1Lzs.jpg"><div><br/></div><div>NASA just released <a href="https://www.nasa.gov/image-feature/goddard/2022/nasa-s-webb-delivers-deepest-infrared-image-of-universe-yet">an image</a> from the James Webb Space Telescope of a galaxy cluster that cosmologists estimate is 4.6 billion years old.</div><div><br/></div><div>The image covers "a patch of sky approximately the size of a grain of sand held at arm’s length."</div><div><br/></div><div>This <a href="https://www.youtube.com/watch?v=aICaAEXDJQQ" rev="en_rl_none">incredible video</a> shows the engineering involved in building the telescope.</div><div><br/></div><div>When looking at the vast number of galaxies in such an infinitesimal part of the sky, I wonder about the <a href="https://waitbutwhy.com/2014/05/fermi-paradox.html">Fermi Paradox</a>.</div><div><br/></div><div>Will we ever connect with extraterrestrial life?</div>`

export default new Reflection({
  html,
  id: 'james-webb-space-telescope',
  tags: [PHYSICS],
  title,
  words: 82,
  written: '7/12/22'
})
