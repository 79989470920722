import React from 'react'
import Img from 'react-image'
import { Loader } from '@/components'

import './Image.scss'

const Image = (props) => {
  const { src, ...restProps } = props
  return (
    <Img
      {...restProps}
      src={src}
      container={(children) => <div className='Image'>{children}</div>}
      loader={<Loader />}
    />
  )
}

export default Image
