import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Caffeine Crashes'
const html = `<div>I drank a bit too much coffee last week after traveling and adjusting to the 3 hour time difference in Kona.</div><div><br/></div><div>If you deprive yourself of sleep, you can't really fix it by drinking coffee.</div><div><br/></div><div>It may help for a few hours, but the more you drink the harder you'll crash.</div><div><br/></div><div>Being sleep deprived is no fun, but you don't need to exacerbate it.</div><div><br/></div><div>The only sustainable solution is rest.</div><div><br/></div><div>Prioritize quality sleep.</div>`

export default new Reflection({
  html,
  id: 'caffeine-crashes',
  tags: [HEALTH],
  title,
  words: 73,
  written: '10/11/22'
})
