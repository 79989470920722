import Reflection from '@/stores/models/reflection'

const title = 'AI Risk'
const html = `<div>With recent advances in AI, especially GPT-4, some people are concerned about catastrophic risk.</div><div><br/></div><div>Eliezer Yudkowsky, an AI researcher, believes that AI will make humans extinct.</div><div><br/></div><div>While that premise may seem ridiculous, it's far from impossible.</div><div><br/></div><div>An intelligence far superior to the smartest human can learn orders of magnitude faster.</div><div><br/></div><div>Silicon-based neural networks aren't biological brains, but we don't know enough about consciousness to predict its emergence.</div><div><br/></div><div>If humans create an artificial superintelligence, it must be aligned with our interests.</div><div><br/></div><div>I'm an techno-optimist, but caution is warranted.</div>`

export default new Reflection({
  html,
  id: 'ai-risk',
  tags: null,
  title,
  words: 87,
  written: '4/18/23'
})
