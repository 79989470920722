import axios from 'axios'

let baseURL = process.env.API_URL

const api = axios.create({
  baseURL,
  timeout: 50000,
  headers: {}
})

let options = {
  handleResponse: res => Promise.resolve(res),
  handleErrors: err => {
    if (err.response) return Promise.reject(err.response)
    return Promise.reject(err)
  }
}

const request = {
  options (params) {
    options = { ...options, ...params }
  },

  handleResponse: res => res,

  get: (url, config) =>
    api.get(url, config)
      .then(options.handleResponse)
      .catch(options.handleErrors),

  delete: (url, config) =>
    api.delete(url, config)
      .then(options.handleResponse)
      .catch(options.handleErrors),

  put: (url, data, config) =>
    api.put(url, data, config)
      .then(options.handleResponse)
      .catch(options.handleErrors),

  post: (url, data, config) =>
    api.post(url, data, config)
      .then(options.handleResponse)
      .catch(options.handleErrors),

  patch: (url, data, config) =>
    api.patch(url, data, config)
      .then(options.handleResponse)
      .catch(options.handleErrors)
}

export default request
