import Reflection from '@/stores/models/reflection'

const title = 'Seed Oils'
const html = `<div>More people are becoming aware that processed oils like canola oil are bad for you.</div><div><br/></div><div>People form their identities around diets, so many never change them.</div><div><br/></div><div>Or they simply don't care.</div><div><br/></div><div>In modern America, it's difficult to eat out at a restaurant without consuming processed seed oils.</div><div><br/></div><div>Capitalism incentivizes using cheaper ingredients to increase the bottom line.</div><div><br/></div><div>The cost of a sick nation in the long term actually creates an incentive to prioritize health, but short term thinking has prevailed.</div>`

export default new Reflection({
  html,
  id: 'seed-oils',
  tags: null,
  title,
  words: 80,
  written: '8/15/23'
})
