import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Fish Bones'
const html = `<div>Use tweezers to debone fish before cooking.</div><div><br/></div><div>While eating sockeye salmon this weekend, I felt a tiny pin bone while swallowing a piece of fish.</div><div><br/></div><div>It can get stuck in your throat — not a fun experience.</div><div><br/></div><div>Be careful when eating fish.</div>`

export default new Reflection({
  html,
  id: 'fish-bones',
  tags: [HEALTH],
  title,
  words: 42,
  written: '11/22/22'
})
