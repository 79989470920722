import Reflection from '@/stores/models/reflection'
import {HISTORY} from '@/tags'

const title = 'Black History Month'
const html = `<div>Several years ago, I decided that I'd read one book every February for Black History Month.</div><div><br/></div><div>I've since read these great books:</div><ul><li><div><i>I Know Why the Caged Bird Sings</i> by Maya Angelou</div></li><li><div><i>Roots</i> by Alex Haley</div></li><li><div><i>The Autobiography of Malcolm X</i></div></li><li><div><i>Up from Slavery</i> by Booker T. Washington</div></li><li><div><i>Between the World and Me</i> by Ta-Nehisi Coates</div></li></ul><div>This year I chose Frederick Douglass' autobiography.</div><div><br/></div><div>I've kept a printed quote of his on my bookshelf that says, "Once you learn to read, you'll be forever free."</div><div><br/></div><div>I know I'll never fully understand what it's like to live in the shoes of an African American, but I'll continue to learn and gain perspective.</div>`

export default new Reflection({
  html,
  id: 'black-history-month',
  tags: [HISTORY],
  title,
  words: 115,
  written: '2/1/22'
})
