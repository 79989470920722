import Reflection from '@/stores/models/reflection'

const title = 'Rent'
const html = `<div>I've been looking for my own place, and I can see why there is a housing crisis in California.</div><div><br/></div><div>The market is so damn high.</div><div><br/></div><div>I'm fortunate to be able to afford my own place, but it's crazy how much apartments are going for these days.</div><div><br/></div><div>There's no incentive for real estate developers to create affordable housing, so they invest in high-income neighborhoods or gentrify low-income neighborhoods.</div><div><br/></div><div>Landlords invest as much as possible into properties to maximize the amount they can charge tenants.</div><div><br/></div><div>There's no countervailing force because housing is a relatively inelastic good — people need a place to live.</div><div><br/></div><div>As an individual, the best thing you can do for yourself is to live within your means.</div><div><br/></div><div>Don't rent a place that's out of your budget.</div><div><br/></div><div>And maybe invest in real estate.</div>`

export default new Reflection({
  html,
  id: 'rent',
  tags: null,
  title,
  words: 133,
  written: '8/3/21'
})
