import types from './types'

const cacheBlogPost = (blogPost) => ({
  type: types.CACHE_BLOG_POST,
  payload: blogPost
})

const cacheBlogPosts = (blogPosts) => ({
  type: types.CACHE_BLOG_POSTS,
  payload: blogPosts
})

export default {
  cacheBlogPost,
  cacheBlogPosts
}
