import Reflection from '@/stores/models/reflection'

const title = 'Noise'
const html = `<div>The University of Chicago School of Media Theory blog describes noise as "random, unpredictable, and undesirable signals, or changes in signals, that mask desired information content." <sup>[1]</sup></div><div><br/></div><div>The determining factor that defines noise is your desire to sense it.</div><div><br/></div><div>When you’re not seeking information, everything is noise.</div><div><br/></div><div>When you seek information, why do you seek it?</div><div><br/></div><div>From my experience, free will does not dictate our thoughts, so what drives idea propagation?</div><div><br/></div><div>Memetic evolutionary theory proposes that ideas, or memes, similar to genes in genetic evolutionary theory, want to reproduce and survive.</div><div><br/></div><div>All information – DNA, writing, music, science, etc. – is competing for survival.</div><div><br/></div><div>Reflect on what ideas you propagate.</div><div><br/></div><div>Are you making noise or signal? For whom?</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> <a href="https://lucian.uchicago.edu/blogs/mediatheory/keywords/noise/">https://lucian.uchicago.edu/blogs/mediatheory/keywords/noise/</a></div>`

export default new Reflection({
  html,
  id: 'noise',
  tags: null,
  title,
  words: 124,
  written: '8/4/20'
})
