import Reflection from '@/stores/models/reflection'

const title = 'Origin of Thoughts'
const html = `<div>What will you think about in 2 minutes?</div><div><br/></div><div>Is the future state of your brain deterministic?</div><div><br/></div><div>If we know the current state of the universe, and the laws of physics are unchanging, is the next moment predetermined?</div><div><br/></div><div>Scientists have not formed a consensus on what a thought really is.</div><div><br/></div><div>Life is exciting because nobody knows what will happen next.</div>`

export default new Reflection({
  html,
  id: 'origin-of-thoughts',
  tags: null,
  title,
  words: 59,
  written: '3/7/23'
})
