import Reflection from '@/stores/models/reflection'

const title = 'Death Note'
const html = `<div>Two of my coworkers recently said they loved this Japanese anime show Death Note.</div><div><br/></div><div>Originally a manga series, it's about a guy who finds a notebook with the power to kill people by writing their name in it.</div><div><br/></div><div>The story revolves around Light, the protagonist/antagonist who finds the notebook, and L, the detective trying to catch him.</div><div><br/></div><div>Both characters are so cunning — their strategies and thought processes always surprise me.</div><div><br/></div><div>The soundtrack is also great and the show is pretty funny, especially Ryuk the Shinigami.</div><div><br/></div><div>Storytelling is one of the most powerful tools in the world.</div>`

export default new Reflection({
  html,
  id: 'death-note',
  tags: null,
  title,
  words: 97,
  written: '8/22/23'
})
