import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getMonthYearStr } from '@/utils/helpers'

import './BlogCard.scss'

const BlogCard = (props) => {
  const { blogPost } = props
  const {
    featuredImage,
    published,
    slug,
    title
  } = blogPost
  return (
    <div className='BlogCard'>
      <Link to={`/blog/${slug}`}>
        <img src={featuredImage} />
        {/*
          <div className='BlogCard__wordCount'>
            {`${wordCount} words`}
          </div>
        */}
        <div className='BlogCard__info'>
          <span className='BlogCard__info__title'>{title}</span>
          <span className='BlogCard__info__date'>{getMonthYearStr(published)}</span>
        </div>
      </Link>
    </div>
  )
}

BlogCard.propTypes = {
  blogPost: PropTypes.object
}

export default BlogCard
