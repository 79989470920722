import Reflection from '@/stores/models/reflection'

const title = 'Priorities'
const html = `<div>There’s a wonderful story about a professor who comes to class with an empty jar and a bunch of rocks.</div><div><br/></div><div>He drops the rocks into the jar and then asks his class, "Is the jar full?"</div><div><br/></div><div>They respond, "yes."</div><div><br/></div><div>He then lifts up a container of small pebbles, and begins pouring them into the jar, filling the space between the rocks.</div><div><br/></div><div>He again asks if the jar is full, and they say yes.</div><div><br/></div><div>He then starts pouring sand into the jar, shaking it until the sand reaches every last crevice.</div><div><br/></div><div>The class again says that the jar is full.</div><div><br/></div><div>Finally, he pours water into the jar until he fills it to the brim.</div><div><br/></div><div>The jar is a metaphor for life, and the rocks symbolize the most important things that contribute to your well-being and happiness – family, health, relationships, your purpose.</div><div><br/></div><div>The pebbles are also necessary, but less important, like work and school. </div><div><br/></div><div>The sand is everything else, the insignificant things.</div><div><br/></div><div>If you fill up too much of your life with trivial matters, you won’t have room for what’s most important.</div><div><br/></div><div>Life is finite.</div><div><br/></div><div>Make sure you take care of the big things first.</div>`

export default new Reflection({
  html,
  id: 'priorities',
  tags: null,
  title,
  words: 194,
  written: '10/13/20'
})
