import Reflection from '@/stores/models/reflection'
import {COMMUNITY} from '@/tags'

const title = 'Friendship'
const html = `<div>I spent Memorial Day with 2 friends that I've known since kindergarten.</div><div><br/></div><div>At this point in our lives, we're practically family.</div><div><br/></div><div>As the saying goes, friends are the family you choose.</div><div><br/></div><div>There is nothing more important in life than relationships.</div>`

export default new Reflection({
  html,
  id: 'friendship',
  tags: [COMMUNITY],
  title,
  words: 41,
  written: '6/1/21'
})
