import Reflection from '@/stores/models/reflection'
import { ENERGY, ENVIRONMENT, POLITICS } from '@/tags'

const title = 'Planetary Responsibility'
const html = `<p>The global population is approaching 8 billion people.</p> <p>Every individual doesn&apos;t have a significant effect on the environment, but collectively we do.</p> <p>The young generations seem to understand the obvious &mdash; that we&apos;re running out of time.</p> <p>We can&apos;t keep extracting finite resources indefinitely.</p> <p>Some people just don&apos;t get it, or worse, they <a href="https://zachgallagher.com/reflections/2019/predatory-delay">don&apos;t care</a>.</p> <p>Either they don&apos;t care because they&apos;ll be dead by the time the problem becomes serious, or they lack empathy.</p> <p>Fortunately, most people support the energy transition.</p> <p>We are stewards of the Earth.</p> <p>The effort to reverse climate change must be global.</p> <p>International coalitions are necessary, not an &quot;America first&quot; mindset like some declining politicians like to say.</p> <p>The planet will be fine without us, but we won&apos;t survive and prosper if it&apos;s not in a stable condition.</p> <p>We&apos;ve already decimated most species on the planet.</p> <p>Let&apos;s try not to do it to ourselves, too.</p> <p>Even if we journey into space, Earth will always be our home.</p>`

export default new Reflection({
  html,
  id: 'planetary-responsibility',
  tags: [ENERGY, ENVIRONMENT, POLITICS],
  title,
  words: 161,
  written: '12/15/20'
})
