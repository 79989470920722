import Reflection from '@/stores/models/reflection'

const title = 'Splendor'
const html = `<div>For as long as I can remember, my dad has given me and my brother a Zen daily calendar every December.</div><div><br/></div><div>Several years ago, I began taking photos of the quotes or koans that resonated with me.</div><div><br/></div><div>Feel free to explore <a href="https://photos.app.goo.gl/sCmgX5RX2rUMDEJw6">the photo album</a> and leave a comment if any of them resonate with you.</div><div><br/></div><div>After reading yesterday’s quote, copied below, I thought of the word splendor and began writing this reflection.</div><div><br/></div><div>"Our goal should be to live life in radical amazement … get up in the morning and look at the world in a way that takes nothing for granted. Everything is phenomenal; everything is incredible; never treat life casually."</div><div>– Abraham Joshua Heschel</div><div><br/></div><div>Since most of modern society lives in urban cities, the light pollution prevents us from seeing the stars.</div><div><br/></div><div>We disconnect from the universe, the galaxies, the cosmos.</div><div><br/></div><div>Although we are always in touch with our surroundings, we lose awareness of just how far those surroundings reach.</div><div><br/></div><div>It’s easy to get lost in mundane day-to-day life.</div><div><br/></div><div>Remember to appreciate the magnificence of it all.</div>`

export default new Reflection({
  html,
  id: 'splendor',
  tags: null,
  title,
  words: 185,
  written: '7/28/20'
})
