import Reflection from '@/stores/models/reflection'

const title = 'Mundane'
const html = `<div>Yesterday morning during my yoga practice I tweaked my neck a bit.</div><div><br/></div><div>Afterwards I thought it was funny because of how quickly my state of mind shifted.</div><div><br/></div><div>Worrying thoughts crept into my mind.</div><div><br/></div><div>What if this lasts for several days?</div><div><br/></div><div>What if I slipped a disc?</div><div><br/></div><div>The moment I found humor in the pain, I realized how simply amazing life is when everything is just going normally.</div><div><br/></div><div>There is so much joy to find in even the most ordinary of days.</div><div><br/></div><div>Even if the weather is hazy, and there’s nothing you would consider eventful going on.</div><div><br/></div><div>Those are the times to remember how lucky we are to be alive and feel gratitude for being wherever we are on our path.</div><div><br/></div><div>When you ask someone how they’ve been and they respond with, "Good, nothing new," no news IS good news.</div><div><br/></div><div>When there’s no news, things are usually going okay.</div><div><br/></div><div>Our hearts are beating.</div><div><br/></div><div>Our lungs are functioning.</div><div><br/></div><div>We are filling our surrounding space with life.</div><div><br/></div><div>We think we have to wait until the next big thing – a vacation, an event, etc. – to get the most out of life.</div><div><br/></div><div>It’s easy to miss what’s right in front of us.</div><div><br/></div><div>Right here, right now.</div><div><br/></div><div>"The sacred is in the ordinary… it is to be found in one’s daily life, in one’s neighbors, friends, family, in one’s own back yard."</div><div>– Abraham Maslow</div>`

export default new Reflection({
  html,
  id: 'mundane',
  tags: null,
  title,
  words: 228,
  written: '9/29/20'
})
