import Reflection from '@/stores/models/reflection'

const title = 'Carbon Dioxide Tolerance'
const html = `<div>During my yoga teacher training last month, we practiced pranayama every morning at 6am.</div><div><br/></div><div>Most days, we did breath sequences that included long holds to build up CO<sub>2</sub> tolerance.</div><div><br/></div><div>Holding your breath can induce anxiety and panic.</div><div><br/></div><div>I've continued the practice on my own since returning home, and I've already noticed differences in my ability to relax and hold my breath longer.</div><div><br/></div><div>To be comfortable in uncomfortable situations, you have to put yourself into uncomfortable situations.</div>`

export default new Reflection({
  html,
  id: 'carbon-dioxide-tolerance',
  tags: null,
  title,
  words: 76,
  written: '7/25/23'
})
