import Reflection from '@/stores/models/reflection'

const title = `Beginner's Mind`
const html = `<div>When you begin to learn a new skill, you don't have any ingrained habits or muscle memory.</div><div><br/></div><div>When you're an "expert," you may become resistant to new ideas.</div><div><br/></div><div>To quote the Zen master Shunryu Suzuki: "In the beginner's mind there are many possibilities, but in the expert's there are few."</div><div><br/></div><div>In order to improve, you have to accept your role as a student and bring awareness each moment to reach beyond your comfort zone.</div>`

export default new Reflection({
  html,
  id: 'beginners-mind',
  tags: null,
  title,
  words: 74,
  written: '11/7/23'
})
