import Reflection from '@/stores/models/reflection'
import {COMMUNITY} from '@/tags'

const title = 'Dormant Ties'
const html = `<div>Who are the people in your life that you care about but haven’t seen or spoken with in a long time?</div><div><br/></div><div>If the relationship is important to you, by not reaching out you are avoiding short-term discomfort but you're missing out on life.</div><div><br/></div><div>Nothing is more important than relationships.</div><div><br/></div><div>You may have anxiety about calling someone because it’s been too long or you’ve grown apart.</div><div><br/></div><div>There is never a perfect time.</div><div><br/></div><div>Reconnect.</div>`

export default new Reflection({
  html,
  id: 'dormant-ties',
  tags: [COMMUNITY],
  title,
  words: 73,
  written: '8/18/20'
})
