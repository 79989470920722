import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Books, Select } from '@/components'
import books from '@/books'
import reflections from '@/reflections'
import { TAGS_OPTIONS } from '@/tags'
import { wordCountText } from '@/utils/helpers'

import './TagsPage.scss'

export default class TagsPage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  onChangeTag = (tag) => {
    const { history } = this.props
    history.replace(`/tags/${tag.value}`)
  }
  render () {
    const { match } = this.props
    const { params } = match
    const { tag } = params
    const matchedBooks = books.filter(book => book.tags.indexOf(tag) !== -1)
    const matchedReflections = Object.keys(reflections)
      .filter(refId => reflections[refId].tags && reflections[refId].tags.indexOf(tag) !== -1)
      .map(refId => reflections[refId])
    return (
      <div className='TagsPage'>
        <h1>Tags</h1>
        <Select
          label='Selected Tag'
          onChange={this.onChangeTag}
          options={TAGS_OPTIONS}
          name='tag'
          placeholder='Select Tag'
          value={TAGS_OPTIONS.filter(option => option.value === tag)[0]}
        />
        <hr />
        <br />
        <div>
          <h2>Books</h2>
          {matchedBooks.length ? <Books books={matchedBooks} /> : <span className='TagsPage__books__empty'>N/A</span>}
        </div>
        <div>
          <h2>Reflections</h2>
          {matchedReflections.length ? (
            <div className='TagsPage__reflections'>
              {matchedReflections.map(reflection => (
                <div key={`TagsPage-${reflection.id}`} className='TagsPage__reflections__reflection'>
                  <Link to={`/reflections/20${reflection.written.slice(-2)}/${reflection.id}`}>{reflection.title}</Link>
                  <span className='TagsPage__reflections__reflection__info'>
                    ({wordCountText(reflection.words)})&nbsp;&nbsp;{reflection.written}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <span className='TagsPage__reflections__empty'>N/A</span>
          )}
        </div>
      </div>
    )
  }
}
