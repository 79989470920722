import Reflection from '@/stores/models/reflection'

const title = 'Attention'
const html = `<div>Attention is your most valuable asset.<br/></div><div><br/></div><div>You can produce it instantaneously, but holding it for an extended period requires mastery of the mind.</div><div><br/></div><div>If you’ve tried meditation, you understand its fragility.</div><div><br/></div><div>Attention on the breath — slow inhales and slow exhales — stealthily shifts to a barrage of thoughts concerning recent or upcoming events.</div><div><br/></div><div>Returning to the breath after getting lost in thought is the practice of meditation.</div><div><br/></div><div><span>    </span><i>Attention = time × focus</i></div><div><br/></div><div>Time without focus is wasted.</div><div><br/></div><div>Today, attention is more critical than ever.</div><div><br/></div><div>The advertising industry relentlessly hunts to seize our attention.</div><div><br/></div><div>Mobile apps gain more function every day to battle for it.</div><div><br/></div><div>In our era of knowledge work, removing distractions differentiates success and failure.</div><div><br/></div><div>Open-office plans seem to promote communication and collaboration, but the cost of lost concentration makes them counterproductive.</div><div><br/></div><div>Even in distributed companies, communication tools like Slack can disrupt your workflow.</div><div><br/></div><div>Multi-tasking appears to be a shortcut, but context switching harms more than it helps.</div><div><br/></div><div>Protect your attention from notifications.</div><div><br/></div><div>Cultivate focus.</div><div><br/></div><div>Be intentional with your attention.</div>`

export default new Reflection({
  html,
  id: 'attention',
  tags: null,
  title,
  words: 170,
  written: '1/22/20'
})
