import Reflection from '@/stores/models/reflection'

const title = 'Masks Off'
const html = `<div>Today in Los Angeles, we're finally allowed to stop wearing masks.</div><div><br/></div><div>The pandemic is over.</div><div><br/></div><div>People will look back on this past year as a collective reset.</div><div><br/></div><div>We paused, we contemplated, and we shifted priorities. </div><div><br/></div><div>Today I'm more grateful and present than I was in March 2020.</div><div><br/></div><div>I'll never take for granted the time I get to spend with people.</div>`

export default new Reflection({
  html,
  id: 'masks-off',
  tags: null,
  title,
  words: 60,
  written: '6/15/21'
})
