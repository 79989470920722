import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Isolation'
const html = `<div>The COVID-19 pandemic accelerated the shift toward remote work.</div><div><br/></div><div>While I think <a href="https://zachgallagher.com/reflections/2020/distributed-work" rev="en_rl_none">distributed work</a> is advantageous to both companies and employees, it can have a dark side.</div><div><br/></div><div>While I'm more productive at home, too much time alone has a negative impact.</div><div><br/></div><div>Since I live on my own and my job is to write code, I can easily let a few days slip by without interacting with another human in person.</div><div><br/></div><div>Luckily I have a hybrid work setup so I can choose to go into the office whenever I want.</div><div><br/></div><div>We're biological, social beings that need a community to thrive.</div><div><br/></div><div>Don't spend too much time alone.</div>`

export default new Reflection({
  html,
  id: 'isolation',
  tags: [HEALTH],
  title,
  words: 105,
  written: '12/6/22'
})
