import Reflection from '@/stores/models/reflection'

const title = 'The Taliban'
const html = `<div>The images from Afghanistan this week shocked me.</div><div><br/></div><div>A few people fell to their deaths trying to hang on to a C-17 jet during take-off in Kabul.</div><div><br/></div><div>After 20 years, the US military finally withdrew its forces, and the Taliban took over control of the capital.</div><div><br/></div><div>I'm a complete novice on the War in Afghanistan, but there seems to be a consensus that the U.S. should never have intervened in the Middle East.</div><div><br/></div><div>Violence is never the answer.</div><div><br/></div><div>There are injustices everywhere, but whose job is it to police the world?</div><div><br/></div><div>It may seem impossible and naive, but the only way to transform a nation is to change people's hearts and minds.</div>`

export default new Reflection({
  html,
  id: 'the-taliban',
  tags: null,
  title,
  words: 112,
  written: '8/17/21'
})
