import Reflection from '@/stores/models/reflection'

const title = 'The Four Agreements'
const html = `<p>Several years ago, a friend recommended a book to me that I&apos;ve continued to reflect upon.</p> <p>It&apos;s a classic that has sold millions of copies worldwide, but I hadn&apos;t heard of it at the time.</p> <p><em>The Four Agreements</em>, by Don Miguel Ruiz, distills Toltec philosophy into simple guidelines to live by.</p> <p>It&apos;s a quick read (160 pages) of practical wisdom.</p> <p>Here are the four agreements:</p> <ol> <li> <p>&quot;Be Impeccable With Your Word.&quot;</p> <ul> <li> <p>Integrity is the most important characteristic to have.</p> </li> </ul> </li> <li> <p>&quot;Don&apos;t Take Anything Personally.&quot;</p> <ul> <li> <p>People are focused on themselves, not on you.</p> </li> </ul> </li> </ol> <ol start="3"> <li> <p>&quot;Don&apos;t Make Assumptions.&quot;</p> </li> </ol> <ul> <ul> <li> <p>Communication and vulnerability solve this problem.</p> </li> </ul> </ul> <ol start="4"> <li> <p>&quot;Always Do Your Best.&quot;</p> </li> </ol> <ul> <ul> <li> <p>Like David Goggins&apos; 40% Rule says, &quot;when your mind is telling you you&apos;re done, you&apos;re really only 40 percent done.&quot; We all have more energy if we dig deep. The human spirit is relentless.</p> </li> </ul> </ul> <p>They are simple, yet so easy to fail to fulfill.</p> <p>Many problems in life wouldn&apos;t exist if we truly followed these agreements.</p>`

export default new Reflection({
  html,
  id: 'the-four-agreements',
  tags: null,
  title,
  words: 159,
  written: '12/29/20'
})
