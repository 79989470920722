import Reflection from '@/stores/models/reflection'

const title = 'Overconfidence'
const html = `<div>"Those who know do not speak. Those who speak do not know."</div><div>– Lao Tzu</div><div><br/></div><div>I can elaborate more on this idea, but I think it would ruin the point.</div><div><br/></div><div>I’ve already said too much.</div><div><br/></div><div>Check your <a href="https://zachgallagher.com/reflections/2019/conviction">conviction</a>.</div>`

export default new Reflection({
  html,
  id: 'overconfidence',
  tags: null,
  title,
  words: 37,
  written: '8/11/20'
})
