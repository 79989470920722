import Reflection from '@/stores/models/reflection'

const title = 'Brevity'
const html = `<div>Time is our most valuable resource.</div><div><br/></div><div>I am truly grateful you are reading this.</div><div><br/></div><div>You can be doing a zillion other things.</div><div><br/></div><div>Life is too short to fill it with fluff.</div><div><br/></div><div>Conciseness helps you focus on the significant.</div><div><br/></div><div>Short to-do lists get done.</div><div><br/></div><div>Long lists wither.</div><div><br/></div><div>Cut out the nonessential.</div><div><br/></div><div>Honor people's time, especially your own.</div>`

export default new Reflection({
  html,
  id: 'brevity',
  tags: null,
  title,
  words: 57,
  written: '9/17/19'
})
