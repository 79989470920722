import Reflection from '@/stores/models/reflection'

const title = 'Streaks'
const html = `<div>I've been writing these reflections every week since July 2019.</div><div><br/></div><div>Even though they're short and only once a week, I still have to commit the time and get them done.</div><div><br/></div><div>Maintaining a streak can be stressful.</div><div><br/></div><div>Sam Harris removed the streak feature from his app, Waking Up, calling it "spiritual materialism."</div><div><br/></div><div>I'm grateful to Sam because that decision improved my mental health.</div><div><br/></div><div>I had an ongoing streak from when I first downloaded the app in 2018, and every day I felt an obligation, a subtle anxiety, to keep it going.</div><div><br/></div><div>I even emailed their support one day to fix the streak when it didn't count a session I'd completed!</div><div><br/></div><div>Although tracking streaks can be detrimental, meditation is a different pursuit than writing.</div><div><br/></div><div>Daily streaks are also much more restrictive than weekly.</div><div><br/></div><div>168 hours is plenty of time, and I don't have a minimum word count.</div><div><br/></div><div>Showing up is all that matters to me.</div><div><br/></div><div>Although paying attention to streaks can be unhealthy, they also have a powerful gravitational pull to keep the action going.</div><div><br/></div><div>Nothing is certain in life.</div><div><br/></div><div>Every week I have a choice.</div><div><br/></div><div>Moving forward, I plan on never missing a week.</div><div><br/></div><div>The output doesn't make the streak important.</div><div><br/></div><div>It's about the practice.</div>`

export default new Reflection({
  html,
  id: 'streaks',
  tags: null,
  title,
  words: 204,
  written: '1/11/22'
})
