import Reflection from '@/stores/models/reflection'
import {SYSTEMS} from '@/tags'

const title = 'Second Brain'
const html = `<div>Tiago Forte developed a great methodology for personal knowledge management called <a href="https://www.buildingasecondbrain.com/" rev="en_rl_none">Building a Second Brain</a>.</div><div><br/></div><div>It uses an organizational structure called PARA — Projects, Areas, Resources, and Archive.</div><div><br/></div><div>Dividing all your digital notes into 4 top-level folders makes them super easy to navigate.</div><div><br/></div><div>It's dynamic, as active projects and areas change priorities over time.</div><div><br/></div><div>It's meant to not only save ideas for future reference, but to also facilitate creativity and productivity with active projects.</div><div><br/></div><div>Tiago also introduces the concept of "intermediate packets," templates that encourage reusing previous work so every project doesn't have to start from scratch.</div><div><br/></div><div>The entire system is meant to support the "CODE" method: Capture, Organize, Distill, and Express.</div><div><br/></div><div>Capture information that resonates with you.</div><div><br/></div><div>Organize into actionable folders to move projects and areas forward.</div><div><br/></div><div>Distill notes by highlighting and summarizing key points.</div><div><br/></div><div>Express by creating something new.</div><div><br/></div><div>The "Second Brain" is a powerful tool that allows your actual brain to offload the burden of remembering every little project detail so you can focus on creating.</div><div><br/></div><div>I implemented the system in my note-taking apps and I feel noticeably more organized and empowered to get things done more effectively.</div>`

export default new Reflection({
  html,
  id: 'second-brain',
  tags: [SYSTEMS],
  title,
  words: 191,
  written: '1/17/23'
})
