import Reflection from '@/stores/models/reflection'

const title = 'Mass Shootings'
const html = `<div>I hope this term disappears from the news cycle forever.</div><div><br/></div><div>The US has a problem, and the solution is clear.</div><div><br/></div><div>Prevent civilians from buying semiautomatic rifles, and regulate gun ownership.</div><div><br/></div><div>You don't need an AR-15 to defend yourself.</div><div><br/></div><div>Getting a driver's license requires passing both a knowledge and driving test.</div><div><br/></div><div>Getting a firearm license should be much more difficult.</div>`

export default new Reflection({
  html,
  id: 'mass-shootings',
  tags: null,
  title,
  words: 59,
  written: '5/31/22'
})
