import Reflection from '@/stores/models/reflection'

const title = 'Movie Theaters'
const html = `<div>Last weekend I went out to see a movie for the first time in years.</div><div><br/></div><div>It was a worse experience than watching it at home.</div><div><br/></div><div>You don't know when the movie actually starts, so you're forced to sit through an unknown amount of previews.</div><div><br/></div><div>The ticket said 5:15, but the movie didn't start until around 5:45.</div><div><br/></div><div>Some people may like the previews, but it wouldn't hurt to have the actual start time on the ticket as well so I know when to show up.</div><div><br/></div><div>Not everyone will agree with me, but movies longer than 2 hours should have an intermission — I'd like to go to the restroom without missing a scene, thank you very much.</div><div><br/></div><div>Sitting for 140+ straight minutes, not including previews, isn't a great experience.</div><div><br/></div><div>For movies that are available for streaming, the theater experience should be significantly better if studios expect people to buy tickets.</div><div><br/></div><div>The IMAX and 3D movie experience is clearly more distinct, but there isn't much of a difference for others.</div><div><br/></div><div>Movie theaters may have to adapt to stay in business.</div>`

export default new Reflection({
  html,
  id: 'movie-theaters',
  tags: null,
  title,
  words: 178,
  written: '12/28/21'
})
