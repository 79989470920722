import Reflection from '@/stores/models/reflection'

const title = 'Blind Spots'
const html = `<div>The range of human awareness is narrow.<br/></div><div><br/></div><div>We can develop laser-like focus, but we obfuscate the rest of our surroundings.</div><div><br/></div><div>The well-known <a href="https://www.youtube.com/watch?v=vJG698U2Mvo">selective attention test</a> from Harvard shows that we miss obvious details when we divert our focus. <sup>[1]</sup></div><div><br/></div><div>The human eye itself can only detect a tiny range of the electromagnetic spectrum. <sup>[2]</sup></div><div><br/></div><div>Even the little that we see is not 100% accurate.</div><div><br/></div><div><a href="https://www.aao.org/museum-eye-openers/experiment-blind-spot">This blind spot experiment</a> proves that your visual perception is distorted.</div><div><br/></div><div>Understand that your perspective is limited.</div><div><br/></div><div>Be open-minded.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> The study is on PubMed [<a href="https://www.ncbi.nlm.nih.gov/pubmed/10694957">link</a>]. The abstract states, “we perceive and remember only those objects and details that receive focused attention.” It’s a simple yet profound statement. Our limited bandwidth of focus blinds us from the majority of our environment.</div><div><br/></div><div><sup>[2]</sup> The <a href="https://upload.wikimedia.org/wikipedia/commons/f/f1/EM_spectrum.svg">visible spectrum</a> is between 380 and 740 nanometers.</div>`

export default new Reflection({
  html,
  id: 'blind-spots',
  tags: null,
  title,
  words: 138,
  written: '1/14/20'
})
