import Reflection from '@/stores/models/reflection'
import {SYSTEMS} from '@/tags'

const title = 'ABZ'
const html = `<div>Shaan Puri has a great framework called <a href="https://twitter.com/ShaanVP/status/1347252471291207681" rev="en_rl_none">ABZ</a>.</div><div><br/></div><div>A is where you are now.</div><div><br/></div><div>B is your next step.</div><div><br/></div><div>Z is your ultimate goal.</div><div><br/></div><div>You don't need to know every step to reach a goal.</div><div><br/></div><div>You can figure things out along the way.</div>`

export default new Reflection({
  html,
  id: 'abz',
  tags: [SYSTEMS],
  title,
  words: 43,
  written: '11/16/21'
})
