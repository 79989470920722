import React from 'react'
import cx from 'classnames'

import './Input.scss'

const Input = (props) => {
  const { isMultiLine, ...restProps } = props

  const className = cx('Input', props.className)

  const defaultProps = {
    type: 'text'
  }

  const tag = isMultiLine ? 'textarea' : 'input'

  return (
    React.createElement(tag, {
      ...defaultProps,
      ...restProps,
      className
    }, props.children)
  )
}

export default Input
