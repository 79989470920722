// https://www.npmjs.com/package/react-spinners
import React, { PureComponent } from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import PropTypes from 'prop-types'
import { orange } from '@/styles/settings/variables.scss'

import './Loader.scss'

class Loader extends PureComponent {
  render () {
    const { size, isBlack } = this.props
    return (
      <div className='Loader'>
        <MoonLoader
          sizeUnit={'px'}
          size={size || 32}
          color={isBlack ? 'black' : orange}
          loading
        />
      </div>
    )
  }
}

Loader.propTypes = {
  size: PropTypes.number
}

export default Loader
