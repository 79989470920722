import Reflection from '@/stores/models/reflection'

const title = 'Apple Vision Pro'
const html = `<div>Yesterday Apple announced their Vision Pro device, a $3500 headset that comes out in early 2024.</div><div><br/></div><div>To some it looks like the first scene of a Black Mirror episode, and to others it looks like an inspiring, magical future.</div><div><br/></div><div>I'm on the optimistic side.</div><div><br/></div><div>As this decade unravels, people will become more accustomed to extended reality devices.</div><div><br/></div><div>They're no substitute for reality, of course, but they open up worlds of possibility.</div>`

export default new Reflection({
  html,
  id: 'apple-vision-pro',
  tags: null,
  title,
  words: 71,
  written: '6/6/23'
})
