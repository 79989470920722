import Reflection from '@/stores/models/reflection'

const title = 'Meme Stonks'
const html = `<div>The stock market is a casino.</div><div><br/></div><div>Always has been and always will be.</div><div><br/></div><div>Nobody explains it better than <a href="https://www.youtube.com/watch?v=xbBD7VIJ4cc">McConaughey's character</a> in The Wolf of Wall Street.</div><div><br/></div><div>It's been hilarious to watch <a href="https://www.reddit.com/r/wallstreetbets/" rev="en_rl_none">r/wallstreetbets</a> pump GME and AMC this year 🚀🚀🚀</div><div><br/></div><div>You love to see it.</div><div><br/></div><div>💎🙌</div>`

export default new Reflection({
  html,
  id: 'meme-stonks',
  tags: null,
  title,
  words: 45,
  written: '6/8/21'
})
