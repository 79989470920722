import Reflection from '@/stores/models/reflection'

const title = 'Tom Brady'
const html = `<div>TB12 is the GOAT.</div><div><br/></div><div>Nobody can argue against 7 titles.</div><div><br/></div><div>In over 20 seasons in the NFL, he's been in 10 Super Bowls.</div><div><br/></div><div>A 20 year career is in itself a major accomplishment.</div><div><br/></div><div>Brady was pretty good in college — he had a 20-5 record as a starter and earned the nickname  "the Comeback Kid" at Michigan. (Go Blue)</div><div><br/></div><div>However, the Patriots famously drafted him with the compensatory 199th pick of the 2000 NFL draft.</div><div><br/></div><div>Predicting greatness isn't as easy as acknowledging it.</div><div><br/></div><div>What can we learn from an outlier like Tom Brady?</div><div><br/></div><div>Studying the greats, in sports and life, reveals key insights into their makings.</div><div><br/></div><div>Success leaves clues.</div><div><br/></div><div>The information is out there, you just have to ask the right questions.</div>`

export default new Reflection({
  html,
  id: 'tom-brady',
  tags: null,
  title,
  words: 122,
  written: '2/9/21'
})
