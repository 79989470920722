import Reflection from '@/stores/models/reflection'

const title = 'Bank Runs'
const html = `<div>Silicon Valley Bank collapsed last week.</div><div><br /></div><div>The FDIC took control of the bank and the SEC halted trading for their stock after depositors tried to withdraw $42 billion in one day.</div><div><br /></div><div>In our hyperconnected world, panics can spread quickly.</div><div><br /></div><div>97% of the bank's customers had over the $250,000 insured limit, so each individual depositor had an incentive to protect themselves from financial ruin.</div><div><br /></div><div>On Sunday, the Federal Reserve, Treasury, and FDIC released a <a href="https://www.federalreserve.gov/newsevents/pressreleases/monetary20230312b.htm" rev="en_rl_none">joint press release</a> that the FDIC will fully protect customer deposits, at no expense to taxpayers.</div><div><br /></div><div>Small to midsize regional banks are vulnerable to bank runs, and if a bank run were to topple a "systemically important bank" (aka too big to fail), the entire global financial system can collapse.</div><div><br /></div><div>The US government is in debt over $30 trillion dollars and the Federal Reserve's monetary policy will always change over time.</div><div><br /></div><div>Fiat currency isn't backed by anything, except perhaps the military.</div><div><br /></div><div>Fiat currencies without a predetermined, immutable monetary policy are susceptible to corruption and misaligned incentives.</div><div><br /></div><div>Digital currencies will eventually take over.</div>`

export default new Reflection({
  html,
  id: 'bank-runs',
  tags: null,
  title,
  words: 175,
  written: '3/14/23'
})
