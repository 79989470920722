import Reflection from '@/stores/models/reflection'
import {PROGRAMMING} from '@/tags'

const title = 'Maintaining Software'
const html = `<div>Dependencies evolve.</div><div><br/></div><div>Whether you're a user or a maintainer, you have to adapt to a changing environment. </div><div><br/></div><div>If you use open-source software and neglect to lock the package versions, it's only a matter of time until something breaks.</div><div><br/></div><div>If you're spending a nontrivial amount of time on legacy software, there are likely better alternatives.</div><div><br/></div><div>The switching costs may create too much friction, however.</div><div><br/></div><div>I still use Apple Notes and Evernote because I'm used to them, even if Notion or Roam Research may be better.</div><div><br/></div><div>I started writing these reflections with Evernote in 2019, and I still use the HTML export feature to publish them.</div><div><br/></div><div style="text-align:justify;">Unfortunately the company deprecated the feature, so I have 2 versions installed — the new for writing and the old for publishing.</div><div style="text-align:justify;"><br/></div><div style="text-align:justify;">It's easier to default to my original choice than to look for a better one.</div><div><br/></div><div style="text-align:justify;">I'm certain that I'll migrate to a new system eventually though.</div><div><br/></div><div>Programmers dislike wasting time on things they can automate, but it takes a certain amount of pain to spark the automation process.</div><div><br/></div><div>What's worth continuing and what should you ignore?</div><div><br/></div><div>If a new tool is 10x or 100x more efficient than the old one, it's time for a change.</div><div><br/></div><div>When AI does a better job in 5 seconds than you do in a week's worth of work, you'll have to adapt.</div><div><br/></div><div>We all want to spend more time doing things we enjoy.</div><div><br/></div><div>Software doesn't replace humans, it frees us.</div>`

export default new Reflection({
  html,
  id: 'maintaining-software',
  tags: [PROGRAMMING],
  title,
  words: 241,
  written: '3/23/21'
})
