import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Homemade Sauerkraut'
const html = `<div>Earlier this year I bought two 2-liter fermentation jars.</div><div><br/></div><div>I've already made about a dozen batches of sauerkraut, and each one gets a little better.</div><div><br/></div><div>I prefer red cabbage to green, since the former maintains its form better.</div><div><br/></div><div>The color is also beautiful as it ages from purple to ruby.</div><div><br/></div><div>The recipe is easy: thinly slice up a cabbage, weigh it, add a minimum of 1.5% salt by weight, use your hands to work the cabbage for a few minutes until the liquid releases, and finally pack it into a jar and place a glass fermentation weight on top to keep the water level above the cabbage.</div><div><br/></div><div>Use a fermentation lid so the carbon dioxide can escape from the jar without letting in any oxygen.</div><div><br/></div><div>After storing for ~21 days at room temp (65-75°F) out of sunlight, you have yourself some amazing sauerkraut that lasts for a while in the fridge.</div>`

export default new Reflection({
  html,
  id: 'homemade-sauerkraut',
  tags: [HEALTH],
  title,
  words: 151,
  written: '8/2/22'
})
