import Reflection from '@/stores/models/reflection'
import {HISTORY} from '@/tags'

const title = 'Kobe'
const html = `<div>Kobe transcended sports.</div><div><br/></div><div>He embodied simple yet powerful lessons:</div><div><br/></div><div>Do what you love.</div><div><br/></div><div>Don’t accept mediocrity.</div><div><br/></div><div>Greatness requires sacrifice.</div><div><br/></div><div>Take responsibility.</div><div><br/></div><div>Never give up.</div><div><br/></div><div>Never back down to anyone.</div><div><br/></div><div>Play through the pain.</div><div><br/></div><div>Improve every day.</div><div><br/></div><div>Wake up early.</div><div><br/></div><div>Strengthen your weaknesses. <sup>[1]</sup></div><div><br/></div><div>Don’t make excuses.</div><div><br/></div><div>Study the details.</div><div><br/></div><div>Elevate others.</div><div><br/></div><div>Put the team on your back.</div><div><br/></div><div>Be curious and constantly learn.</div><div><br/></div><div>Create.</div><div><br/></div><div>To become a champion, you have to do what others won’t do.</div><div><br/></div><div>The mamba mentality lives on through the millions of people he inspired.</div><div><br/></div><div>“The mindset isn’t about seeking a result — it’s more about the process of getting to that result. It’s about the journey and the approach. It’s a way of life. I do think that it’s important, in all endeavors, to have that mentality.”</div><div><br/></div><div>Thank you, Kobe.</div><div><br/></div><div>💜💛</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> Kobe could drop a <a href="https://www.youtube.com/watch?v=vukMoNEtkBQ">3-pointer with his left hand</a>.<br/></div>`

export default new Reflection({
  html,
  id: 'kobe',
  tags: [HISTORY],
  title,
  words: 144,
  written: '1/28/20'
})
