import Reflection from '@/stores/models/reflection'

const title = 'Sound Baths'
const html = `<div>On Sunday I experienced a sound bath for the first time.</div><div><br/></div><div>Bells and bowls resonate, and the energy flows through you.</div><div><br/></div><div>After the session I felt totally at peace.</div><div><br/></div><div>Sounds, lighting, and vibrations have a material impact on us.</div><div><br/></div><div>Our environment shapes us.</div>`

export default new Reflection({
  html,
  id: 'sound-baths',
  tags: null,
  title,
  words: 43,
  written: '5/9/23'
})
