import Reflection from '@/stores/models/reflection'

const title = 'Birthday Wishes'
const html = `<div>My birthday was yesterday.</div><div><br/></div><div>I'm still young, and when you're young it feels like you have infinite time left to live.</div><div><br/></div><div>I have so much left to do, and time seems to go by faster every year.</div><div><br/></div><div>All I really want is the health to keep pursuing my dreams.</div>`

export default new Reflection({
  html,
  id: 'birthday-wishes',
  tags: null,
  title,
  words: 49,
  written: '2/20/23'
})
