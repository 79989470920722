import React, { Component } from 'react'
import { Books, Input } from '@/components'
import books from '@/books'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import './NotesPage.scss'

const testRegexpArray = (arr, title, author) => {
  let i = 0
  for (; i < arr.length; i++) {
    if (arr[i].test(title) || arr[i].test(author)) continue
    else return false
  }
  return true
}

export default class NotesPage extends Component {
  state = {
    isVisibleGoodReadsBadge: false,
    searchTerm: '',
    visibleBooks: books
  }
  componentDidMount () {
    this.onLoadGoodReadsBadge = this.goodReadsBadge.addEventListener('load', this.makeGoodReadsBadgeVisible)
    window.scrollTo(0, 0)
  }

  filterBooks = debounce((searchTerm) => {
    if (searchTerm.length) {
      let visibleBooks = JSON.parse(JSON.stringify(books))
      const searchTermRegExps = searchTerm.toLowerCase().split(' ').map(s => new RegExp(s))
      visibleBooks = visibleBooks.filter(book => testRegexpArray(searchTermRegExps, book.title.toLowerCase(), book.author.toLowerCase()))
      this.setState({ visibleBooks })
    } else {
      this.setState({
        searchTerm: '',
        visibleBooks: books
      })
    }
  }, 250)

  makeGoodReadsBadgeVisible = () => {
    this.goodReadsBadge.removeEventListener('load', this.onLoadGoodReadsBadge)
    this.setState({ isVisibleGoodReadsBadge: true })
  }

  onChangeSearch = (e) => {
    const { target } = e
    const { value } = target
    this.setState({ searchTerm: value })
    this.filterBooks(value)
  }

  onClearSearch = () => {
    this.filterBooks('')
    this.setState({
      searchTerm: '',
      visibleBooks: books
    })
  }

  setGoodReadsRef = (el) => {
    this.goodReadsBadge = el
  }

  renderSearchBooks = () => {
    const { searchTerm } = this.state
    return (
      <div className='NotesPage__searchBooks'>
        <Input
          onChange={this.onChangeSearch}
          placeholder='Search books'
          value={searchTerm}
        />
        {searchTerm.length ? <i className='fas fa-times' onClick={this.onClearSearch} /> : null}
      </div>
    )
  }

  render () {
    const { isVisibleGoodReadsBadge, visibleBooks } = this.state
    return (
      <div className='NotesPage'>
        <h1>Notes</h1>
        <div className='NotesPage__intro'>
          <p>Here are some of the book notes I've taken using Evernote.</p>
        </div>
        <div className='NotesPage__goodreads'>
          <span>Currently reading:</span>
          <div>
            <a target='_blank' href='https://www.goodreads.com/review/list/63381732?shelf=currently-reading' rel='nofollow'><img className={cx('NotesPage__goodreads__badge', { 'is-visible': isVisibleGoodReadsBadge })} src='https://www.goodreads.com/images/badge/badge1.jpg' ref={this.setGoodReadsRef} /></a>
          </div>
        </div>
        {this.renderSearchBooks()}
        <div className='NotesPage__books'>
          {visibleBooks.length ? <Books books={visibleBooks} /> : <span className='NotesPage__books__empty'>No results found</span>}
        </div>
      </div>
    )
  }
}
