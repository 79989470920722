import {
  ACTING,
  BIOLOGY,
  BITCOIN,
  BLOCKCHAIN,
  BUDDHISM,
  BUSINESS,
  COMMUNITY,
  CRYPTOCURRENCY,
  ECONOMICS,
  ENERGY,
  ENVIRONMENT,
  INVESTING,
  HEALTH,
  HISTORY,
  MILITARY,
  MYTHOLOGY,
  PHILOSOPHY,
  PHYSICS,
  POKER,
  POLITICS,
  PROGRAMMING,
  PSYCHOLOGY,
  SYSTEMS,
  WRITING,
  YOGA
} from '@/tags'

class Book {
  constructor (title, author, img, tags, evernoteLink, amazonLink) {
    this.title = title
    this.author = author
    this.img = img
    this.tags = tags || []
    this.evernoteLink = evernoteLink
    this.amazonLink = amazonLink
  }
}

// https://affiliate-program.amazon.com/home/productlinks/search

// const NewBook = new Book('', '', '', [], '', '')

// const NewBook = new Book('', '', '', [], '', '')
const WhatsOurProblem = new Book('What\'s Our Problem?', 'Tim Urban', 'https://m.media-amazon.com/images/I/41KX-1TEPhL.jpg', [POLITICS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/whatsourproblemtimurban', '')
const TheHeartOfYoga = new Book('The Heart of Yoga', 'T. K. V. Desikachar', 'https://m.media-amazon.com/images/I/51kjCuXfNYL._SX218_BO1,204,203,200_QL40_FMwebp_.jpg', [YOGA], 'https://www.evernote.com/pub/zachagallagher/theheartofyogat.k.v.desikachar', '')
const TheIlluminatedBreath = new Book('The Illuminated Breath', 'Dylan Werner', 'https://m.media-amazon.com/images/W/MEDIAX_792452-T1/images/I/41Zfjhjyu3L._SX342_SY445_.jpg', [HEALTH, YOGA], 'https://www.evernote.com/pub/zachagallagher/theilluminatedbreathdylanwerner', '')
const TheBeginningOfInfinity = new Book('The Beginning of Infinity', 'David Deutsch', 'https://m.media-amazon.com/images/I/51H9D+p2RSL.jpg', [PHILOSOPHY, PHYSICS], 'https://www.evernote.com/pub/zachagallagher/thebeginningofinfinitydaviddeutsch', '')
const BuildingASecondBrain = new Book('Building a Second Brain', 'Tiago Forte', 'https://m.media-amazon.com/images/I/41oPg29yspL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg', [BUSINESS, SYSTEMS, WRITING], 'https://www.evernote.com/pub/zachagallagher/buildingasecondbraintiagoforte', '')
const TheArtOfGathering = new Book('The Art of Gathering: How We Meet and Why It Matters', 'Priya Parker', 'https://m.media-amazon.com/images/I/51FPeQedXkL.jpg', [COMMUNITY], 'https://www.evernote.com/pub/zachagallagher/theartofgatheringpriyaparker', '')
const NoRulesRules = new Book('No Rules Rules: Netflix and the Culture of Reinvention', 'Reed Hastings & Erin Meyer', 'https://m.media-amazon.com/images/I/516JuOUqrFL.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/norulesrulesreedhastingserinmeyer', '')
const AnElegantPuzzle = new Book('An Elegant Puzzle: Systems of Engineering Management', 'Will Larson', 'https://m.media-amazon.com/images/W/WEBP_402378-T2/images/I/51aTO3pGp9L.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/anelegantpuzzlesystemsofengineeringmanagementwilllarson', '')
const TheNetworkState = new Book('The Network State', 'Balaji Srinivasan', 'https://m.media-amazon.com/images/I/41sRCUMvutL.jpg', [BLOCKCHAIN, COMMUNITY, CRYPTOCURRENCY, HISTORY, POLITICS], 'https://www.evernote.com/pub/zachagallagher/thenetworkstatebalajisrinivasan', '')
const TheScoutMindset = new Book('The Scout Mindset', 'Julia Galef', 'https://images-na.ssl-images-amazon.com/images/I/41MpdW9uPXL._SX329_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/thescoutmindsetjuliagalef', '')
const ThinkingInSystems = new Book('Thinking in Systems', 'Donella H. Meadows', 'https://images-na.ssl-images-amazon.com/images/I/41TJRhugQJL._SX324_BO1,204,203,200_.jpg', [BUSINESS, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/thinkinginsystemsdonellameadows', '')
const TheChangingWorldOrder = new Book('Principles for Dealing with the Changing World Order', 'Ray Dalio', 'https://images-na.ssl-images-amazon.com/images/I/41c7pa262UL._SX327_BO1,204,203,200_.jpg', [HISTORY, ECONOMICS], 'https://www.evernote.com/pub/zachagallagher/thechangingworldorderraydalio', '')
const TheFiveDysfunctionsOfATeam = new Book('The Five Dysfunctions of a Team', 'Patrick Lencioni', 'https://images-na.ssl-images-amazon.com/images/I/51QArmq8raL._SX328_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thefivedysfunctionsofateampatricklencioni', '')
const TheBodyKeepsTheScore = new Book('The Body Keeps the Score', 'Bessel van der Kolk', 'https://images-na.ssl-images-amazon.com/images/I/41fhvDxRRUL._SX329_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/thebodykeepsthescorebesselvanderkolk', '')
const SixEasyPieces = new Book('Six Easy Pieces', 'Richard Feynman', 'https://images-na.ssl-images-amazon.com/images/I/51Y0D7VNG4L._SX314_BO1,204,203,200_.jpg', [PHYSICS], 'https://www.evernote.com/pub/zachagallagher/sixeasypiecesrichardfeynman', '')
const RicherWiserHappier = new Book('Richer, Wiser, Happier', 'William Green', 'https://images-na.ssl-images-amazon.com/images/I/41Xi16U2WHL._SX326_BO1,204,203,200_.jpg', [INVESTING], 'https://www.evernote.com/pub/zachagallagher/richerwiserhappierwilliamgreen', '')
const TheMoralAnimal = new Book('The Moral Animal', 'Robert Wright', 'https://images-na.ssl-images-amazon.com/images/I/51YwKI2pCLL._SX318_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/themoralanimalrobertwright', '')
const EightyTwentyPrinciple = new Book('The 80/20 Principle', 'Richard Koch', 'https://images-na.ssl-images-amazon.com/images/I/518ELr51x-L._SX322_BO1,204,203,200_.jpg', [SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/the8020principlerichardkoch', '')
const TheSovereignIndividual = new Book('The Sovereign Individual', 'James Dale Davidson & William Rees-Mogg', 'https://images-na.ssl-images-amazon.com/images/I/41FoHhWkpvL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg', [ECONOMICS], 'https://www.evernote.com/pub/zachagallagher/thesovereignindividualdavidsonrees-mogg', '')
const Israel = new Book('Israel', 'Noa Tishby', 'https://images-na.ssl-images-amazon.com/images/I/41V06z-zpQL._SX329_BO1,204,203,200_.jpg', [HISTORY], 'https://www.evernote.com/pub/zachagallagher/israelasimpleguidetothemostmisunderstoodcountryonearthnoatishby', '')
const Range = new Book('Range: Why Generalists Triumph in a Specialized World ', 'David Epstein', 'https://images-na.ssl-images-amazon.com/images/I/41c2PR2VIyL._SX333_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/rangedavidepstein', '')
const TheOrderOfTime = new Book('The Order of Time', 'Carlo Rovelli', 'https://images-na.ssl-images-amazon.com/images/I/41HQuvVQWbL._SX324_BO1,204,203,200_.jpg', [PHYSICS], 'https://www.evernote.com/pub/zachagallagher/theorderoftimecarlorovelli', '')
const WhyBuddhismIsTrue = new Book('Why Buddhism is True', 'Robert Wright', 'https://images-na.ssl-images-amazon.com/images/I/41DKOLz-5qL._SX329_BO1,204,203,200_.jpg', [BUDDHISM], 'https://www.evernote.com/pub/zachagallagher/whybuddhismistruerobertwright', '')
const RadicalCandor = new Book('Radical Candor', 'Kim Scott', 'https://images-na.ssl-images-amazon.com/images/I/41MFKXNZVOL._SX327_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/radicalcandorkimscott', '')
const ThePsychologyOfMoney = new Book('The Psychology of Money', 'Morgan Housel', 'https://images-na.ssl-images-amazon.com/images/I/41y1sBiezmL._SX322_BO1,204,203,200_.jpg', [INVESTING], 'https://www.evernote.com/pub/zachagallagher/thepsychologyofmoneymorganhousel', '')
const WhyWeSleep = new Book('Why We Sleep', 'Matthew Walker', 'https://images-na.ssl-images-amazon.com/images/I/51z7Ha9xvGL._SX329_BO1,204,203,200_.jpg', [HEALTH], 'https://www.evernote.com/pub/zachagallagher/whywesleepmatthewwalker', '')
const AmusingOurselvesToDeath = new Book('Amusing Ourselves to Death', 'Neil Postman', 'https://images-na.ssl-images-amazon.com/images/I/51ayZYs05ZL._SX324_BO1,204,203,200_.jpg', [HISTORY], 'https://www.evernote.com/pub/zachagallagher/amusingourselvestodeathneilpostman', '')
const UnderstandingMedia = new Book('Understanding Media', 'Marshall McLuhan', 'https://images-na.ssl-images-amazon.com/images/I/51Golduu4CL._SX336_BO1,204,203,200_.jpg', [HISTORY], 'https://www.evernote.com/pub/zachagallagher/understandingmediamarshallmcluhan', '')
const Systemantics = new Book('Systemantics', 'John Gall', 'https://images-na.ssl-images-amazon.com/images/I/41XjmAj7cfL._SX331_BO1,204,203,200_.jpg', [SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/systemanticsjohngall', '')
const TheGoal = new Book('The Goal', 'Eliyahu Goldratt', 'https://images-na.ssl-images-amazon.com/images/I/519C2Gz-v2L._SX334_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thegoalaprocessofongoingimprovementeliyahugoldratt', '')
const TheSnowball = new Book('The Snowball: Warren Buffett and the Business of Life', 'Alice Schroeder', 'https://images-na.ssl-images-amazon.com/images/I/51KVWaAP02L._SX328_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thesnowballwarrenbuffettandthebusinessoflifealiceschroeder', '')
const TheHappinessHypothesis = new Book('The Happiness Hypothesis', 'Jonathan Haidt', 'https://images-na.ssl-images-amazon.com/images/I/41J3FYTLTYL._SX331_BO1,204,203,200_.jpg', [PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/thehappinesshypothesisjonathanhaidt', '')
const Nudge = new Book('Nudge', 'Richard Thaler & Cass Sunstein', 'https://images-na.ssl-images-amazon.com/images/I/41PvC79D52L._SX324_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/nudgerichardthalercasssunstein', '')
const TheCatalyst = new Book('The Catalyst', 'Jonah Berger', 'https://images-na.ssl-images-amazon.com/images/I/41PGITcANqL._SX324_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thecatalystjonahberger', '')
const StartupNation = new Book('Start-up Nation: The Story of Israel\'s Economic Miracle', 'Dan Senor & Saul Singer', 'https://images-na.ssl-images-amazon.com/images/I/515rWDeHmML._SX322_BO1,204,203,200_.jpg', [BUSINESS, ECONOMICS], 'https://www.evernote.com/pub/zachagallagher/start-upnationthestoryofisraelseconomicmiracledansenorsaulsinger', '')
const Originals = new Book('Originals', 'Adam Grant', 'https://images-na.ssl-images-amazon.com/images/I/511qyzBH42L._SX322_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/originalsadamgrant', '')
const DaringGreatly = new Book('Daring Greatly', 'Brené Brown', 'https://images-na.ssl-images-amazon.com/images/I/51z0yrcMLdL._SX330_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/daringgreatlybrenbrown', '')
const GiveAndTake = new Book('Give and Take', 'Adam Grant', 'https://m.media-amazon.com/images/I/41yqdLA5kHL.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/giveandtakeadamgrant', '')
const OptionB = new Book('Option B', 'Sheryl Sandberg & Adam Grant', 'https://m.media-amazon.com/images/I/41horxKOCXL._SY346_.jpg', [], 'https://www.evernote.com/pub/zachagallagher/optionbsherylsandbergadamgrant', '')
const LeanIn = new Book('Lean In', 'Sheryl Sandberg', 'https://m.media-amazon.com/images/I/41XGZDGFaLL.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/leaninsherylsandberg', '')
const TheEffectiveExecutive = new Book('The Effective Executive', 'Peter Drucker', 'https://m.media-amazon.com/images/I/51GzGzjFEqL.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/theeffectiveexecutivepeterdrucker', '')
const SoGoodTheyCantIgnoreYou = new Book(`So Good They Can't Ignore You`, 'Cal Newport', 'https://images-na.ssl-images-amazon.com/images/I/51IjORMFLkL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/sogoodtheycantignoreyoucalnewport', '')
const CleanCode = new Book('Clean Code', 'Robert C. Martin', 'https://images-na.ssl-images-amazon.com/images/I/41jEbK-jG+L._SX374_BO1,204,203,200_.jpg', [PROGRAMMING], 'https://www.evernote.com/pub/zachagallagher/cleancoderobertc.martin', '')
const DesigningDataIntensiveApplications = new Book('Designing Data-Intensive Applications', 'Martin Kleppman', 'https://images-na.ssl-images-amazon.com/images/I/51gP9mXEqWL._SX379_BO1,204,203,200_.jpg', [PROGRAMMING], 'https://www.evernote.com/pub/zachagallagher/designingdata-intensiveapplicationsmartinkleppman', '')
const Alchemy = new Book('Alchemy', 'Rory Sutherland', 'https://images-na.ssl-images-amazon.com/images/I/41ZF3I4S26L._SX329_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/alchemyrorysutherland', '')
const AtomicHabits = new Book('Atomic Habits', 'James Clear', 'https://images-na.ssl-images-amazon.com/images/I/51Eqf-URhoL._SX329_BO1,204,203,200_.jpg', [SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/atomichabitsjamesclear', '')
const TheRideOfALifetime = new Book('The Ride of a Lifetime', 'Robert Iger', 'https://images-na.ssl-images-amazon.com/images/I/41gr0fllE9L._SX327_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/therideofalifetime-robertiger', '')
const PragmaticProgrammer = new Book('The Pragmatic Programmer', 'Andy Hunt & Dave Thomas', 'https://images-na.ssl-images-amazon.com/images/I/418M2053aNL._SX396_BO1,204,203,200_.jpg', [PROGRAMMING], 'https://www.evernote.com/pub/zachagallagher/thepragmaticprogrammerandyhuntdavethomas', '')
const Factfulness = new Book('Factfulness', 'Hans Rosling', 'https://images-na.ssl-images-amazon.com/images/I/51tvugRSHKL._SX322_BO1,204,203,200_.jpg', [ECONOMICS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/factfulness-hansrosling', '')
const TheInnerGameOfTennis = new Book('The Inner Game of Tennis', 'Timothy Gallwey', 'https://images-na.ssl-images-amazon.com/images/I/41oYpZlSQ0L._SX321_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/theinnergameoftennis-timothygallwey', '')
const TheGene = new Book('The Gene', 'Siddhartha Mukherjee', 'https://images-na.ssl-images-amazon.com/images/I/41m0KlYn5lL._SX310_BO1,204,203,200_.jpg', [BIOLOGY], 'https://www.evernote.com/pub/zachagallagher/thegene-siddharthamukherjee', 'https://amzn.to/35Fa1IB')
const Buddha = new Book('Buddha', 'Karen Armstrong', 'https://images-na.ssl-images-amazon.com/images/I/41t6AgfGPRL._SX330_BO1,204,203,200_.jpg', [PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/buddhakarenarmstrong', '')
const HighOutputManagement = new Book('High Output Management', 'Andy Grove', 'https://images-na.ssl-images-amazon.com/images/I/51IXfgjZNgL._SX322_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/highoutputmanagement-andygrove', 'https://amzn.to/34f9jSF')
const TheSerengetiRules = new Book('The Serengeti Rules', 'Sean B. Carroll', 'https://images-na.ssl-images-amazon.com/images/I/41kjYU44a-L._SX326_BO1,204,203,200_.jpg', [BIOLOGY, ENVIRONMENT, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/theserengetirules-seanb.carroll', 'https://amzn.to/33wXvKZ')
const TheBoom = new Book('The Boom', 'Russell Gold', 'https://images-na.ssl-images-amazon.com/images/I/51qLLpEA4IL._SX301_BO1,204,203,200_.jpg', [ENERGY, ENVIRONMENT], 'https://www.evernote.com/pub/zachagallagher/theboom-russellgold', 'https://amzn.to/2TrU0Uj')
const AlgorithmsToLiveBy = new Book('Algorithms to Live By', 'Brian Christian & Tom Griffiths', 'https://images-na.ssl-images-amazon.com/images/I/510GRAYQNSL._SX329_BO1,204,203,200_.jpg', [PROGRAMMING, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/algorithmstoliveby-brianchristiantomgriffiths', 'https://amzn.to/33ToPD4')
const Loonshots = new Book('Loonshots', 'Safi Bahcall', 'https://images-na.ssl-images-amazon.com/images/I/41xeO-IKMUL._SX327_BO1,204,203,200_.jpg', [BUSINESS, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/loonshots-safibahcall', 'https://amzn.to/2MKPGgl')
const GettingThingsDone = new Book('Getting Things Done', 'David Allen', 'https://images-na.ssl-images-amazon.com/images/I/41YJyWQCBZL._SX310_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/gettingthingsdone-davidallen', 'https://amzn.to/380tl4Y')
const RevisingProse = new Book('Revising Prose', 'Richard Lanham', 'https://images-na.ssl-images-amazon.com/images/I/412Q0A4YXJL._SX330_BO1,204,203,200_.jpg', [WRITING], 'https://www.evernote.com/pub/zachagallagher/revisingprose-richardlanham', '')
const Falter = new Book('Falter', 'Bill McKibben', 'https://images-na.ssl-images-amazon.com/images/I/41E7DULw9hL._SX322_BO1,204,203,200_.jpg', [ENVIRONMENT], 'https://www.evernote.com/pub/zachagallagher/falter-billmckibben', 'https://amzn.to/2KoT6D1')
const PoorCharliesAlmanack = new Book(`Poor Charlie's Almanack: The Wit and Wisdom of Charlie Munger`, 'Charlie Munger', 'https://images-na.ssl-images-amazon.com/images/I/51tP2sf8SkL._SX430_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/poorcharliesalmanackthewitandwisdomofcharliemunger', 'https://amzn.to/2T2j7L3')
const Antifragile = new Book('Antifragile', 'Nassim Taleb', 'https://images-na.ssl-images-amazon.com/images/I/419QSJTZ%2BbL._SX322_BO1,204,203,200_.jpg', [ECONOMICS, PHILOSOPHY, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/antifragile-taleb', 'https://amzn.to/2YCQmWf')
const AgeOfSustainableDevelopment = new Book('The Age of Sustainable Development', 'Jeffrey Sachs', 'https://images-na.ssl-images-amazon.com/images/I/61yOXCh8IUL._SX387_BO1,204,203,200_.jpg', [ENVIRONMENT], 'https://www.evernote.com/pub/zachagallagher/theageofsustainabledevelopment-jeffreysachs', 'https://amzn.to/2ZtNc8x')
const PredictablyIrrational = new Book('Predictably Irrational', 'Dan Ariely', 'https://images-na.ssl-images-amazon.com/images/I/51Qp1elDB-L._SX326_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/predictablyirrational-danariely', 'https://amzn.to/2ZuqBJh')
const WhenCoffeeAndKaleCompete = new Book('When Coffee and Kale Compete', 'Alan Klement', 'https://images-na.ssl-images-amazon.com/images/I/41FyeM2USIL._SX331_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/whencoffeeandkalecompete-alanklement', 'https://amzn.to/2yFlZE0')
const Hooked = new Book('Hooked: How to Build Habit-Forming Products', 'Nir Iyal', 'https://images-na.ssl-images-amazon.com/images/I/41aMEH29Z-L._SX329_BO1,204,203,200_.jpg', null, 'https://www.evernote.com/pub/zachagallagher/hooked-niriyal', 'https://amzn.to/2LNyaGA')
const Rework = new Book('Rework', 'Jason Fried & David Heinemeier Hansson', 'https://images-na.ssl-images-amazon.com/images/I/41woojfwaaL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/rework-jasonfrieddavidheinemeierhansson', 'https://amzn.to/2ZrcyUC')
const CreatingSignatureStories = new Book('Creating Signature Stories', 'David Aaker', 'https://images-na.ssl-images-amazon.com/images/I/41Bgzpu8wYL._SX322_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/creatingsignaturestories-davidaaker', 'https://amzn.to/2YCblIR')
const SeekingWisdom = new Book('Seeking Wisdom: From Darwin to Munger', 'Peter Bevelin', 'https://images-na.ssl-images-amazon.com/images/I/41Cgogw98UL._SX365_BO1,204,203,200_.jpg', [BUSINESS, PHILOSOPHY, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/seekingwisdomfromdarwintomunger-peterbevelin', 'https://amzn.to/2LWescI')
const Quiet = new Book(`Quiet: The Power of Introverts in a World That Can't Stop Talking`, 'Susan Cain', 'https://images-na.ssl-images-amazon.com/images/I/51fJjkSEyiL._SX322_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/quiet-susancain', 'https://amzn.to/2yCy4de')
const ExtremeOwnership = new Book('Extreme Ownership: How U.S. Navy SEALs Lead and Win', 'Jocko Willink & Leif Babin', 'https://images-na.ssl-images-amazon.com/images/I/41lJf8ayabL.jpg', [BUSINESS, MILITARY], 'https://www.evernote.com/pub/zachagallagher/extremeownership-jockowillinkleifbabin', 'https://amzn.to/2M0OzII')
const Rebbe = new Book('Rebbe', 'Joseph Telushkin', 'https://images-na.ssl-images-amazon.com/images/I/51X%2BoG987FL._SX329_BO1,204,203,200_.jpg', [HISTORY], 'https://www.evernote.com/pub/zachagallagher/rebbe-josephtelushkin', '')
const Gaia = new Book('Gaia', 'James Lovelock', 'https://images-na.ssl-images-amazon.com/images/I/512haT4dZeL._SX327_BO1,204,203,200_.jpg', [ENVIRONMENT, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/gaia-jameslovelock', '')
const SustainableEnergy = new Book('Sustainable Energy - Without the Hot Air', 'David MacKay', 'https://images-na.ssl-images-amazon.com/images/I/51CF5xNmidL._SX435_BO1,204,203,200_.jpg', [ENERGY, ENVIRONMENT], 'https://www.evernote.com/pub/zachagallagher/sustainableenergy-davidmackay', 'https://amzn.to/2YsuFxe')
const LessonsOfHistory = new Book('The Lessons of History', 'Will Durant & Ariel Durant', 'https://images-na.ssl-images-amazon.com/images/I/51CU-45T3aL._SX309_BO1,204,203,200_.jpg', [HISTORY, PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/thelessonsofhistory-willdurantarieldurant', 'https://amzn.to/2yxQGv4')
const AdweekCopywritingHandbook = new Book('The Adweek Copywriting Handbook', 'Joseph Sugarman', 'https://images-na.ssl-images-amazon.com/images/I/513uEJkI4WL._SX404_BO1,204,203,200_.jpg', [BUSINESS, WRITING], 'https://www.evernote.com/pub/zachagallagher/theadweekcopywritinghandbook-josephsugarman', 'https://amzn.to/2Ynwmf3')
const ElephantInTheBrain = new Book('The Elephant in the Brain', 'Kevin Simler & Robin Hanson', 'https://images-na.ssl-images-amazon.com/images/I/41%2B8WQEdXqL._SX327_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/theelephantinthebrain-kevinsimlerrobinhanson', 'https://amzn.to/2YDRj0J')
const ThisIsMarketing = new Book('This is Marketing', 'Seth Godin', 'https://images-na.ssl-images-amazon.com/images/I/51UYILvuvtL._SX352_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thisismarketing-sethgodin', 'https://amzn.to/2LYTdXL')
const OriginOfConsciousness = new Book('The Origin of Consciousness in the Breakdown of the Bicameral Mind', 'Julian Jaynes', 'https://images-na.ssl-images-amazon.com/images/I/41hrJvVLMHL._SX331_BO1,204,203,200_.jpg', [PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/theoriginofconsciousnessinthebreakdownofthebicameralmind-julianjaynes', 'https://amzn.to/2YGbw5O')
const Subscribed = new Book('Subscribed', 'Tien Tzuo', 'https://images-na.ssl-images-amazon.com/images/I/41E8v%2BWRXHL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/subscribed-tientzuo', 'https://amzn.to/31k6HRp')
const ImprovWisdom = new Book(`Improv Wisdom`, 'Patricia Ryan Madson', 'https://images-na.ssl-images-amazon.com/images/I/41nXZWcyJrL._SX323_BO1,204,203,200_.jpg', [ACTING], 'https://www.evernote.com/pub/zachagallagher/improvwisdom-patriciaryanmadson', 'https://amzn.to/2YHVOHs')
const Impro = new Book('Impro: Improvisation and the Theatre', 'Keith Johnstone', 'https://images-na.ssl-images-amazon.com/images/I/51vxwWn36XL._SX308_BO1,204,203,200_.jpg', [ACTING], 'https://www.evernote.com/pub/zachagallagher/impro-keithjohnstone', 'https://amzn.to/2yyF22W')
const TruthInComedy = new Book('Truth in Comedy', 'Charna Halpern, Del Close, Kim Johnson', 'https://images-na.ssl-images-amazon.com/images/I/51lhG16-gzL._SX322_BO1,204,203,200_.jpg', [ACTING], 'https://www.evernote.com/pub/zachagallagher/truthincomedy-charnahalperndelclosekimjohnson', 'https://amzn.to/2YIJtmq')
const AnythingYouWant = new Book('Anything You Want', 'Derek Sivers', 'https://images-na.ssl-images-amazon.com/images/I/51JHt8Kml-L._SX352_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/anythingyouwant-dereksivers', 'https://amzn.to/2YCdLHD')
const LeanStartup = new Book('The Lean Startup', 'Eric Ries', 'https://images-na.ssl-images-amazon.com/images/I/51T-sMqSMiL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/theleanstartup-ericries', 'https://amzn.to/2YF7Od0')
const NeverSplitTheDifference = new Book('Never Split the Difference', 'Chris Voss', 'https://images-na.ssl-images-amazon.com/images/I/511Lo1f6MXL._SX328_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/neversplitthedifference-chrisvoss', 'https://amzn.to/2MQfFCW')
const BlackBoxThinking = new Book('Black Box Thinking', 'Matthew Syed', 'https://images-na.ssl-images-amazon.com/images/I/41cMiQ%2B%2Bw%2BL._SX329_BO1,204,203,200_.jpg', [BUSINESS, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/blackboxthinking-matthewsyed', 'https://amzn.to/2YHmqIO')
const PsychoCybernetics = new Book('Psycho-Cybernetics', 'Maxwell Maltz', 'https://images-na.ssl-images-amazon.com/images/I/51EPMqvqURL._SX331_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/psycho-cyberneticsmaxwellmaltz', '')
const Influence = new Book('Influence', 'Robert Cialdini', 'https://images-na.ssl-images-amazon.com/images/I/51gysFA30GL._SX326_BO1,204,203,200_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/influencerobertcialdini', '')
const NegotiationGenius = new Book('Negotiation Genius', 'Deepak Malhotra & Max Bazerman', 'https://images-na.ssl-images-amazon.com/images/I/51B40y7a5iL._SX331_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/negotiationgenius-deepakmalhotramaxbazerman', 'https://amzn.to/2LXI5dV')
const WordsThatWork = new Book('Words That Work', 'Frank Luntz', 'https://images-na.ssl-images-amazon.com/images/I/51dW9rFrrWL._SX327_BO1,204,203,200_.jpg', [BUSINESS, POLITICS], 'https://www.evernote.com/pub/zachagallagher/wordsthatworkitsnotwhatyousayitswhatpeoplehear-frankluntz', 'https://amzn.to/2YHntsf')
const HardThingAboutHardThings = new Book('The Hard Thing About Hard Things', 'Ben Horowitz', 'https://images-na.ssl-images-amazon.com/images/I/51slqM2g3jL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/thehardthingabouthardthings-benhorowitz', 'https://amzn.to/339CDK1')
const When = new Book('When', 'Daniel Pink', 'https://images-na.ssl-images-amazon.com/images/I/4164RkY1yFL._SX329_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/when-danielpink', 'https://amzn.to/2YlggCJ')
const StartWithWhy = new Book('Start With Why', 'Simon Sinek', 'https://images-na.ssl-images-amazon.com/images/I/51BlNddi%2BNL._SX330_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/startwithwhy-simonsinek', 'https://amzn.to/2YIHS4e')
const ThinkingInBets = new Book('Thinking in Bets', 'Annie Duke', 'https://images-na.ssl-images-amazon.com/images/I/41oQkVLDeUL._SY346_.jpg', [BUSINESS, POKER, PSYCHOLOGY, SYSTEMS], 'https://www.evernote.com/pub/zachagallagher/thinkinginbets-annieduke', 'https://amzn.to/2ZG3z1O')
const WhatEveryBodyIsSaying = new Book('What Every Body is Saying', 'Joe Navarro', 'https://images-na.ssl-images-amazon.com/images/I/41t6vPosB0L._SX331_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/whateverybodyissaying-joenavarro', 'https://amzn.to/2MMYOkn')
const FailingForward = new Book('Failing Forward', 'John C. Maxwell', 'https://images-na.ssl-images-amazon.com/images/I/41tq2Rt%2B-aL._SX326_BO1,204,203,200_.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/failingforward-johnc.maxwell', 'https://amzn.to/2Zz3Svu')
const UnlimitedMemory = new Book('Unlimited Memory', 'Kevin Horsley', 'https://images-na.ssl-images-amazon.com/images/I/513g%2Bbu9OdL._SY346_.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/unlimitedmemory-kevinhorsley', 'https://amzn.to/336uNkp')
const HeroWithAThousandFaces = new Book('The Hero With a Thousand Faces', 'Joseph Campbell', 'https://images-na.ssl-images-amazon.com/images/I/51VeJN9ikML._SX323_BO1,204,203,200_.jpg', [MYTHOLOGY, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/theherowithathousandfaces-josephcampbell', 'https://amzn.to/2yC2tIK')
const MasteringBitcoin = new Book('Mastering Bitcoin', 'Andreas Antonopoulos', 'https://images-na.ssl-images-amazon.com/images/I/51nnYGq964L._SX381_BO1,204,203,200_.jpg', [BITCOIN, BLOCKCHAIN, CRYPTOCURRENCY], 'https://www.evernote.com/pub/zachagallagher/masteringbitcoin-andreasantonopoulos', 'https://amzn.to/2OQ0Xfc')
const BitcoinAndCryptocurrencyTechnologies = new Book('Bitcoin and Cryptocurrency Technologies', 'Publisher: Princeton University Press', 'https://images-na.ssl-images-amazon.com/images/I/51KP2HiDznL._SX347_BO1,204,203,200_.jpg', [BITCOIN, BLOCKCHAIN, CRYPTOCURRENCY], 'https://www.evernote.com/pub/zachagallagher/bitcoinandcryptocurrenytechnologies-publisherprincetonuniversitypress', 'https://amzn.to/33q5Dwy')
const FourHourWorkweek = new Book('The 4-Hour Workweek', 'Tim Ferriss', 'https://images-na.ssl-images-amazon.com/images/I/51pPn2LkI8L.jpg', [BUSINESS], 'https://www.evernote.com/pub/zachagallagher/the4-hourworkweek-timferriss', 'https://amzn.to/2Zz3YDm')
const TheFourAgreements = new Book('The Four Agreements', 'Don Miguel Ruiz', 'https://m.media-amazon.com/images/I/51OUyiqgIUL.jpg', [PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/thefouragreementsdonmiguelruiz', '')
const EmotionsRevealed = new Book('Emotions Revealed', 'Paul Ekman', 'https://images-na.ssl-images-amazon.com/images/I/516aVheCskL._SX334_BO1,204,203,200_.jpg', [PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/emotionsrevealed-paulekman', 'https://amzn.to/33df8A6')
const LawsOfHumanNature = new Book('The Laws of Human Nature', 'Robert Greene', 'https://images-na.ssl-images-amazon.com/images/I/41s27w7fY6L.jpg', [BUSINESS, PSYCHOLOGY], 'https://www.evernote.com/pub/zachagallagher/thelawsofhumannature-greene', 'https://amzn.to/31mO5k5')
const FooledByRandomness = new Book('Fooled by Randomness', 'Nassim Taleb', 'https://images-na.ssl-images-amazon.com/images/I/41BdFeFRXbL._SX322_BO1,204,203,200_.jpg', [BUSINESS, ECONOMICS, PHILOSOPHY], 'https://www.evernote.com/pub/zachagallagher/fooledbyrandomnessnassimtaleb', '')

const books = [
  WhatsOurProblem,
  TheHeartOfYoga,
  TheIlluminatedBreath,
  TheBeginningOfInfinity,
  BuildingASecondBrain,
  TheArtOfGathering,
  NoRulesRules,
  AnElegantPuzzle,
  TheNetworkState,
  TheScoutMindset,
  ThinkingInSystems,
  TheChangingWorldOrder,
  TheBodyKeepsTheScore,
  SixEasyPieces,
  RicherWiserHappier,
  TheFiveDysfunctionsOfATeam,
  TheMoralAnimal,
  EightyTwentyPrinciple,
  TheSovereignIndividual,
  Israel,
  Range,
  TheOrderOfTime,
  WhyBuddhismIsTrue,
  RadicalCandor,
  ThePsychologyOfMoney,
  WhyWeSleep,
  AmusingOurselvesToDeath,
  UnderstandingMedia,
  Systemantics,
  TheGoal,
  TheSnowball,
  TheHappinessHypothesis,
  Nudge,
  TheCatalyst,
  StartupNation,
  DaringGreatly,
  Originals,
  GiveAndTake,
  OptionB,
  LeanIn,
  TheEffectiveExecutive,
  SoGoodTheyCantIgnoreYou,
  CleanCode,
  DesigningDataIntensiveApplications,
  Alchemy,
  AtomicHabits,
  TheRideOfALifetime,
  PragmaticProgrammer,
  Factfulness,
  TheInnerGameOfTennis,
  TheGene,
  Buddha,
  TheSerengetiRules,
  TheBoom,
  AlgorithmsToLiveBy,
  Loonshots,
  GettingThingsDone,
  RevisingProse,
  Falter,
  HighOutputManagement,
  PoorCharliesAlmanack,
  Antifragile,
  AgeOfSustainableDevelopment,
  PredictablyIrrational,
  WhenCoffeeAndKaleCompete,
  Hooked,
  Rework,
  CreatingSignatureStories,
  SeekingWisdom,
  Quiet,
  ExtremeOwnership,
  Rebbe,
  Gaia,
  SustainableEnergy,
  LessonsOfHistory,
  AdweekCopywritingHandbook,
  ElephantInTheBrain,
  ThisIsMarketing,
  OriginOfConsciousness,
  Subscribed,
  ImprovWisdom,
  Impro,
  TruthInComedy,
  AnythingYouWant,
  LeanStartup,
  NeverSplitTheDifference,
  BlackBoxThinking,
  PsychoCybernetics,
  Influence,
  NegotiationGenius,
  WordsThatWork,
  HardThingAboutHardThings,
  When,
  StartWithWhy,
  ThinkingInBets,
  WhatEveryBodyIsSaying,
  FailingForward,
  UnlimitedMemory,
  HeroWithAThousandFaces,
  MasteringBitcoin,
  BitcoinAndCryptocurrencyTechnologies,
  FourHourWorkweek,
  TheFourAgreements,
  EmotionsRevealed,
  LawsOfHumanNature,
  FooledByRandomness
]

export default books
