import Reflection from '@/stores/models/reflection'
import {HISTORY} from '@/tags'

const title = '2020'
const html = `<p>This year has been peculiar, to say the least.</p> <p>From the Kobe tragedy, the coronavirus, and George Floyd&apos;s death, 2020 was off to a rough start.</p> <p>However, nothing is all bad or all good.</p> <p>If you wake up each morning with preconceived notions about how the day will go because the past week, month, or year has been going a certain way, you&apos;ve lost awareness.</p> <p>Numbering 365 or 366 days is arbitrary.</p> <p>Everyone has had unique life events this year, such as newborns, engagements, new jobs, new opportunities, etc.</p> <p>Although the mass media tends to report on negative news, the world is getting better.</p> <p>As Pfizer and Moderna continue to distribute COVID-19 vaccines, there is hope that a year from now we&apos;ll be back to the previous normal.</p> <p>After Joe Biden and Kamala Harris won the U.S. election, the tides seemed to have turned.</p> <p>Sure, there are still many problems in the world.</p> <p>Every problem is an opportunity.</p> <p>Start where you are, with what you have, and try to make a difference in what matters to you.</p> <p>There will be another pandemic someday, another tragedy, another senseless murder, but we have a choice each day.</p> <p>We can either dwell in the past, or move forward.</p> <p>I choose to move forward.</p>`

export default new Reflection({
  html,
  id: 'twenty-twenty',
  tags: [HISTORY],
  title,
  words: 210,
  written: '12/22/20'
})
