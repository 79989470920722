import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './stores/rootReducer'
import Routes from './Routes'
import GoogleTagManager from '@/utils/tagManager'

import './styles/index.scss'

const store = createStore(rootReducer)
const isDev = process.env.NODE_ENV === 'development'
const gtmId = process.env.GTM_ID

export default class App extends Component {
  render () {
    return (
      <Provider store={store}>
        { !isDev && gtmId && <GoogleTagManager gtmId={gtmId} /> }
        <Routes />
      </Provider>
    )
  }
}
