import Reflection from '@/stores/models/reflection'

const title = 'Twitter'
const html = `<div>I've definitely learned more using Twitter than any other social media site.</div><div><br/></div><div>I made an account in early 2012, which was late relative to the hundreds of millions of users already on the site at the time.</div><div><br/></div><div>Everyone has a unique experience on Twitter.</div><div><br/></div><div>No two feeds are the same.</div><div><br/></div><div>Defining Twitter by your personal experience is just as much of a misconception as defining the world by your personal experience.</div><div><br/></div><div><a href="https://fs.blog/2015/11/map-and-territory/">The map is not the territory</a>.</div><div><br/></div><div>Twitter data scientists probably don't even understand the impact that it has on the world's consciousness.</div><div><br/></div><div>The internet is like a brain — each person a neuron.</div><div><br/></div><div>The web forms synapses that, in total, generate something greater than the sum of its parts.</div><div><br/></div><div>It can be a battlefield of hate and spite, or a source of inspiration and insight.</div><div><br/></div><div>Twitter becomes what you make of it.</div>`

export default new Reflection({
  html,
  id: 'twitter',
  tags: null,
  title,
  words: 143,
  written: '7/13/21'
})
