import Reflection from '@/stores/models/reflection'

const title = 'Meta Connect 2023'
const html = `<div>I visited Menlo Park this week for the Meta Connect event.</div><div><br/></div><div>I'd never been to the MPK offices before, so I enjoyed exploring the campus.</div><div><br/></div><div>One highlight was biking between Classic Campus and the newer buildings.</div><div><br/></div><div>It was cool to play a small part of the event, since my team worked on the virtual reality version in Horizon Worlds.</div><div><br/></div><div>I found a<a href="https://hackmd.io/@hiroP/SJs31hMep"> blog post</a> on Twitter that does a great job describing the immersive VR experience.</div><div><br/></div><div>The event was a success, and I'm excited for the Quest 3 release.</div><div><br/></div><div>VR/AR/MR (virtual, augmented, and mixed reality) tech has made huge improvements in the past couple of years.</div><div><br/></div><div>I'm inspired by the audacious goals this technology aims to achieve.</div>`

export default new Reflection({
  html,
  id: 'meta-connect-2023',
  tags: null,
  title,
  words: 117,
  written: '9/28/23'
})
