import Reflection from '@/stores/models/reflection'

const title = 'Self Reviews'
const html = `<div>I'm writing a self evaluation at work this week.</div><div><br/></div><div>Outside of corporate America, formal reviews are rare.</div><div><br/></div><div>As with most things in life, it's far easier if you make consistent steps along the way instead of waiting until the last minute.</div><div><br/></div><div>For self reviews, having a weekly log makes the process much easier.</div><div><br/></div><div>I know exactly what I did and when I did it.</div><div><br/></div><div>Although performance reviews are bureaucratic practices that most people dislike, they're a great opportunity to take a step back and examine your life as a whole.</div><div><br/></div><div>If you're always reacting to the issues of the day, you won't notice the big picture.</div><div><br/></div><div>Whether the short-term outcome of the review is positive or negative, it can illuminate blind spots.</div><div><br/></div><div>How could you improve?</div><div><br/></div><div>What went well?</div><div><br/></div><div>What didn't?</div><div><br/></div><div>Why?</div><div><br/></div><div>Maybe you find out you're in the wrong place or career, and that's okay.</div><div><br/></div><div>A self review is also an opportunity to reset.</div><div><br/></div><div>Whether you knocked it out of the park or struck out, you still have to focus on your next at-bat.</div>`

export default new Reflection({
  html,
  id: 'self-reviews',
  tags: null,
  title,
  words: 175,
  written: '6/23/21'
})
