import Reflection from '@/stores/models/reflection'

const title = 'What Matters Most'
const html = `<div>My Thanksgiving weekend was full of family and friends, but also anxiety about my health.</div><div><br/></div><div>Since I've been going through some health scares, I realized that I want to express more love in my relationships.</div><div><br/></div><div>My parents divorced when I was in grade school, and I used to never even say "I love you" to them.</div><div><br/></div><div>I'm not sure why.</div><div><br/></div><div>I might have just followed my older brother, who can also be emotionally detached.</div><div><br/></div><div>Perhaps I was unwilling to show affection to one parent and not the other.</div><div><br/></div><div>In any case, I spent the first 16 years of my life not expressing my love for my own parents.</div><div><br/></div><div>That changed when I had my first panic attack in high school.</div><div><br/></div><div>A crisis has a way of confronting you with reality.</div><div><br/></div><div>Mental or physical health issues will plague everyone eventually.</div><div><br/></div><div>Don't wait for a crisis to express how you feel to the people who mean the most to you.</div>`

export default new Reflection({
  html,
  id: 'what-matters-most',
  tags: null,
  title,
  words: 158,
  written: '11/29/22'
})
