import Reflection from '@/stores/models/reflection'

const title = 'Outbreaks'
const html = `<div>Death is always a good wake up call.</div><div><br/></div><div>Amid the current global coronavirus outbreak, I’ve been thinking about risk management.</div><div><br/></div><div>There may be contentious debates on the seriousness of the disease, but experts have expressed concerns regarding the inability of hospitals to handle an exponential transmission rate.</div><div><br/></div><div>The biggest risk seems to be the disease overwhelming the healthcare system.</div><div><br/></div><div>Although the statistics don't place me at risk of serious illness, there is still the hazard that I become a carrier and proliferate the virus.</div><div><br/></div><div>Exponential growth reaches unmanageable levels quickly.</div><div><br/></div><div>When containment is impossible, delaying the spread is the best option.</div><div><br/></div><div>People overestimate personal health risks and underestimate societal health risks.</div><div><br/></div><div>They disregard the possibility of a future event that has no experiential precedent.</div><div><br/></div><div>Just because they’ve only seen white swans, they think black swans don’t exist.</div><div><br/></div><div>When the worst-case scenario is catastrophic, it’s best to err on the side of caution.</div><div><br/></div><div>The downside of cautiousness is nothing compared to the downside of recklessness.</div><div><br/></div><div>When dealing with uncertainty, we can still optimize our decision-making.</div><div><br/></div><div>Don’t panic, but don’t be reckless.</div><div><br/></div><div>It’s better to be safe than sorry.</div><div><br/></div><div>Take precautions.</div>`

export default new Reflection({
  html,
  id: 'outbreaks',
  tags: null,
  title,
  words: 191,
  written: '3/11/20'
})
