import Reflection from '@/stores/models/reflection'

const title = 'Sacrifices'
const html = `<div>I left a July 4th party early this past weekend (compared to my younger self).</div><div><br/></div><div>One of the bouncers, Vincent, asked as I stepped out the front door, "You're leaving now?"</div><div><br/></div><div>I told him that I've outgrown the partying and getting-wasted lifestyle, and that anyone I could meet later in the night isn't the type of person I want to meet right now anyway.</div><div><br/></div><div>We shook hands, and he nodded in agreement as I said we're all on our own path through life.</div><div><br/></div><div>Most people would claim that I made a sacrifice by giving up the fun I could have had.</div><div><br/></div><div>The word sacrifice implies that you're losing something of value.</div><div><br/></div><div>I know what I want and what I don't want, and I knew that I'd rather wake up refreshed than with a hangover.</div><div><br/></div><div>Every hour you spend doing anything can be a sacrifice by not alternatively doing something else of higher supposed value.</div><div><br/></div><div>Even taking the time right now to write this reflection is, in some sense, a sacrifice.</div><div><br/></div><div>I've committed to doing this every week, and there are always other things I could be doing instead.</div><div><br/></div><div>I do it because I choose to do it, and that's the only reason you need to do anything in your life.</div><div><br/></div><div>Sacrifices aren't losses if you gain something of greater value.</div><div><br/></div><div>You define what's valuable.</div>`

export default new Reflection({
  html,
  id: 'sacrifices',
  tags: null,
  title,
  words: 224,
  written: '7/6/21'
})
