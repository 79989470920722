import Reflection from '@/stores/models/reflection'

const title = 'Inner Scorecard'
const html = `<div>When you seek approval, you put your self-worth in somebody else’s hands.</div><div><br/></div><div>"The big question about how people behave is whether they've got an Inner Scorecard or an Outer Scorecard. It helps if you can be satisfied with an Inner Scorecard."</div><div>– Warren Buffett</div><div><br/></div><div>I love what Marcus Aurelius refers to people’s praise and admiration: "the clacking of tongues."</div><div><br/></div><div>Ask yourself why you do the things you do.</div>`

export default new Reflection({
  html,
  id: 'inner-scorecard',
  tags: null,
  title,
  words: 68,
  written: '10/6/20'
})
