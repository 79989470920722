import Reflection from '@/stores/models/reflection'

const title = 'Outages'
const html = `<div>Facebook, Instagram, and Whatsapp were all down today for over 5 hours.</div><div><br/></div><div>What users didn't also know is that Facebook's internal tools were down as well.</div><div><br/></div><div>Tens of thousands of employees had no access to most of the tools that allow them to do their jobs.</div><div><br/></div><div>In a centralized system, a single point of failure can break everything.</div><div><br/></div><div>We rely on technology so much in our lives that it's invisible until something stops working.</div><div><br/></div><div>If global payment systems like credit card processors went down, consumers and businesses worldwide would lose the ability to transact.</div><div><br/></div><div>This FB outage may foreshadow that decentralized apps will win in the long term.</div>`

export default new Reflection({
  html,
  id: 'outages',
  tags: null,
  title,
  words: 108,
  written: '10/4/21'
})
