import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Convenient Food'
const html = `<div>Your environment affects your behavior.</div><div><br/></div><div>I work for a company that generously gives employees free food at the office, and the cafeteria and micro-kitchens tempt you.</div><div><br/></div><div>Since I started working from home in 2020, I cook at home every day and buy fresh produce every few days.</div><div><br/></div><div>I've always been a health nut.</div><div><br/></div><div>I'd never buy something at the grocery store without reading the label first.</div><div><br/></div><div>You really have to search for food that doesn't have added sugar, seed oils, and preservatives.</div><div><br/></div><div>Most real food, like fruits and vegetables, don't have nutrition labels.</div><div><br/></div><div>A lot of the office cafeteria choices, although cooked fresh, have canola oil and added sugar.</div><div><br/></div><div>Even "healthy" snacks like protein bars have unhealthy additives.</div><div><br/></div><div>Although I wouldn't buy the office protein bars because of the ingredients, I still caved and ate them last week.</div><div><br/></div><div>When you're hungry, resisting food that's right in front of you is hard.</div><div><br/></div><div>The easiest way to avoid eating unhealthy food at home is to just not buy it.</div><div><br/></div><div>If it's not in your kitchen or pantry, you won't have to resist every time you see it.</div><div><br/></div><div>When you're out in the world, it takes iron discipline to say no to certain foods.</div><div><br/></div><div>If you know you'll be around certain food options, be intentional and know your environment.</div>`

export default new Reflection({
  html,
  id: 'convenient-food',
  tags: [HEALTH],
  title,
  words: 216,
  written: '4/5/22'
})
