export default class Reflection {
  constructor (reflectionData) {
    const {
      html,
      id,
      tags,
      title,
      words,
      written
    } = reflectionData
    this.html = html
    this.id = id
    this.tags = tags
    this.title = title
    this.words = words
    this.written = written
  }
}
