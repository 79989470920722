export default class BlogPost {
  constructor (data) {
    const {
      body,
      categories,
      featuredImage,
      metaDescription,
      published,
      seoTitle,
      slug,
      summary,
      tags,
      title
    } = data
    this.body = body || ''
    this.categories = categories
    this.featuredImage = featuredImage
    this.metaDescription = metaDescription
    this.published = published
    this.seoTitle = seoTitle
    this.slug = slug
    this.summary = summary
    this.tags = tags
    this.title = title
  }

  addBody (body) {
    this.body = body
  }
}
