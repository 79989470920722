import React, { Component } from 'react'

import './NotFoundPage.scss'

export default class NotFoundPage extends Component {
  render () {
    return (
      <div className='NotFoundPage'>
        <h1>404 — Not Found</h1>
        <p>"I thought you were a keen-eyed lion, but now I understand that you are a blind dog."</p>
        <p>— Seungsahn</p>
      </div>
    )
  }
}
