import React, { Component, Fragment } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Typist from 'react-typist'
import { Particles } from '@/components'
import { uiStoreActions } from '@/stores/uiStore'

import './HomePage.scss'

const { isDoneTyping } = uiStoreActions

const introText1 = 'Hello 🧡'

class HomePage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  handleTypingAnimationEnd = () => {
    const { dispatch } = this.props
    dispatch(isDoneTyping)
  }
  render () {
    const { isDoneTypingAnimation } = this.props
    return (
      <Fragment>
        {isDoneTypingAnimation ? <Particles /> : null}
        <div className='HomePage'>
          {!isDoneTypingAnimation ? (
            <Fragment>
              <Typist
                avgTypingDelay={45}
                cursor={{ blink: true }}
                onTypingDone={this.handleTypingAnimationEnd}
                startDelay={300}
              >
                <p>{introText1}</p>
                <Typist.Delay ms={360} />
                <br />
              </Typist>
              <br />
            </Fragment>
          ) : (
            <Fragment>
              <p>{introText1}</p>
            </Fragment>
          )}
          {isDoneTypingAnimation ? (
            <div className='HomePage__main'>
              <hr />
              <br />
              <h3>Brief Intro</h3>
              <br />
              <p>I live in Los Angeles. ☀️</p>
              <br />
              <p>When I'm not coding or reading you can usually find me in a yoga class.</p>
              {/*
              <hr />
              <br />
              <h3>Quick facts</h3>
              <br />
              <p>Favorite place: Yosemite</p>
              <br />
              <p>Favorite book: Gödel, Escher, Bach</p>

              <hr />
              <br />
                 <p>Before you go anywhere, it's important to me that you understand the <Link to='/keeling-curve'>Keeling Curve</Link>.</p>
                 <p>If you want to keep up with what I learn, sign up for my newsletter below. It's fairly infrequent and brief, once a month at the most. I share book notes, new posts, and anything I've felt has added value to my life.</p> */}
            </div>
          ) : null}
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = ({ uiStore }) => {
  const { isDoneTypingAnimation } = uiStore
  return { isDoneTypingAnimation }
}

export default connect(mapStateToProps)(HomePage)
