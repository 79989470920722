import Reflection from '@/stores/models/reflection'

const title = 'Bubbles'
const html = `<div>Michael Burry, of The Big Short Fame, currently displays his Twitter name as Cassandra, the character in Greek mythology who could predict the future but is cursed with the inability to convince anyone.</div><div><br/></div><div>Burry's recent tweet — I excluded the link because he deleted it today! — showed a graph of the S&amp;P 500 against margin debt, foreboding a possible market collapse. <sup>[1]</sup></div><div><br/></div><div>Margin debt is money that speculators borrow to buy more securities than they can afford.</div><div><br/></div><div>Combined with <a href="https://www.investopedia.com/terms/c/calloption.asp" rev="en_rl_none">call options</a> and other derivatives, margin debt turns the stock market from a sound investment into a high-stakes casino.</div><div><br/></div><div>To make matters worse, the U.S. Fed printed trillions of dollars in 2020, inflating the USD supply.</div><div><br/></div><div>I can't predict the future, but it doesn't take a genius to see that another recession is bound to happen sooner or later.</div><div><br/></div><div>Our economic system has unfailingly produced boom/bust cycles.</div><div><br/></div><div>How can we create a self-correcting, stable global economic system?</div><div><br/></div><div>We need to shift from bull and bear markets to a steady, sustainable economy.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> I couldn't find the up-to-date graph, but <a href="https://d1-invdn-com.akamaized.net/content/pic3f3afe9280f8f5008a1afccd30f8f186.png" rev="en_rl_none">here</a> is a June 2017 version. The 2021 version has a drastically widened gap on the right, even after the spring 2020 spike. <a href="https://markets.businessinsider.com/news/stocks/big-short-michael-burry-warns-stock-market-huge-risk-crashing-2021-2-1030106969" rev="en_rl_none">Here</a> is an article about said tweet. </div>`

export default new Reflection({
  html,
  id: 'bubbles',
  tags: null,
  title,
  words: 210,
  written: '2/23/21'
})
