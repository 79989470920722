import Reflection from '@/stores/models/reflection'

const title = 'Years'
const html = `<div>If one lap around a standard outdoor track represents your entire life, from birth to death, how many meters would one year equal?</div><div><br/></div><div>For someone who lives to be 100, that's 4 meters — a few steps or a couple short strides.</div><div><br/></div><div>How far along the track are you right now?</div><div><br/></div><div>What do you want to accomplish before you reach the finish line?</div><div><br/></div><div>It's not a race, you may want to think about how to slow things down a bit.</div><div><br/></div><div>You can only take steps forward, not back.</div><div><br/></div><div>We're all at different points moving at different speeds, regardless of age.</div><div><br/></div><div>Some people know they're on the final stretch, and others think they're in the first half but suddenly discover that they actually only have a handful of steps remaining.</div><div><br/></div><div>Wherever you are, you're with all of us on the same track, heading to the same destination.</div><div><br/></div><div>To still be on the track is precious.</div>`

export default new Reflection({
  html,
  id: 'years',
  tags: null,
  title,
  words: 154,
  written: '6/29/21'
})
