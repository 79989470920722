import Reflection from '@/stores/models/reflection'

const title = 'Ramping Up'
const html = `<div>I joined a new team a couple weeks ago to work on a virtual reality app.</div><div><br/></div><div>I've never written software before for VR, so I took a risk by switching teams instead of staying in a more comfortable position.</div><div><br/></div><div>Brené Brown has a name for uncomfortable new situations: <a href="https://brenebrown.com/podcast/brene-on-ffts/" rev="en_rl_none">FFTs</a> ("effing first times").</div><div><br/></div><div>"When we get to the point that we only do things that we’re already good at doing, we stop growing."</div><div><br/></div><div>I don't claim to have mastered my craft on my previous team, but I know that I want to learn VR development.</div><div><br/></div><div>I'd rather challenge myself in an unknown environment than play it safe in a known one.</div><div><br/></div><div>However, starting something new isn't always easy.</div><div><br/></div><div>When the learning curve is steep, don't be too hard on yourself in the early stages.</div><div><br/></div><div>In technology, the ability to adapt is more important in the long run than your current skill sets.</div><div><br/></div><div>Put yourself into situations where you have to ramp up.</div><div><br/></div><div>If you don't bet on yourself, who else will?</div><div><br/></div><div>Growth requires discomfort.</div>`

export default new Reflection({
  html,
  id: 'ramping-up',
  tags: null,
  title,
  words: 123,
  written: '2/16/21'
})
