import Reflection from '@/stores/models/reflection'

const title = 'Emergency Room Visits'
const html = `<div>Last Thursday night I went to the ER.</div><div><br/></div><div>The visit was precautionary, so I'm fine, but it reminded me of life's fragility.</div><div><br/></div><div>We're always one breath away from death.</div><div><br/></div><div>Be present and appreciate the simple joy of just being healthy.</div>`

export default new Reflection({
  html,
  id: 'emergency-room-visits',
  tags: null,
  title,
  words: 40,
  written: '3/8/22'
})
