import Reflection from '@/stores/models/reflection'

const title = 'Phone Addiction'
const html = `<div>Although most people would deny it, we are all addicted to our mobile devices.</div><div><br/></div><div>When you have a 3 by 6 inch screen in your pocket everywhere you go, you never fully experience life.</div><div><br/></div><div>As long as you have your phone with you, you will eventually lose awareness of the present moment.</div><div><br/></div><div>Attention is our most valuable resource, and we give it away too easily.</div><div><br/></div><div>Like gravity, the phone has an invisible pull.</div><div><br/></div><div>The habit to reach for your device becomes so automatic that you don’t even notice it or question why it’s happening.</div><div><br/></div><div>When you stop at a red light in your car, do you reach for your phone?</div><div><br/></div><div>To really understand how much your device influences you, turn on Do Not Disturb mode and see how many times you reach for it throughout the day.</div><div><br/></div><div>When all notifications and calls are silent, the only thing that pings you to reach for it is your brain.</div><div><br/></div><div>The least you can do is acknowledge that you have a pattern of picking it up.</div><div><br/></div><div>Whether it’s every five minutes or every hour, your impulse to look at it has been repeating for probably a decade now.</div><div><br/></div><div>It’s easier said than done, but if you want to truly be present, ditch the phone.</div>`

export default new Reflection({
  html,
  id: 'phone-addiction',
  tags: null,
  title,
  words: 211,
  written: '9/22/20'
})
