import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'Return to Office'
const html = `<div>Yesterday was the official "Return to Office" day for Meta (Facebook), almost 2 years after the COVID-19 pandemic started.</div><div><br/></div><div>I'm the only engineer on my team in LA, since we're mostly distributed and full-time remote.</div><div><br/></div><div>It was good to meet a few people, but I didn't get as much work done as usual.</div><div><br/></div><div>I'm much more productive at home than in an open office.</div><div><br/></div><div>On the other hand, you can't put a value on the serendipity of in-person interactions.</div><div><br/></div><div>I'll likely have a hybrid schedule where some days I come in and others I stay home, depending on what I have to get done.</div><div><br/></div><div>Everyone's different, and people should work where they feel and perform best.</div>`

export default new Reflection({
  html,
  id: 'return-to-office',
  tags: [BUSINESS],
  title,
  words: 116,
  written: '3/29/22'
})
