import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'Virtual Offsites'
const html = `<p>This week my team at work had a virtual offsite.</p> <p>We all ordered acrylic painting supplies in advance, and an art teacher guided us through painting a landscape.</p> <p>I&apos;ve had my fair share of Zoom meetings during 2020 so the idea of spending the day on video chat didn&apos;t sound ideal, but painting was actually quite therapeutic and relaxing.</p> <p>We also each prepared a slide about ourselves to present to the group.</p> <p>I used to dread public speaking because of my severe anxiety, but this time it wasn&apos;t so bad and I actually enjoyed learning a bit about my colleagues.</p> <p>Although the reason for having a virtual event was the pandemic, I think online team building experiences will grow as an industry as companies transition to distributed work.</p> <p>Of course it&apos;s better to get everyone together in person, but hosting multiple events per year is difficult for remote teams.</p> <p>The ability to connect with people over the internet is one of the challenges of our time.</p> <p>These digital social experiences will improve and become more immersive and lifelike with VR in the future.</p> <p>Building relationships is critical to collaborative work.</p> <p>To run a successful distributed organization, you&apos;ll need to create environments for people to connect online.</p>`

export default new Reflection({
  html,
  id: 'virtual-offsites',
  tags: [BUSINESS],
  title,
  words: 206,
  written: '12/8/20'
})
