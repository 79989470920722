import Reflection from '@/stores/models/reflection'

const title = 'Final Stretches'
const html = `<div>I love running the 400 meter dash.</div><div><br/></div><div>When you get to the final straightaway, you have a decision.</div><div><br/></div><div>You can either let the fatigue slow you down or maintain your form.</div><div><br/></div><div>Keep driving your knees up and let your shoulders and face relax.</div><div><br/></div><div>You can see the finish line.</div><div><br/></div><div>Stay focused and execute until you cross it.</div>`

export default new Reflection({
  html,
  id: 'final-stretches',
  tags: null,
  title,
  words: 57,
  written: '5/17/22'
})
