import Reflection from '@/stores/models/reflection'

const title = 'Satsang'
const html = `<div>Every night during the yoga teacher training, the group gathers for Satsang.</div><div><br/></div><div>We sing songs, share poems/stories, and meditate.</div><div><br/></div><div>In order to build a community, people have to connect during meaningful shared experiences.</div>`

export default new Reflection({
  html,
  id: 'satsang',
  tags: null,
  title,
  words: 33,
  written: '6/27/23'
})
