import Reflection from '@/stores/models/reflection'

const title = 'Blank'
const html = `<div>If you overfill your life, you won't have space to reflect.</div>`

export default new Reflection({
  html,
  id: 'blank',
  tags: null,
  title,
  words: 11,
  written: '10/12/21'
})
