import Reflection from '@/stores/models/reflection'

const title = 'Predictions'
const html = `<div>Life is uncertain.</div><div><br/></div><div>Theories and tools give us a false impression that we fundamentally understand nature.</div><div><br/></div><div>Astronomers can predict the trajectories of planets and stars, but most systems don’t obey structured models.</div><div><br/></div><div>Every calculation has an associated margin of error.</div><div><br/></div><div>A 99% confidence interval still fails 1% of the time.</div><div><br/></div><div>Don’t underestimate outliers.</div><div><br/></div><div>Beware of expressing certainty with the words “always” or “never.”</div><div><br/></div><div>Nobody knows what the future holds.</div>`

export default new Reflection({
  html,
  id: 'predictions',
  tags: null,
  title,
  words: 69,
  written: '5/12/20'
})
