import Reflection from '@/stores/models/reflection'
import {ECONOMICS} from '@/tags'

const title = 'Bear Markets and the USD'
const html = `<div>The S&amp;P 500 is down around 20% so far this year.</div><div><br/></div><div>Last week, the US government reported year-over-year inflation at 8.6%.</div><div><br/></div><div>I just finished Ray Dalio's book, <i>The Changing World Order</i>, and his outlook is not so optimistic for the United States.</div><div><br/></div><div>In short, world powers throughout history have risen and fallen in similar patterns.</div><div><br/></div><div>The US is in the declining stage where empires of the past have fallen.</div><div><br/></div><div>Insurmountable debt, massive wealth gaps, and internal conflict are signs of a world power's end.</div><div><br/></div><div>Money is a mental construct.</div><div><br/></div><div>Government officials can change fiscal and monetary policy at any time.</div><div><br/></div><div>If people lose faith in the US government and make a run on banks to get out of dollar-denominated assets, wealth around the world would disappear.</div><div><br/></div><div>In the long term, the future of money will be digital currency secured by cryptography.</div><div><br/></div><div>All types of money will have their own bull and bear market cycles.</div>`

export default new Reflection({
  html,
  id: 'bear-markets-and-the-usd',
  tags: [ECONOMICS],
  title,
  words: 154,
  written: '6/14/22'
})
