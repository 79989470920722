import Reflection from '@/stores/models/reflection'

const title = 'Inhaling Food'
const html = `<div>Last week I accidentally inhaled a tiny piece of raw carrot for the 3rd time in a year…</div><div><br/></div><div>The nurse I spoke to on the phone said to go to the ER if I couldn't cough it out.</div><div><br/></div><div>The previous time, my body naturally broke it down in a couple days, so I didn't follow her advice.</div><div><br/></div><div>I ended up being fine, but I decided that I'm done with raw carrots.</div><div><br/></div><div>Choking on food is not fun.</div><div><br/></div><div>Please be present when you're eating.</div>`

export default new Reflection({
  html,
  id: 'inhaling-food',
  tags: null,
  title,
  words: 83,
  written: '10/18/22'
})
