import Reflection from '@/stores/models/reflection'

const title = 'Finding Your Voice'
const html = `<div>One of the most common fears is public speaking.</div><div><br/></div><div>I've personally struggled with this fear for most of my life.</div><div><br/></div><div>My nervous system would get the best of me — constricting my throat, spiking my heart rate, and sending me into a fight-or-flight state.</div><div><br/></div><div>I know what it's like to lack command over my voice and to want to hide.</div><div><br/></div><div>I've had successes when I've been confident in the material and comfortable with the audience, but I've also had epic failures.</div><div><br/></div><div>Whether I succeed or fail, life moves on.</div><div><br/></div><div>Most people have unique reasons for not speaking up, sharing their ideas more, or putting themselves out there.</div><div><br/></div><div>Insecurities and self-doubt can hold you back if you fixate on them.</div><div><br/></div><div>I'm still on the journey to find my voice and not let past doubts and worries stop me from taking action.</div><div><br/></div><div>If you're facing a similar challenge, just keep taking steps forward.</div><div><br/></div><div>Everyone's unique life experiences and perspectives can benefit the world.</div>`

export default new Reflection({
  html,
  id: 'finding-your-voice',
  tags: null,
  title,
  words: 161,
  written: '10/3/23'
})
