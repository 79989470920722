import Reflection from '@/stores/models/reflection'

import {
  ENERGY,
  ENVIRONMENT,
  SYSTEMS
} from '@/tags'

const title = 'The Frog'
const html = '<div>We are currently living in an experiment unprecedented in the history of our species.</div><div><br /></div><div>Most of the time since the Agricultural Revolution roughly 12,000 years ago, creating goods and services relied on muscular expenditure.</div><div><br /></div><div>When we began burning resources that stored the sun’s energy, we sparked a flame that has not stopped growing in size year after year continuing to today.</div><div><br /></div><div>The notion that we can expect this experiment to continue unencumbered is myopic and foolish, yet we still allow the burning of coal in power plants.</div><div><br /></div><div>Although the current estimates show that there are only about 50 years worth of oil remaining in reserve worldwide, we still have our collective foot pressed firmly on the pedal, accelerating.</div><div><br /></div><div>In 2018, the United States used 12% renewable energy and 8% nuclear, leaving 80% from carbon-based sources.</div><div><br /></div><div>This experiment is coming to an end one way or another.</div><div><br /></div><div>Unlike the fabled frog in the pot of lukewarm water slowly reaching the boiling point, we cannot jump out of the pot.</div><div><br /></div><div>We simply need to open our eyes, and turn off the flame.</div>'

export default new Reflection({
  html,
  id: 'the-frog',
  tags: [ENERGY, ENVIRONMENT, SYSTEMS],
  title,
  words: 181,
  written: '8/1/19'
})
