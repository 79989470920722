import Reflection from '@/stores/models/reflection'

const title = 'Peace and Chaos'
const html = `<div>The events on and since October 7th have inflamed the world, and a peaceful resolution seems more distant than ever.</div><div><br/></div><div>Meanwhile, in America, families will gather this week for Thanksgiving while many around the world continue to mourn and suffer.</div><div><br/></div><div>More than 200 hostages are still in Gaza.</div><div><br/></div><div>The events of October 7th have changed the lives of millions, both directly and indirectly.</div><div><br/></div><div>Tragedies often give people meaning.</div><div><br/></div><div>What you live for is something to be grateful for.</div>`

export default new Reflection({
  html,
  id: 'peace-and-chaos',
  tags: null,
  title,
  words: 78,
  written: '11/21/23'
})
