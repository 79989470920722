import Reflection from '@/stores/models/reflection'

const title = 'According to Plan'
const html = `<div>Shit happens.</div><div><br/></div><div>Things don't always go the way you want or expect them to go.</div><div><br/></div><div>After 2020 it's pretty clear that events are out of our control.</div><div><br/></div><div>When major problems arise, it's easy to let them bring us down.</div><div><br/></div><div>Some things we need to feel and shouldn't avoid, like a death in the family.</div><div><br/></div><div>For most people, most of the time, problems are small, but we let them disproportionately affect us.</div><div><br/></div><div>If your problems are small, you're lucky.</div><div><br/></div><div>Things could be much worse.</div><div><br/></div><div>No matter what happens today, change is coming.</div><div><br/></div><div>You can plan, but you can't prepare for everything.</div><div><br/></div><div>Tomorrow will be a new, unpredictable day.</div>`

export default new Reflection({
  html,
  id: 'according-to-plan',
  tags: null,
  title,
  words: 107,
  written: '5/11/21'
})
