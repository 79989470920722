import Reflection from '@/stores/models/reflection'

const title = 'Survival'
const html = `<div>An old proverb says that a healthy person has a thousand wishes, a sick person only one.</div><div><br/></div><div>Without health, everything else is irrelevant.</div>`

export default new Reflection({
  html,
  id: 'survival',
  tags: null,
  title,
  words: 23,
  written: '1/10/23'
})
