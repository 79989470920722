import Reflection from '@/stores/models/reflection'

const title = 'Resuming'
const html = `<p>Life contains both continuous experiences of flow and discrete moments.</p> <p>We usually don&apos;t do one thing for a long period of time.</p> <p>The mind has a tendency to intervene and divert your attention, or time just passes between events.</p> <p>Whether you&apos;re doing work, practicing a golf swing, or catching up with a friend, there is always an interval between now and the last moment you were focusing on the same thing.</p> <p>Starting something back up presents a challenge.</p> <p>The longer the gap between this time and the last time, the more effort it takes to get back up to speed.</p> <p>As I write this reflection, I&apos;m resuming my weekly practice.</p> <p>The decision to resume has become easier for me as my streak builds.</p> <p>I&apos;m writing to improve my thinking and to rely on myself to commit to the practice &mdash; week after week.</p> <p>The first moment of the restart is always the most difficult.</p> <p>It requires activation energy to get the ball rolling.</p> <p>You have to exert a force to spark the process.</p> <p>Once you&apos;re moving, it&apos;s easier to stay in motion.</p> <p>Like moving from static friction to kinetic friction, the resistance weakens.</p> <p>Then you can enjoy the ride.</p> <p>We&apos;re all capable of creating something from nothing.</p> <p>Going from 0 to 1.</p> <p>Each week, each day, each moment.</p> <p>There are endless opportunities to begin again.</p>`

export default new Reflection({
  html,
  id: 'resuming',
  tags: null,
  title,
  words: 225,
  written: '12/2/20'
})
