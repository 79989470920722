import Reflection from '@/stores/models/reflection'

const title = 'Embrace Chaos'
const html = `<div>In 2012 I was waiting for a train in Prague with some friends from my study abroad program.</div><div><br/></div><div>We overheard a man exclaim, "Embrace chaos!"</div><div><br/></div><div>A few of us repeated it and joked that we should take a train to Bratislava instead of Berlin, inspired by the movie EuroTrip.</div><div><br/></div><div>Sometimes I remember that phrase and laugh, but I also recognize the wisdom in it.</div><div><br/></div><div>Life is unpredictable.</div><div><br/></div><div>You can either resist it or surrender to it — not helplessly, but by courageously letting go of attachment.</div>`

export default new Reflection({
  html,
  id: 'embrace-chaos',
  tags: null,
  title,
  words: 86,
  written: '8/8/23'
})
