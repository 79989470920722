import Reflection from '@/stores/models/reflection'

const title = `Parkinson's Law`
const html = `<div>"Work expands so as to fill the time available for its completion."</div><div>– C. Northcote Parkinson</div><div><br/></div><div>Parkinson first wrote about his Law in <a href="https://www.economist.com/news/1955/11/19/parkinsons-law">his comical 1955 essay</a> about the tendency of government organizations to grow regardless of whether there is actually any work to be done.</div><div><br/></div><div>We’ve all procrastinated.</div><div><br/></div><div>We know the feeling of anxiety when we’re behind schedule.</div><div><br/></div><div>A report is due.</div><div><br/></div><div>The test is tomorrow.</div><div><br/></div><div>The meeting starts in an hour.</div><div><br/></div><div>Due dates are critical to getting work done, but there's a line between defining a deadline that creates a sense of urgency and one that's too relaxed.</div><div><br/></div><div>A combination of both tension and rest is necessary for sustainable long-term growth.</div><div><br/></div><div>You don’t want to overcommit and burn out, but you also don’t want to move at a snail's pace.</div>`

export default new Reflection({
  html,
  id: 'parkinsons-law',
  tags: null,
  title,
  words: 132,
  written: '11/10/20'
})
