import Reflection from '@/stores/models/reflection'

const title = 'Meta'
const html = `<div>Last Thursday, Facebook changed its name to Meta.</div><div><br/></div><div>Crypto/web3 communities talk about the metaverse as the future of the internet, but they criticize Facebook for trying to play a central role in building out what they want to be decentralized.</div><div><br/></div><div>Facebook, or Meta (it still feels weird to call it that), is actually building an open platform, not a walled garden.</div><div><br/></div><div>It's investing billions of dollars into core technologies around AR/VR, which will be a huge net positive for the industry.</div><div><br/></div><div>No single entity will own the metaverse.</div><div><br/></div><div>Meta is a centralized company, but its capital will accelerate the transition to a more equitable world where creators can thrive and people from low socioeconomic backgrounds have equal access to the same resources that the top 1% have.</div><div><br/></div><div>A more virtual world may sound dystopian or utopian depending on your take, but it is a democratizing force.</div><div><br/></div><div>In 10 years, people won't think of Facebook when they think of Meta.</div>`

export default new Reflection({
  html,
  id: 'meta',
  tags: null,
  title,
  words: 159,
  written: '11/2/21'
})
