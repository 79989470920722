import Reflection from '@/stores/models/reflection'

const title = 'No'
const html = `<div>“No.”</div><div><br/></div><div>It’s the most powerful word in the English dictionary.</div><div><br/></div><div>Rosa Parks was brave enough to say it instead of moving to the back of the bus.</div><div><br/></div><div>We define what we tolerate by what we refuse to tolerate.</div><div><br/></div><div>Change happens when we say no to the current system and work towards developing a better one.</div><div><br/></div><div>Aside from societal reform, saying “no” is invaluable to having authority over your time.</div><div><br/></div><div>Derek Sivers has a <a href="https://sivers.org/hellyeah">great framework</a> for choosing whether to accept or reject a choice:</div><div><br/></div><div>"If you’re not saying ‘Hell yeah!’ about something, say ’no’.”</div><div><br/></div><div>Life is too short to do things that don’t align with your values.</div><div><br/></div><div>Give yourself space to say yes to the things that really matter.</div><div><br/></div><div>Don’t be afraid to say no.</div>`

export default new Reflection({
  html,
  id: 'no',
  tags: null,
  title,
  words: 125,
  written: '6/23/20'
})
