import Reflection from '@/stores/models/reflection'

const title = 'Health Scares'
const html = `<div>I went to the ER twice last week…</div><div><br/></div><div>I'm pretty attuned to how I feel everyday, and something was off.</div><div><br/></div><div>Weird symptoms like numbness in my face, fatigue, and pressure in my head would suddenly arise.</div><div><br/></div><div>I worried that something was wrong with my brain or circulation, and fears turned into panic.</div><div><br/></div><div>Thankfully, all the tests were clear.</div><div><br/></div><div>The root issue is most likely mental.</div><div><br/></div><div>I still have a lot of unresolved trauma, and certain experiences can trigger acute anxiety.</div><div><br/></div><div>Although I knew the odds of something really being wrong were low, I felt like my life was possibly at risk.</div><div><br/></div><div>Life is fragile.</div><div><br/></div><div>I've been reflecting on what's important in life, and I'm going to prioritize relationships more.</div><div><br/></div><div>The bad times make you appreciate the good times so much more.</div>`

export default new Reflection({
  html,
  id: 'health-scares',
  tags: null,
  title,
  words: 131,
  written: '11/1/22'
})
