import Reflection from '@/stores/models/reflection'

const title = 'Time'
const html = `<div>There is no universal "now."</div><div><br/></div><div>A clock at sea level ticks faster than one on top of a mountain.</div><div><br/></div><div>Every point in the universe has its own frame of reference in time.</div><div><br/></div><div>Speed distorts it as well.</div><div><br/></div><div>From the Earth's perspective, a photon takes 8 minutes to travel from the Sun.</div><div><br/></div><div>From the photon's perspective, the journey is instantaneous.</div><div><br/></div><div>Time itself is just a concept.</div><div><br/></div><div>Things in the world are just collections of atoms in time.</div><div><br/></div><div>Everything moves toward disorder.</div><div><br/></div><div>Dust to dust.</div><div><br/></div><div>Although theoretical physicists have not yet reached consensus on what time really is, one thing is certain.</div><div><br/></div><div>It's the most valuable thing we have.</div>`

export default new Reflection({
  html,
  id: 'time',
  tags: null,
  title,
  words: 107,
  written: '4/20/21'
})
