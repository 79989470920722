import Reflection from '@/stores/models/reflection'

const title = 'Plane Crashes'
const html = `<div>I try to avoid the daily news, but I like to check Twitter to get a pulse on what's going on around the world.</div><div><br/></div><div>Yesterday a plane crashed in China, killing all 132 people on-board.</div><div><br/></div><div>Statistics strip away the humanity of what happens in our world.</div><div><br/></div><div>Lives full of possibility, over in an instant.</div><div><br/></div><div>Events like these make me realize how lucky we are to be alive.</div><div><br/></div><div>I appreciate simple things like a sunrise, a deep breath, and a moment of calm.</div><div><br/></div><div>Nothing is guaranteed in life.</div>`

export default new Reflection({
  html,
  id: 'plane-crashes',
  tags: null,
  title,
  words: 87,
  written: '3/22/22'
})
