import Reflection from '@/stores/models/reflection'

const title = 'Messi'
const html = `<div>On Sunday, December 18, 2022, the world witnessed greatness.</div><div><br/></div><div>Messi finally got his World Cup championship for Argentina.</div><div><br/></div><div>While Mbappé and the French put up a fight until the last minute of overtime, the Argentines put them away in the penalty shoot-out.</div><div><br/></div><div>It was an instant classic.</div><div><br/></div><div>Nobody deserves the trophy more than Lionel Messi, the greatest footballer of all time.</div>`

export default new Reflection({
  html,
  id: 'messi',
  tags: null,
  title,
  words: 61,
  written: '12/20/22'
})
