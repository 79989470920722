import Reflection from '@/stores/models/reflection'

const title = '"Ivy-covered Intellectual Rot"'
const html = `<div>Dr. Phil, in <a href="https://www.youtube.com/watch?v=L5qhGVSWFCM">this video</a>, perfectly describes how American universities responded to the Hamas attack on October 7.</div><div><br/></div><div>I don't get how any rational person can justify a group of self-proclaimed jihadists beheading, raping, and burning innocent civilians.</div><div><br/></div><div>I don't get how any rational person can morally equate Hamas intentionally killing civilians to Israel unintentionally killing people used by Hamas as human shields.</div><div><br/></div><div>I don't get how any rational person can believe there will be peace if Israel doesn't defend itself, especially after what happened on October 7.</div><div><br/></div><div>I actually do get how people can believe these things — or even worse, like antisemitism.</div><div><br/></div><div>People are not rational.</div>`

export default new Reflection({
  html,
  id: '"ivy-covered-intellectual-rot"',
  tags: null,
  title,
  words: 108,
  written: '10/24/23'
})
