import Reflection from '@/stores/models/reflection'

const title = 'Baseball'
const html = `<div>Major League Baseball was founded in 1869.</div><div><br/></div><div>There’s a reason the game of baseball is known as America’s national pastime.</div><div><br/></div><div>There are 3 things that make baseball unique:</div><div><br/></div><ol><li><div>It’s the only major team sport in America with no game clock.</div></li></ol><ul><ul><li><div>As many teams and fans know, even a lead with 2 outs in the bottom of the ninth inning is not a certain win.</div></li></ul></ul><ol start="2"><li><div>The MLB has the longest season with 162 games.</div></li></ol><ul><ul><li><div>The most wins in a season is 116, by the 1906 Chicago Cubs and the 2001 Seattle Mariners. Both teams didn't even win the World Series.</div></li></ul></ul><ol start="3"><li><div>Baseball is a game of failure.</div></li></ol><ul><ul><li><div>The average Hall of Fame batting average is around .300. The best players in the world still fail to get a hit 70% of the time.</div></li></ul></ul><div><br/></div><div>Since the Dodgers just won their 7th World Series title, their first in 32 years, I’ve been thinking about how baseball relates to life.</div><div><br/></div><div>Baseball is a game of individual responsibility.</div><div><br/></div><div>Don’t focus on the clock. Focus on your responsibilities in the here-and-now.</div><div><br/></div><div>Play the long-term game. Don’t burn out by overexerting yourself. Starting pitchers often rest for 5 days between games.</div><div><br/></div><div>Learn from failures.</div><div><br/></div><div>You’re only as good as your last at-bat.</div>`

export default new Reflection({
  html,
  id: 'baseball',
  tags: null,
  title,
  words: 208,
  written: '10/28/20'
})
