import Reflection from '@/stores/models/reflection'
import {ENERGY} from '@/tags'

const title = 'Power Outages'
const html = `<div>Last Friday, a storm hit Los Angeles during a heat wave.</div><div><br/></div><div>In a completely minor incident, I lost power for several hours.</div><div><br/></div><div>Even so, it reminded me of how dependent we are on electrical infrastructure.</div><div><br/></div><div>Without the power grid, society would collapse.</div>`

export default new Reflection({
  html,
  id: 'power-outages',
  tags: [ENERGY],
  title,
  words: 42,
  written: '9/14/22'
})
