import Reflection from '@/stores/models/reflection'

const title = 'Breakthroughs'
const html = `<div>Certain things may seem impossible today, but we only see from a present frame of reference.</div><div><br/></div><div>Step functions occur naturally in the universe.<br/></div><div><br/></div><div>Things change, spontaneously and drastically.</div><div><br/></div><div>The big bang was a step function.</div><div><br/></div><div>Consciousness is a step function.</div><div><br/></div><div>At what moment does a living thing transform from mere matter to a sentient being?</div><div><br/></div><div>Breakthroughs in science, personal development, and what society deems possible can all change in a single moment.</div><div><br/></div><div>What’s similar across all of these is an encounter with the unknown.</div><div><br/></div><div>Experimenting, taking risks, and stepping up to the plate are required for transformation.</div><div><br/></div><div>In order to make a change, you have to believe that change is possible.</div><div><br/></div><div>Never give up.</div>`

export default new Reflection({
  html,
  id: 'breakthroughs',
  tags: null,
  title,
  words: 114,
  written: '9/15/20'
})
