import Reflection from '@/stores/models/reflection'

const title = 'Impossibility'
const html = `<div>There are two types of accomplishments.</div><ol><li><div>Those that people have already figured out and achieved</div></li><li><div>Those in uncharted territory</div></li></ol><div>Regarding the first type, there is no doubt that they are in the realm of possibility.</div><div><br/></div><div>There is a defined path to follow.</div><div><br/></div><div>The second type, however, people often mislabel as impossible.</div><div><br/></div><div>Thinking something is impossible is a self-fulfilling prophecy.</div><div><br/></div><div>Something is only impossible for you if you say it is.</div><div><br/></div><div>In the past 100 years, there have been many achievements that seemed inconceivable.</div><div><br/></div><div>The Moon landing, the Human Genome Project, and the internet are but a few of the triumphs of mankind.</div><div><br/></div><div>To say something is impossible is to have the arrogance of completely understanding the universe.</div><div><br/></div><div>Written language has been around for less than 6000 years.</div><div><br/></div><div>Collective human knowledge, as wide-ranging as it is, barely scratches the surface of reality.</div><div><br/></div><div>Humankind is just getting started.</div><div><br/></div><div>As Steve Jobs said, "those who are crazy enough to think they can change the world usually do."</div><div><br/></div><div>He may have been domineering, but his belief in not only what is possible, but what is inevitable — his <span style="font-style: italic;">reality distortion field</span> — made a dent in the universe.</div><div><br/></div><div>We can know that things are possible, but not that they are impossible.</div><div><br/></div><div>Take solace in the fact that the universe is a mystery.</div><div><br/></div><div>The possibilities are endless.</div>`

export default new Reflection({
  html,
  id: 'impossibility',
  tags: null,
  title,
  words: 222,
  written: '1/8/20'
})
