import Reflection from '@/stores/models/reflection'

const title = 'Transitoriness'
const html = `<div>Everything that arises, passes.</div><div><br/></div><div>We often overstress recent events and overestimate their importance.</div><div><br/></div><div>Certain decisions have long-term consequences (relative to time scales that we perceive) but most things that happen every day fade into lost memories.</div><div><br/></div><div>I'll soon forget ever having written this sentence.</div><div><br/></div><div>You likely can't even remember the thing you worried about last week or last month.</div><div><br/></div><div>That awkward encounter or that mistake you made is long gone.</div><div><br/></div><div>How you're feeling now will pass too.</div><div><br/></div><div>In the grand scheme of things, the events on one planet orbiting one star in one galaxy in the entire universe are meaningless.</div><div><br/></div><div>That may sound dark, but transitoriness can empower us to create meaning and to release false meanings.</div>`

export default new Reflection({
  html,
  id: 'transitoriness',
  tags: null,
  title,
  words: 117,
  written: '7/20/21'
})
