import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Select } from '@/components'
import reflections from '@/reflections'
import { wordCountText, writtenDateToYear } from '@/utils/helpers'

import './ReflectionsPage.scss'

const ALL_YEARS = 'All'

export default class ReflectionsPage extends Component {
  componentDidMount () {
    // MAYBE - Move reflections into a DB and create API to fetch them
    window.scrollTo(0, 0)
  }
  onChangeYear = (year) => {
    const { history } = this.props
    if (year.value === ALL_YEARS) {
      history.replace(`/reflections`)
    } else {
      history.replace(`/reflections/${year.value}`)
    }
  }
  renderList () {
    const { match } = this.props
    const { params } = match
    const { year } = params
    const filteredReflectionKeys = year != null
      ? Object.keys(reflections).filter(reflectionKey => writtenDateToYear(reflections[reflectionKey].written) === year)
      : Object.keys(reflections)
    return (
      <div className='ReflectionsPage__list'>
        {filteredReflectionKeys.sort((a, b) => {
          // TODO - ugh this is inefficient. Move to DB?
          return new Date(reflections[b].written) - new Date(reflections[a].written)
        }).map(refId => {
          const reflection = reflections[refId]
          const {
            id,
            title,
            words,
            written
          } = reflection
          return (
            <div key={`ReflectionsPage-${id}`} className='ReflectionsPage__list__reflection'>
              <Link to={`/reflections/${writtenDateToYear(written)}/${id}`}>{title}</Link>
              <span className='ReflectionsPage__list__reflection__info'>
                {`(${wordCountText(words)})`}&nbsp;&nbsp;{written}
              </span>
            </div>
          )
        })}
      </div>
    )
  }
  renderYears () {
    const { match } = this.props
    const { params } = match
    const { year } = params
    const years = [{ value: ALL_YEARS, label: ALL_YEARS }]
    for (let yr = new Date().getFullYear(); yr >= 2019; yr--) {
      years.push({ value: yr, label: yr })
    }
    return (
      <Select
        label=''
        onChange={this.onChangeYear}
        options={years}
        name='years'
        placeholder='Filter Year'
        value={year == null
          ? { value: ALL_YEARS, label: ALL_YEARS }
          : { value: year, label: year }
        }
      />
    )
  }
  render () {
    return (
      <div className='ReflectionsPage'>
        <h1>Reflections</h1>
        <p>I started a weekly writing practice in 2019 to improve my thinking and to reflect on life.</p>
        <p>Here are some of my perspectives on the world.</p>
        {this.renderYears()}
        {this.renderList()}
      </div>
    )
  }
}
