import Reflection from '@/stores/models/reflection'

const title = 'Kona'
const html = `<div>I'm on the Big Island this week to attend the 2022 Ironman World Championship.</div><div><br/></div><div>Thousands of people from around the world bring life to the town of Kailua-Kona for the week.</div><div><br/></div><div>My mom is competing on Thursday instead of the usual Saturday, as this is the first year where Ironman is splitting the event into 2 separate days for men and women.</div><div><br/></div><div>The Ironman is a crazy race — a 2.4 mile swim, a 112 mile bike, and then a 26.2 mile run.</div><div><br/></div><div>Besides the energy of the event, the best part about Kona is drinking some of the best coffee on Earth, enjoying the beautiful beaches and clear water, and eating poke for days.</div><div><br/></div><div>Swimming with giant manta rays at night was also an unforgettable experience.</div>`

export default new Reflection({
  html,
  id: 'kona',
  tags: null,
  title,
  words: 127,
  written: '10/5/22'
})
