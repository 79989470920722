import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'On-sites'
const html = `<div>Last week I flew to Seattle for a team on-site.</div><div><br/></div><div>In 2 years of working for Meta, I hadn't met anyone on my engineering team in person.</div><div><br/></div><div>Building relationships with distributed coworkers can be difficult.</div><div><br/></div><div>In a post-COVID world, getting together every quarter or so is likely the best strategy for team cohesion.</div><div><br/></div><div>There's no alternative for in-person interaction.</div>`

export default new Reflection({
  html,
  id: 'on-sites',
  tags: [BUSINESS],
  title,
  words: 59,
  written: '7/26/22'
})
