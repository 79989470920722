import Reflection from '@/stores/models/reflection'
import {HISTORY} from '@/tags'

const title = '8-24'
const html = `<div><div><img src="https://www.nba.com/lakers/sites/lakers/files/171216-kb.jpg"></div><div><br/></div>Symbols give life meaning.</div><div><br/></div><div>We number our years, name our months and days, and maintain a steady cycle to provide structure to life.</div><div><br/></div><div>Today’s date has a significance to the city of Los Angeles.</div><div><br/></div><div>LA actually declared August 24th as Kobe Bryant Day in 2016 after Kobe retired from the NBA, and Orange County did the same earlier this month.</div><div><br/></div><div>The LA Times just announced today that the city will rename part of the street outside of Staples Center as Kobe Bryant Blvd.</div><div><br/></div><div>No amount of words that I write can convey the impact that Kobe had on LA and the world.</div><div><br/></div><div>Yesterday on what would have been Kobe’s 42nd birthday, the Dodgers shared this <a href="https://www.youtube.com/watch?v=eAmtatEMuQI">beautiful tribute</a> narrated by Vin Scully.</div><div><br/></div><div>Kobe’s legacy is bigger than basketball.</div><div><br/></div><div>He may be gone from the Earth, but Kobe's impact is just beginning.</div><div><br/></div><div>Mamba mentality.</div><div><br/></div><div>💜💛</div>`

export default new Reflection({
  html,
  id: '8-24',
  tags: [HISTORY],
  title,
  words: 142,
  written: '8/24/20'
})
