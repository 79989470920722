import Reflection from '@/stores/models/reflection'
import {SYSTEMS} from '@/tags'


const title = 'Systems'
const html = `<div>The book <i>Systemantics</i> by John Gall reads more like a comedy than a business book.</div><div><br/></div><div>"Large systems usually operate in failure mode."</div><div><br/></div><div>I found his definition of the "coefficient of fiction" pretty amusing:</div><div><br/></div><div>C<sub>F</sub> = R<sub>0</sub> / R<sub>s</sub></div><div><br/></div><div>R<sub>0</sub>: the amount of reality which fails to reach the system</div><div>R<sub>s</sub>: the amount of reality presented to the system</div><div><br/></div><div>When the value is at its theoretical minimum, 0, the system receives 100% of information, which of course is impossible.</div><div><br/></div><div>When the value is at its maximum, 1, it has no awareness of reality.</div><div><br/></div><div>It's safe to say that every system on Earth has a C<sub>F</sub> asymptotically close to 1.</div><div><br/></div><div>Most of reality fails to get through to us.</div><div><br/></div><div>Gall is most famous for his eponymous law:  "A complex system designed from scratch never works and cannot be made to work. You have to start over, beginning with a working simple system."</div><div><br/></div><div>Humans tend to complicate things.</div><div><br/></div><div>It's better to start over than to keep adding onto something broken.</div><div><br/></div><div>Keep it simple.</div>`

export default new Reflection({
  html,
  id: 'systems',
  tags: [SYSTEMS],
  title,
  words: 170,
  written: '4/27/21'
})
