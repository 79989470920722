import { combineReducers } from 'redux'
import contentStore from './contentStore'
import uiStore from './uiStore'

const rootReducer = combineReducers({
  contentStore,
  uiStore
})

export default rootReducer
