import Reflection from '@/stores/models/reflection'

const title = 'The Storyteller'
const html = `<div>There's an awesome, but perhaps apocryphal, <a href="https://www.quora.com/What-are-the-best-stories-about-people-randomly-or-non-randomly-meeting-Steve-Jobs/answer/Tomas-Higbey">story about Steve Jobs</a> from his time at NeXT in 1994.</div><div><br/></div><div>He asked a few of his employees, "Who is the most powerful person in the world?"</div><div><br/></div><div>His answer surprised them.</div><div><br/></div><div>"The storyteller sets the vision, values, and agenda of an entire generation that is to come."</div><div><br/></div><div>The storyteller is the most powerful person in the world.</div><div><br/></div><div>This past weekend I celebrated Passover with my family, and I realized the significance of passing along the story every year.</div><div><br/></div><div>Natural selection / genetics have determined physical survival throughout history, and stories / memetics have determined cultural survival.</div><div><br/></div><div>Cultures live or die by traditions.</div>`

export default new Reflection({
  html,
  id: 'the-storyteller',
  tags: null,
  title,
  words: 108,
  written: '3/30/21'
})
