import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Fitness Classes'
const html = `<div>I've been using ClassPass for the last several months to mix up my yoga practice.</div><div><br/></div><div>These past couple weeks I switched it up by going to a fitness studio instead.</div><div><br/></div><div>I love high intensity interval training (HIIT), but some instructors just don't know what they're doing.</div><div><br/></div><div>When they tell you to get as many reps done in an arbitrary timeframe, you can overwork the small stabilizer muscles instead of the intended muscle group.</div><div><br/></div><div>Using the same weight for a dozen movements is not ideal.</div><div><br/></div><div>If you're not familiar with the movements, you can end up doing more harm than good.</div><div><br/></div><div>Unless the instructor designed the class well by including bodyweight exercises, you'll likely create additional stress and tightness.</div><div><br/></div><div>I get that people enjoy being in an energetic environment to workout, but I'd prefer to just go to a gym and create my own routine.</div><div><br/></div><div>Having had a long-term back injury, I'm more aware of how habits and patterns can create dysfunction and pain.</div><div><br/></div><div>You only get one body, and how you move it affects all aspects of your life.</div>`

export default new Reflection({
  html,
  id: 'fitness-classes',
  tags: [HEALTH],
  title,
  words: 179,
  written: '10/19/21'
})
