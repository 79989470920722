import Reflection from '@/stores/models/reflection'

const title = 'Ad Hominem'
const html = `<div>An idea should stand on its own, separate from its source.</div><div><br/></div><div>Just because someone doesn’t agree with you on a certain topic doesn’t mean that all of their beliefs are invalid.</div><div><br/></div><div>Name-calling and insults add no strength to an argument.</div><div><br/></div><div>Instead of dismissing everything that someone you hold in low regard says, consider what he or she is asserting.</div><div><br/></div><div>People with a questionable past can still make valid arguments.</div><div><br/></div><div>Judge ideas, not people.</div>`

export default new Reflection({
  html,
  id: 'ad-hominem',
  tags: null,
  title,
  words: 74,
  written: '6/30/20'
})
