import Reflection from '@/stores/models/reflection'

const title = '"Playing Possum"'
const html = `<div>I watched Israel Adesanya knock out Alex Pereira in UFC 287 on Saturday night.</div><div><br/></div><div>The end of the fight was an interesting lesson in game theory.</div><div><br/></div><div>Adesanya retreated back into the fence, covering his face with both gloves after Pereira connected on a couple leg kicks.</div><div><br/></div><div>He seemed like he was losing composure, but he took advantage and executed a perfect counter with a right hook, and then knocked out Pereira with a second right hook.</div><div><br/></div><div>After the fight, he said he was "playing possum."</div><div><br/></div><div>Mixed martial arts is a brutal sport, but its lessons apply to other areas of life.</div><div><br/></div><div>Acting weak can make people underestimate you.</div>`

export default new Reflection({
  html,
  id: 'playing-possum',
  tags: null,
  title,
  words: 108,
  written: '4/11/23'
})
