import Reflection from '@/stores/models/reflection'

const title = 'Timing'
const html = `<div>I started writing this reflection around the usual time on a Monday night around 7.</div><div><br/></div><div>I realized that the timing of my writing affects how I think and process information.</div><div><br/></div><div>Your analytical and creative abilities fluctuate throughout the day.</div><div><br/></div><div>In his book <i>When</i>, Daniel Pink wrote about how timing affects our lives.</div><div><br/></div><div>Everyone has a different chronotype based on their natural circadian rhythm, which changes as we age.</div><div><br/></div><div>He named the three following chronotypes:</div><ul><li><div>larks (morning people)</div></li><li><div>owls (night people)</div></li><li><div>third birds (somewhere in between)</div></li></ul><div>I'm definitely a lark.</div><div><br/></div><div>I wake up around 5:30 every morning with no alarm.</div><div><br/></div><div>I'd love to be able to go out at night and sleep in, but my mind wakes me up at the same time everyday whether I go to bed early or after midnight.</div><div><br/></div><div>If I get 7-8 hours of sleep, I'm full of energy the moment I wake up, but I feel awful if I get 5 or less hours.</div><div><br/></div><div>As someone who has suffered from severe anxiety, I'm more sensitive to sleep deprivation.</div><div><br/></div><div>I'd rather feel great waking up early than force myself to stay out late, usually with a foggy mind because I've been awake for 16+ hours.</div><div><br/></div><div>I listen to my body and mind, and try to do what's best so I can give more of myself during the day.</div><div><br/></div><div>Your chronotype determines when you're most effective.</div><div><br/></div><div>Timing is everything.</div>`

export default new Reflection({
  html,
  id: 'timing',
  tags: null,
  title,
  words: 235,
  written: '2/22/22'
})
