import React, { Component } from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

import { AppLayout } from './layouts'

import BlogPage from './pages/BlogPage/BlogPage'
import BlogPostPage from './pages/BlogPostPage/BlogPostPage'
import ContactPage from './pages/ContactPage/ContactPage'
import HomePage from './pages/HomePage/HomePage'
import KeelingCurvePage from './pages/KeelingCurvePage/KeelingCurvePage'
import NotesPage from './pages/NotesPage/NotesPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import NowPage from './pages/NowPage/NowPage'
import ReflectionPage from './pages/ReflectionPage/ReflectionPage'
import ReflectionsPage from './pages/ReflectionsPage/ReflectionsPage'
import TagsPage from './pages/TagsPage/TagsPage'

export default class Router extends Component {
  render () {
    return (
      <BrowserRouter>
        <AppLayout>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/blog' component={BlogPage} />
            <Route exact path='/blog/:slug' component={BlogPostPage} />
            <Route exact path='/contact' component={ContactPage} />
            <Route exact path='/keeling-curve' component={KeelingCurvePage} />
            <Route exact path='/notes' component={NotesPage} />
            <Route exact path='/now' component={NowPage} />
            <Route exact path='/reflections' component={ReflectionsPage} />
            <Route exact path='/reflections/:year' component={ReflectionsPage} />
            <Route exact path='/reflections/:year/:refId' component={ReflectionPage} />
            <Route exact path='/tags/:tag' component={TagsPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </AppLayout>
      </BrowserRouter>
    )
  }
}
