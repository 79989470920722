import Reflection from '@/stores/models/reflection'

const title = 'Doomscrolling'
const html = `<div>There has been a lot of negative news between 2016 and 2021.</div><div><br/></div><div>It started with "what did the president do today?"</div><div><br/></div><div>Then hurricanes and wildfires devastated cities across the globe.</div><div><br/></div><div>And of course, 2020 needs no explanation.</div><div><br/></div><div>One thing is certain when you seek bad news: you feel worse after doing it.</div><div><br/></div><div>So my advice is to just not look for it.</div><div><br/></div><div>Focus on things you can control.</div><div><br/></div><div>As I'm writing this on January 19, 2021, the night before Biden's inauguration, I'm optimistic that the world will get better.</div><div><br/></div><div>It will take time.</div><div><br/></div><div>Industry and transportation will shift from fossil fuels to clean energy.</div><div><br/></div><div>Healthcare and education will improve.</div><div><br/></div><div>The world will become more connected as developing countries gain access to high-speed internet.</div><div><br/></div><div>Bad things will still happen, but the net change will be positive.</div><div><br/></div><div>Instead of paying attention to negative news, go create good news.</div>`

export default new Reflection({
  html,
  id: 'doomscrolling',
  tags: null,
  title,
  words: 146,
  written: '1/19/21'
})
