import Reflection from '@/stores/models/reflection'

const title = 'Patience'
const html = `<div>Success doesn't happen overnight.</div><div><br/></div><div>Some things can take months, years, even decades to realize.</div><div><br/></div><div>If you're moving in your intended direction, stay the course.</div>`

export default new Reflection({
  html,
  id: 'patience',
  tags: null,
  title,
  words: 24,
  written: '4/4/23'
})
