import Reflection from '@/stores/models/reflection'
import {COMMUNITY} from '@/tags'

const title = 'Once in a Lifetime'
const html = `<div>Yesterday my cousin Amanda married her love Dani in a beautiful wedding ceremony overlooking the ocean.</div><div><br/></div><div>Most of my cousins flew to LA from Philadelphia to celebrate.</div><div><br/></div><div>Big life events like weddings and bar or bat mitzvahs are the only occasions when the entire family gets together.</div><div><br/></div><div>The years fly by, and these events are few and far between.</div><div><br/></div><div>If you get the chance to attend a once-in-a-lifetime event, don't miss the opportunity.</div>`

export default new Reflection({
  html,
  id: 'once-in-a-lifetime',
  tags: [COMMUNITY],
  title,
  words: 73,
  written: '8/15/22'
})
