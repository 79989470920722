import Reflection from '@/stores/models/reflection'
import { HEALTH, SYSTEMS } from '@/tags'

const title = 'Habits'
const html = `<div>There’s no such thing as an overnight success.</div><div><br/></div><div>People only see the results, not the work.</div><div><br/></div><div>The 5am mornings.</div><div><br/></div><div>The sacrifices.</div><div><br/></div><div>The uninterrupted focus.</div><div><br/></div><div>Long-term results are lagging indicators of daily habits.</div><div><br/></div><div>The power of habits can work for or against you.</div><div><br/></div><div>The writer Samuel Johnson once said, "The chains of habit are too weak to be felt until they are too strong to be broken."</div><div><br/></div><div>If you aren’t careful, bad habits can become addictions that are difficult to release.</div><div><br/></div><div>Positive habits, on the other hand, can seem overwhelming before you start, but the effort to maintain them fades into automatic behavior over time.</div><div><br/></div><div>One decision, repeated every day, can change your life.</div><div><br/></div><div>Focus on the process, not the results.</div><div><br/></div><div>What do you do when no one’s watching?</div><div><br/></div><div>Show up each day, for yourself and for the world.</div><div><br/></div><div>Like Seth Godin would say, culture transforms <a href="https://seths.blog/2017/03/drip-by-drip-and-the-thunderclap/">drip by drip</a>.</div><div><br/></div><div>His daily blog, where he hasn’t missed a single day since 2008, is now an ocean of insight.</div><div><br/></div><div>The best book to learn the tools and strategies for developing habits is <span style="font-style: italic;">Atomic Habits</span> by James Clear.</div><div><br/></div><div>As James said, "You do not rise to the level of your goals. You fall to the level of your systems."</div><div><br/></div><div>Find what works for you.</div><div><br/></div><div>Build the future.</div><div><br/></div><div>Brick by brick.</div>`

export default new Reflection({
  html,
  id: 'habits',
  tags: [HEALTH, SYSTEMS],
  title,
  words: 217,
  written: '5/19/20'
})
