import Reflection from '@/stores/models/reflection'

const title = 'Aspen'
const html = `<div>I'm taking my first week off from work in years.</div><div><br/></div><div>Yesterday I went to Maroon Bells in Aspen, and it's one of the most beautiful places I've ever seen.</div><div><br/></div><div><img src="https://i.imgur.com/BzrsYnb.jpeg"></div><div><br/></div><div>Having spent the last year and a half working from home and staring at a screen all day, I missed being out in nature.</div><div><br/></div><div>Work-life balance is so important.</div><div><br/></div><div>I'm not going to let too much time pass before the next trip.</div>`

export default new Reflection({
  html,
  id: 'aspen',
  tags: null,
  title,
  words: 71,
  written: '8/10/21'
})
