import Reflection from '@/stores/models/reflection'

const title = 'Sunrise'
const html = `<div>i awaken peacefully</div><div><br/></div><div>the darkness before dawn</div><div><br/></div><div>no sounds of cars in the distance</div><div><br/></div><div>i begin to meditate</div><div><br/></div><div>sitting down cross-legged</div><div><br/></div><div>my eyes close</div><div><br/></div><div>inhale, exhale</div><div><br/></div><div>thoughts come and go</div><div><br/></div><div>effortless awareness</div><div><br/></div><div>grateful for these moments</div><div><br/></div><div>this is enough</div><div><br/></div><div>ten minutes fly by</div><div><br/></div><div>the world keeps spinning</div><div><br/></div><div>i open my eyes</div><div><br/></div><div>a glimmer of light shines through the curtain</div>`

export default new Reflection({
  html,
  id: 'sunrise',
  tags: null,
  title,
  words: 60,
  written: '4/7/20'
})
