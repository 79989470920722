import Reflection from '@/stores/models/reflection'

const title = 'Public Reviews'
const html = `<div>Are public reviews reliable?</div><div><br/></div><div>Every restaurant, product, or book is not meant for everyone.</div><div><br/></div><div>Yelp, Amazon, Goodreads — these are just a few of the countless websites that display customer reviews.</div><div><br/></div><div>If something is popular, that doesn't always mean it's good.</div><div><br/></div><div>People can buy reviews, likes, and followers.</div><div><br/></div><div>You may lower your risk buying something that has thousands of positive ratings, but your unique taste doesn't always align with the general public.</div><div><br/></div><div>I'm talking about strictly anonymous reviews.</div><div><br/></div><div>Of course a recommendation from a trusted source is more legitimate, but even then you have to consider the underlying incentives.</div><div><br/></div><div>As people gain more experience in a domain, they learn more distinctions that separate what they deem good and bad.</div><div><br/></div><div>A number or rating has whatever meaning you give it.</div>`

export default new Reflection({
  html,
  id: 'public-reviews',
  tags: null,
  title,
  words: 129,
  written: '4/6/21'
})
