import Reflection from '@/stores/models/reflection'

const title = 'Pro-choice'
const html = `<div>Last week, the US Supreme court reversed the Roe v. Wade decision.</div><div><br/></div><div>A nation that stands for freedom took bodily rights away from women.</div><div><br/></div><div>While I'm 100% on the pro-choice side, I understand that abortion is a complicated subject.</div><div><br/></div><div>Most pro-lifers will never change their mind about it.</div><div><br/></div><div>Does life begin at conception?</div><div><br/></div><div>If a woman can't support a child, will the birth cause more total suffering than an abortion?</div><div><br/></div><div>Nobody thinks it's okay to terminate a healthy pregnancy late in the cycle.</div><div><br/></div><div>If a mother's life is at risk, she should absolutely have the right to terminate the pregnancy.</div><div><br/></div><div>Governments should stay out of personal affairs.</div><div><br/></div><div>In California, women's rights are still protected.</div><div><br/></div><div>The right to choose will win.</div>`

export default new Reflection({
  html,
  id: 'pro-choice',
  tags: null,
  title,
  words: 120,
  written: '6/28/22'
})
