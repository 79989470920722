import request from '@/utils/request'
import BlogPost from '../models/blogPost'
import contentStoreActions from './actions'
import humps from 'humps'

const { cacheBlogPost, cacheBlogPosts } = contentStoreActions

const getBlogPost = async (slug, dispatch) => {
  try {
    const result = await request.get(`/posts/${slug}`)
    const resultData = humps.camelizeKeys(result.data)
    const { data } = resultData
    dispatch(cacheBlogPost(new BlogPost(data)))
  } catch (err) {
    console.log('getBlogPost error', err)
  }
}

const getBlogPosts = async (dispatch) => {
  try {
    const result = await request.get('/posts?page=1&pageSize=10')
    const resultData = humps.camelizeKeys(result.data)
    const { data } = resultData
    const blogPosts = []
    for (let i = 0; i < data.length; i++) {
      blogPosts.push(new BlogPost(data[i]))
    }
    dispatch(cacheBlogPosts(blogPosts))
  } catch (err) {
    console.log('getBlogPosts error', err)
  }
}

const subscribe = async (email) => {
  try {
    await request.post('/posts/subscribe', { email })
    return true
  } catch (err) {
    console.log('subscribe error', err)
  }
}

export default {
  getBlogPost,
  getBlogPosts,
  subscribe
}
