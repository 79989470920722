import Reflection from '@/stores/models/reflection'

const title = 'Consistency'
const html = `<div>Woody Allen once said that 80% percent of success is showing up.</div><div><br/></div><div>“Showing up” is not just physical presence.</div><div><br/></div><div>More importantly it's being mentally present and engaged, day after day.</div><div><br/></div><div>All of us have started something new before – whether a project, a book, a workout plan, a course, an idea for a company, etc.</div><div><br/></div><div>It’s easy to <i>decide</i> to begin, but when things become difficult your true character emerges.</div><div><br/></div><div>It takes commitment and perseverance to keep moving forward.</div><div><br/></div><div>With today’s unlimited distractions, the external world will try to pull you away from your goals.</div><div><br/></div><div>Inertia by itself will not keep you on track.</div><div><br/></div><div>You have to resist outside forces.</div><div><br/></div><div>We all have the same 24 hours each day, and we choose what we do with them.</div><div><br/></div><div>Knowing where you want to go or what you want to achieve can be the hardest part.</div><div><br/></div><div>If you do have a north star, a definite mission, resolve to stay the course.</div>`

export default new Reflection({
  html,
  id: 'consistency',
  tags: null,
  title,
  words: 158,
  written: '10/20/20'
})
