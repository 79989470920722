import Reflection from '@/stores/models/reflection'
import {WRITING} from '@/tags'

const title = `Writer's Block`
const html = `<div>When I was in high school, I'd stare at a blank page for hours unable to start an essay for English class.</div><div><br/></div><div>I've always struggled with perfectionism, which is ridiculous because nothing is perfect.</div><div><br/></div><div>I'm still an amateur writer, but at least I can put my thoughts on the page.</div><div><br/></div><div>I've made and will continue to make plenty of mistakes.</div><div><br/></div><div>Every mistake can teach you.</div><div><br/></div><div>Just keep writing.</div>`

export default new Reflection({
  html,
  id: 'writers-block',
  tags: [WRITING],
  title,
  words: 68,
  written: '1/31/23'
})
