import Reflection from '@/stores/models/reflection'

const title = 'Main'
const html = `<div>Last week I renamed the GitHub branch for this website from its default, master, to main. <sup>[1]</sup></div><div><br/></div><div>The change may seem trivial, but language is important and every detail matters.</div><div><br/></div><div>Other terms in the English language have more obvious negative connotations.</div><div><br/></div><div>Some companies still use the terms blacklist/whitelist instead of denylist/allowlist.</div><div><br/></div><div>Established terminology can have undertones that perpetuate racist or elitist ideologies.</div><div><br/></div><div>If you notice your team, organization, friends, or family using a term that you would prefer to replace, speak up.</div><div><br/></div><div>Changing language changes the future.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> Thankfully, GitHub makes it easy to <a href="https://github.com/github/renaming">rename the default branch</a> for repositories.</div>`

export default new Reflection({
  html,
  id: 'main',
  tags: null,
  title,
  words: 101,
  written: '3/9/21'
})
