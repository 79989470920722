import Reflection from '@/stores/models/reflection'

const title = 'Phobias'
const html = `<div>Some fears can get out of control.</div><div><br/></div><div>There’s a big difference between merely disliking something and having a visceral reaction that overwhelms your nervous system.</div><div><br/></div><div>For me, that thing was public speaking.</div><div><br/></div><div>It started with nervous trembling when I was in grade school and progressed into panic attacks in college.</div><div><br/></div><div>As I avoided experiences that would trigger my symptoms, more triggers developed.</div><div><br/></div><div>Interviews and phone calls became more difficult.</div><div><br/></div><div>The longer you wait to confront your fears, the worse the experience will be.</div><div><br/></div><div>Don’t downplay something that severely affects you.</div><div><br/></div><div>You just need to make a <a href="https://zachgallagher.com/reflections/2019/turning-points">single decision</a> to take action.</div><div><br/></div><div>I am by no means 100% past my fear of public speaking, but I am more willing to put myself out there when I see an opportunity.</div><div><br/></div><div>You’ll be surprised at how far you can go if you just keep moving forward.</div>`

export default new Reflection({
  html,
  id: 'phobias',
  tags: null,
  title,
  words: 143,
  written: '9/9/20'
})
