import Reflection from '@/stores/models/reflection'

const title = 'Imperfect Environments'
const html = `<div>You can’t prepare for every situation.</div><div><br/></div><div>In an ideal world, practice would exactly mimic performance.</div><div><br/></div><div>But that’s not the way the world works.</div><div><br/></div><div>Broadway performers don’t rehearse in front of a thousand people.</div><div><br/></div><div>An NBA player's jump shot over defenders is different every time.</div><div><br/></div><div>Each set of circumstances adds a slight variation to the moment.</div><div><br/></div><div>The environment is out of our control.</div><div><br/></div><div>You may not be able to physically prepare for every event, but you can mentally prepare.</div><div><br/></div><div>Expect and accept the unexpected.</div>`

export default new Reflection({
  html,
  id: 'imperfect-environments',
  tags: null,
  title,
  words: 83,
  written: '9/2/20'
})
