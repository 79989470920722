import Reflection from '@/stores/models/reflection'

const title = 'Stepping into the Unknown'
const html = `<div>Tomorrow I'm teaching my first yoga class to coworkers at Meta.</div><div><br/></div><div>On the one hand, it's just a yoga class with a small group of people.</div><div><br/></div><div>On the other hand, I used to struggle with crippling panic attacks when I've had to speak in public, so it means more to me than what it is.</div><div><br/></div><div>In addition to the mental health challenges, my back injury during my 20s prevented me from feeling true joy.</div><div><br/></div><div>Yoga has given me so much, I feel drawn to share.</div><div><br/></div><div>I'm so grateful to be where I am today.</div><div><br/></div><div>I want to help others along their own physical, mental, and spiritual journeys.</div>`

export default new Reflection({
  html,
  id: 'stepping-into-the-unknown',
  tags: null,
  title,
  words: 107,
  written: '7/17/23'
})
