import Reflection from '@/stores/models/reflection'

const title = 'Make It Bloom'
const html = `<div>"If you’re competent, they can throw you into the desert and you’ll make it bloom."</div><div><br/></div><div>I recently heard that quote on a podcast, and it inspired me.</div><div><br/></div><div>Your environment doesn't completely dictate your outcome. </div><div><br/></div><div>You can transform your surroundings.</div>`

export default new Reflection({
  html,
  id: 'make-it-bloom',
  tags: null,
  title,
  words: 39,
  written: '12/5/23'
})
