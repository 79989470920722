import Reflection from '@/stores/models/reflection'

const title = 'Succession'
const html = `<div>I don't watch a lot of TV, but I've seen every episode of Succession.</div><div><br/></div><div>The writing, acting, and humor were all great throughout the series.</div><div><br/></div><div>The finale aired on Sunday and it was an incredible ending to the show after only 4 seasons.</div><div><br/></div><div>Hollywood producers usually try to milk as much money as possible before ending a show, but the creators of Succession made the perfect ending and went out on top, leaving money on the table.</div><div><br/></div><div>It's better to finish strongly than to fade out on a decline.</div>`

export default new Reflection({
  html,
  id: 'succession',
  tags: null,
  title,
  words: 89,
  written: '5/30/23'
})
