import Reflection from '@/stores/models/reflection'

const title = 'Weekends'
const html = `<div>I just learned that Henry Ford played a significant role in creating the two-day weekend.</div><div><br/></div><div>Should we thank him or blame him?</div><div><br/></div><div>Dividing the already peculiar seven-day week into 5 and 2 seems arbitrary, yet the modern global economy still follows the convention.</div><div><br/></div><div>Apparently the Babylonians structured the seven-day week based on the visible celestial bodies in the night sky — the Sun, the Moon, Mars, Mercury, Jupiter, Venus, and Saturn.</div><div><br/></div><div>Perhaps digital technology will eventually change society's concept of the workweek and the weekend.</div><div><br/></div><div>Most people live for the weekend.</div><div><br/></div><div>If you dread Mondays, you're not living a life you love.</div>`

export default new Reflection({
  html,
  id: 'weekends',
  tags: null,
  title,
  words: 102,
  written: '5/25/21'
})
