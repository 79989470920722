import Reflection from '@/stores/models/reflection'

const title = 'Serendipity'
const html = `<div>Amazing things can happen at any moment.</div><div><br/></div><div>You have to show up and be present to seize the opportunity.</div><div><br/></div><div>A few days ago I went to an event for LA Tech Week.</div><div><br/></div><div>I tend to be introverted and can find it difficult to navigate big social events, but most people at events are open and welcoming.</div><div><br/></div><div>I ended up meeting a bunch of interesting and inspiring people.</div><div><br/></div><div>Some approached me, and others I approached.</div><div><br/></div><div>One principle I want to live by is to always initiate.</div><div><br/></div><div><a href="https://fs.blog/great-talks/multidisciplinary-approach-thinking-peter-kaufman/">Go positive and go first</a>.</div>`

export default new Reflection({
  html,
  id: 'serendipity',
  tags: null,
  title,
  words: 90,
  written: '8/22/22'
})
