import Reflection from '@/stores/models/reflection'

const title = 'Airports'
const html = `<div>The Wright brothers completed the first successful flight in 1903.</div><div><br/></div><div>Now, a global network of airports connects all major cities.</div><div><br/></div><div>In one century humanity went from dreaming about flying machines to orchestrating millions of flights per year.</div><div><br/></div><div>Planes are even safer than cars.</div><div><br/></div><div>It's easy to take the ability to fly anywhere for granted.</div><div><br/></div><div>Although the carbon emissions are a problem we still need to solve, the airline industry is simply incredible.</div>`

export default new Reflection({
  html,
  id: 'airports',
  tags: null,
  title,
  words: 72,
  written: '7/19/22'
})
