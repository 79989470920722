import Reflection from '@/stores/models/reflection'

const title = 'Chapters'
const html = `<div>Everything comes to an end.</div><div><br/></div><div>This weekend I'm moving out of the Culver City house I've lived in for almost 3 years with my friends Yoni, Jonas, and Aaron.</div><div><br/></div><div>The house has been home to our other friends, Justin and Joey, before I moved in as well.</div><div><br/></div><div>It was our group's hub for most of our 20s, hosting New Years parties, Super Bowls, BBQs, etc.</div><div><br/></div><div>We made it through 2020 in one piece, and the time has come for a new chapter.</div><div><br/></div><div>I'm moving into my own place, and I couldn't be more excited.</div><div><br/></div><div>One chapter closes, another begins.</div>`

export default new Reflection({
  html,
  id: 'chapters',
  tags: null,
  title,
  words: 99,
  written: '8/24/21'
})
