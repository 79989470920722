import Reflection from '@/stores/models/reflection'
import {BLOCKCHAIN} from '@/tags'

const title = 'Moonbirds'
const html = `<div>The <a href="https://moonbirds.xyz/" rev="en_rl_none">Moonbirds</a> NFT project dropped this past Saturday.</div><div><br/></div><div>For historical context, the current price of ETH is $3000.</div><div><br/></div><div>I didn't win the allowlist lottery to mint a Moonbird for 2.5Ξ, so I didn't end up getting one.</div><div><br/></div><div>One bold friend of mine bought one on the secondary market for 12Ξ right after the seller minted it.</div><div><br/></div><div>He flipped it for 40Ξ an hour later, which is just insane.</div><div><br/></div><div>The floor price after mint was around 6Ξ.</div><div><br/></div><div>Public markets can be crazy, and the creators aren't in control when people start bidding wars to own rare ones as a status signal.</div><div><br/></div><div>As I'm publishing this reflection, the <a href="https://opensea.io/collection/proof-moonbirds" rev="en_rl_none">floor price</a> is 20Ξ.</div><div><br/></div><div>Most people don't understand <a href="https://zachgallagher.com/reflections/2021/nfts">NFTs</a>.</div><div><br/></div><div>Although there may be 99 scams or rug pulls for every legitimate project, Moonbirds is one of the legit ones.</div><div><br/></div><div>People who've followed the space know that it's difficult to convince people of an NFT's value.</div><div><br/></div><div>Of course valuations can be in a bubble, but the technology will change the web economy.</div><div><br/></div><div>NFTs are not <i>just</i> pixel art.</div><div><br/></div><div>Utility NFTs are the future.</div><div><br/></div><div>IYKYK.</div>`

export default new Reflection({
  html,
  id: 'moonbirds',
  tags: [BLOCKCHAIN],
  title,
  words: 179,
  written: '4/19/22'
})
