import types from './types'

const uiReducer = (state = {}, action) => {
  switch (action.type) {
    case types.IS_DONE_TYPING_ANIMATION:
      return { ...state, isDoneTypingAnimation: true }
  }
  return state
}

export default uiReducer
