import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  BlogCard,
  Button,
  Input,
  Loader
} from '@/components'
import { contentStoreOperations } from '@/stores/contentStore'

import './BlogPage.scss'

const { getBlogPosts, subscribe } = contentStoreOperations

class BlogPage extends Component {
  state = {
    email: '',
    isLoadingBlogPosts: false,
    isSubmittingEmail: false
  }
  componentDidMount () {
    const { blogPosts, dispatch } = this.props
    window.scrollTo(0, 0)
    if (!blogPosts) {
      this.setState({ isLoadingBlogPosts: true }, () => {
        getBlogPosts(dispatch).then(() => {
          this.setState({ isLoadingBlogPosts: false })
        }).catch(err => {
          console.log('Error fetching blog posts', err)
          this.setState({ isLoadingBlogPosts: false })
        })
      })
    }
  }
  onChangeEmail = (e) => {
    const { target } = e
    const { value } = target
    this.setState({ email: value })
  }
  onSubmitEmail = (e) => {
    const { email } = this.state
    e.preventDefault()
    if (!email) return
    this.setState({ isSubmittingEmail: true }, () => {
      subscribe(email)
        .then(isSubscribed => {
          this.setState({ isSubmittingEmail: false, isThankful: isSubscribed })
        })
        .catch((err) => {
          console.log('Error submitting email', err)
          this.setState({ isSubmittingEmail: false })
        })
    })
  }
  renderCards () {
    const { blogPosts } = this.props
    const sortedBlogPosts = Object.keys(blogPosts).sort((a, b) => {
      return new Date(b.published) - new Date(a.published)
    }).map(slug => blogPosts[slug])
    return (
      <div className='BlogPage__cards'>
        {sortedBlogPosts.map(blogPost => <BlogCard key={`BlogPage-card-${blogPost.slug}`} blogPost={blogPost} />)}
      </div>
    )
  }
  renderGetNotified () {
    const {
      email,
      isSubmittingEmail,
      isThankful
    } = this.state
    return (
      <div className='BlogPage__getNotified'>
        <p>📩 Get notified of new posts.</p>
        <p>🚯 No spam ever.</p>
        <div className='BlogPage__getNotified__form'>
          <form onSubmit={this.onSubmitEmail}>
            <Input
              onChange={this.onChangeEmail}
              placeholder='email address'
              type='email'
              value={email}
            />
            <Button
              disabled={isSubmittingEmail}
              isLoading={isSubmittingEmail}
              text='Submit'
              type='submit'
            />
            {isThankful && <div className='BlogPage__thankful'>Thank you 🙏</div>}
          </form>
        </div>
        <br />
        <hr />
      </div>
    )
  }
  renderIntro () {
    return (
      <div className='BlogPage__intro'>
        <p>🌎 I write about topics related to climate change.</p>
        <p>🤔 My aim is to synthesize complex issues into concise, understandable posts.</p>
        <p>🕵 I try to be as objective as possible when researching and consolidating my thoughts.</p>
        <p>📝 Feel free to send me feedback — it is greatly appreciated!</p>
        <hr />
      </div>
    )
  }
  renderNotFound () {
    return (
      <div className='BlogPage__empty'>
        There was an error fetching the blog posts.
      </div>
    )
  }
  render () {
    const { blogPosts } = this.props
    const { isLoadingBlogPosts } = this.state
    return (
      <div className='BlogPage'>
        <div className='BlogPage__head'>
          <h1>Blog</h1>
          {this.renderIntro()}
          {this.renderGetNotified()}
        </div>
        <br />
        {isLoadingBlogPosts ? (
          <Loader />
        ) : (
          blogPosts ? this.renderCards() : this.renderNotFound()
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ contentStore }) => {
  const { blogPosts } = contentStore
  return { blogPosts }
}

export default connect(mapStateToProps)(BlogPage)
