import Reflection from '@/stores/models/reflection'

const title = 'Technical Explanations'
const html = `<div>My team at work recently expressed the need for great technical writers.</div><div><br/></div><div>If engineers can't explain their work, they create intellectual debt.</div><div><br/></div><div>What seems obvious to someone who's spent weeks or months on a project can be difficult for someone new.</div><div><br/></div><div>Simplifying complex ideas is not easy.</div><div><br/></div><div>The <a href="https://fs.blog/2021/02/feynman-learning-technique/" rev="en_rl_none">Feynman Learning Technique</a> centers around simplifying explanations so anyone can understand them.</div><div><br/></div><div>You truly learn by refining ideas into straightforward, logical reasoning.</div><div><br/></div><div>The best way to learn is to teach.</div><div><br/></div><div>If you can't teach others what you know, you don't fully understand the subject.</div>`

export default new Reflection({
  html,
  id: 'technical-explanations',
  tags: null,
  title,
  words: 92,
  written: '3/16/21'
})
