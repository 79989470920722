import Reflection from '@/stores/models/reflection'
import {BLOCKCHAIN} from '@/tags'

const title = 'NFTs'
const html = `<div>Like most people who heard about CryptoPunks and CryptoKitties in 2017, I thought they weren't worth the time.</div><div><br/></div><div>Right now, Punks <a href="https://www.larvalabs.com/cryptopunks/forsale" rev="en_rl_none">are selling</a> between $70,000 for the cheapest and millions for rare ones.</div><div><br/></div><div>Yesterday the CryptoPunks creators, Larva Labs, released their 3rd project called <a href="https://meebits.larvalabs.com/" rev="en_rl_none">Meebits</a>.</div><div><br/></div><div>The public sale of 9000 Meebits averaged around 2.5Ξ each.</div><div><br/></div><div>Since the value of ETH rose in the past week from $2000 to $3200, the value at the time of sale was $8000 each.</div><div><br/></div><div>They sold out in a mere 6 hours, netting the creators about $72 million worth of ETH.</div><div><br/></div><div>The market is a bit crazy right now, but NFTs will continue to evolve and provide more value than simply collectible art.</div><div><br/></div><div>As more applications begin to integrate on-chain assets for tangible use, such as in AR or VR, the value of digital goods will become more understandable.</div><div><br/></div><div>Digital scarcity is real.</div>`

export default new Reflection({
  html,
  id: 'nfts',
  tags: [BLOCKCHAIN],
  title,
  words: 148,
  written: '5/4/21'
})
