import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'PTO'
const html = `<div>Paid Time Off can be difficult to take.</div><div><br/></div><div>Your team will always be working on something important, and it doesn't feel great to take time off while they're at work.</div><div><br/></div><div>I used to work at a small company with an unlimited PTO policy.</div><div><br/></div><div>In 20 months I only remember taking one day off, and it was to attend a funeral.</div><div><br/></div><div>In hindsight, I made the mistake of not taking more time off.</div><div><br/></div><div>When you don't accrue a quantified amount, you feel guilty taking PTO, especially when you don't have the experience of having an accrual policy.</div><div><br/></div><div>If you have PTO, use it.</div><div><br/></div><div>Time is more valuable than money.</div><div><br/></div>`

export default new Reflection({
  html,
  id: 'pto',
  tags: [BUSINESS],
  title,
  words: 108,
  written: '2/8/22'
})
