import Reflection from '@/stores/models/reflection'
import {
  BLOCKCHAIN,
  COMMUNITY,
  CRYPTOCURRENCY,
  HISTORY,
  POLITICS
} from '@/tags'

const title = 'Network States'
const html = `<div>Human civilization is not that old.</div><div><br/></div><div>Around 12,000 years ago, we shifted from hunting and gathering to agriculture.</div><div><br/></div><div>The first form of writing dates back to around 5000 years ago.</div><div><br/></div><div>The internet has only existed since 1983.</div><div><br/></div><div>Civilization's rate of change has grown exponentially since the Industrial Revolution, and it's becoming vertical with the Information Age.</div><div><br/></div><div>There's no reason to believe that the structure of global society will be static with the same nations and countries in 2100 as in 2000.</div><div><br/></div><div>Balaji Srinivasan presents a vision of the future in his book, <a href="https://thenetworkstate.com/" rev="en_rl_none">The Network State</a>.</div><div><br/></div><div>Network states will be cloud first, land last.</div><div><br/></div><div>Digital communities who opt in and can opt out at any time will be the truest form of democracy.</div><div><br/></div><div>They'll defend their wealth with cryptography.</div><div><br/></div><div>They'll crowdfund physical territory.</div><div><br/></div><div>Each society will start with "One Commandment" — its moral innovation, its reason for being.</div><div><br/></div><div>Ultimately, with on-chain verifiable proof of population, income, and land, they'll gain diplomatic recognition from existing states.</div><div><br/></div><div>A society with thriving, cooperative, interconnected network states is a future worth striving towards.</div>`

export default new Reflection({
  html,
  id: 'network-states',
  tags: [BLOCKCHAIN, COMMUNITY, CRYPTOCURRENCY, HISTORY, POLITICS],
  title,
  words: 178,
  written: '8/31/22'
})
