import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import cx from 'classnames'

import './MobileSideNav.scss'

const mobileNavItems = [
  {
    text: 'Now',
    to: '/now'
  }
  /* {
    text: 'Blog',
    to: '/blog'
  }, */
  // {
  //   text: 'Notes',
  //   to: '/notes'
  // },
  // {
  //   text: 'Reflections',
  //   to: '/reflections'
  // }
  // {
  //   text: 'Contact',
  //   to: '/contact'
  // }
]

class MobileSideNav extends Component {
  onClose = () => {
    const { onClose } = this.props
    onClose()
  }
  menuClick = (e) => {
    e.stopPropagation()
  }
  onClickMenuItem = (e, isActive) => {
    if (isActive) {
      e.preventDefault()
    } else {
      this.onClose()
    }
  }
  renderItems () {
    const { location } = this.props
    const { pathname } = location
    return mobileNavItems.map(item => {
      const isActive = pathname === item.to
      return (
        <Link
          className={cx('MobileSideNav__item', { 'is-active': isActive })}
          key={`MobileSideNav-${item.to}`}
          onClick={(e) => this.onClickMenuItem(e, isActive)}
          to={item.to}
        >
          <span>{item.text}</span>
        </Link>
      )
    })
  }
  render () {
    const { isOpen } = this.props
    return (
      <div className={cx('MobileSideNav__wrapper', { 'slide-left': isOpen })} onClick={this.onClose}>
        <div className='MobileSideNav' onClick={this.menuClick}>
          <div className='MobileSideNav__close' onClick={this.onClose}>
            <i className='fa fa-angle-right' />
          </div>
          <div className='MobileSideNav__items'>
            {this.renderItems()}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(MobileSideNav)
