import MakeItBloom from '@/reflections/2023/MakeItBloom.js'
import ANewChapter from '@/reflections/2023/ANewChapter.js'
import PeaceAndChaos from '@/reflections/2023/PeaceAndChaos.js'
import Growth from '@/reflections/2023/Growth.js'
import BeginnersMind from '@/reflections/2023/BeginnersMind.js'
import DevX from '@/reflections/2023/DevX.js'
import IvyCoveredIntellectualRot from '@/reflections/2023/IvyCoveredIntellectualRot.js'
import Antisemitism from '@/reflections/2023/Antisemitism.js'
import TheLastHamasAttack from '@/reflections/2023/TheLastHamasAttack.js'
import FindingYourVoice from '@/reflections/2023/FindingYourVoice.js'
import MetaConnect2023 from '@/reflections/2023/MetaConnect2023.js'
import SinglePointsOfFailure from '@/reflections/2023/SinglePointsOfFailure.js'
import BetterLeftUnsaid from '@/reflections/2023/BetterLeftUnsaid.js'
import GoPositiveAndGoFirst from '@/reflections/2023/GoPositiveAndGoFirst.js'
import Chicago from '@/reflections/2023/Chicago.js'
import DeathNote from '@/reflections/2023/DeathNote.js'
import SeedOils from '@/reflections/2023/SeedOils.js'
import EmbraceChaos from '@/reflections/2023/EmbraceChaos.js'
import FiguringItOut from '@/reflections/2023/FiguringItOut.js'
import CarbonDioxideTolerance from '@/reflections/2023/CarbonDioxideTolerance.js'
import SteppingIntoTheUnknown from '@/reflections/2023/SteppingIntoTheUnknown.js'
import Threads from '@/reflections/2023/Threads.js'
import _200HourYogaTeacherTraining from '@/reflections/2023/200HourYogaTeacherTraining.js'
import Satsang from '@/reflections/2023/Satsang.js'
import FacingFears from '@/reflections/2023/FacingFears.js'
import JetLag from '@/reflections/2023/JetLag.js'
import AppleVisionPro from '@/reflections/2023/AppleVisionPro.js'
import Succession from '@/reflections/2023/Succession.js'
import ChangeOfFortune from '@/reflections/2023/ChangeOfFortune.js'
import Pugs from '@/reflections/2023/Pugs.js'
import SoundBaths from '@/reflections/2023/SoundBaths.js'
import Tetris from '@/reflections/2023/Tetris.js'
import Layoffs from '@/reflections/2023/Layoffs.js'
import AIRisk from '@/reflections/2023/AIRisk.js'
import PlayingPossum from '@/reflections/2023/PlayingPossum.js'
import Patience from '@/reflections/2023/Patience.js'
import MinimumEffectiveDose from '@/reflections/2023/MinimumEffectiveDose.js'
import GPT4 from '@/reflections/2023/GPT-4.js'
import BankRuns from '@/reflections/2023/BankRuns.js'
import OriginOfThoughts from '@/reflections/2023/OriginOfThoughts.js'
import PrixFixe from '@/reflections/2023/PrixFixe.js'
import BirthdayWishes from '@/reflections/2023/BirthdayWishes.js'
import GameDecidingCalls from '@/reflections/2023/GameDecidingCalls.js'
import TunnelVision from '@/reflections/2023/TunnelVision.js'
import WritersBlock from '@/reflections/2023/WritersBlock.js'
import Escapism from '@/reflections/2023/Escapism.js'
import SecondBrain from '@/reflections/2023/SecondBrain.js'
import Survival from '@/reflections/2023/Survival.js'
import MondayMusings from '@/reflections/2023/MondayMusings.js'

import BeavisAndButthead from '@/reflections/2022/BeavisAndButthead.js'
import Messi from '@/reflections/2022/Messi.js'
import ChatGPT from '@/reflections/2022/ChatGPT.js'
import Isolation from '@/reflections/2022/Isolation.js'
import WhatMattersMost from '@/reflections/2022/WhatMattersMost.js'
import FishBones from '@/reflections/2022/FishBones.js'
import FamilyWeddings from '@/reflections/2022/FamilyWeddings.js'
import PunchingClocks from '@/reflections/2022/PunchingClocks.js'
import HealthScares from '@/reflections/2022/HealthScares.js'
import Depersonalization from '@/reflections/2022/Depersonalization.js'
import InhalingFood from '@/reflections/2022/InhalingFood.js'
import CaffeineCrashes from '@/reflections/2022/CaffeineCrashes.js'
import Kona from '@/reflections/2022/Kona.js'
import Boredom from '@/reflections/2022/Boredom.js'
import YellowCards from '@/reflections/2022/YellowCards.js'
import PowerOutages from '@/reflections/2022/PowerOutages.js'
import InfiniteLearning from '@/reflections/2022/InfiniteLearning.js'
import NetworkStates from '@/reflections/2022/NetworkStates.js'
import Serendipity from '@/reflections/2022/Serendipity.js'
import OnceInALifetime from '@/reflections/2022/OnceInALifetime.js'
import VinScully from '@/reflections/2022/VinScully.js'
import HomemadeSauerkraut from '@/reflections/2022/HomemadeSauerkraut.js'
import OnSites from '@/reflections/2022/OnSites.js'
import Airports from '@/reflections/2022/Airports.js'
import JamesWebbSpaceTelescope from '@/reflections/2022/JamesWebbSpaceTelescope.js'
import OceanSwims from '@/reflections/2022/OceanSwims.js'
import ProChoice from '@/reflections/2022/ProChoice.js'
import ScoutMindset from '@/reflections/2022/ScoutMindset.js'
import BearMarketsAndTheUSD from '@/reflections/2022/BearMarketsAndTheUSD.js'
import PullupBars from '@/reflections/2022/PullupBars.js'
import MassShootings from '@/reflections/2022/MassShootings.js'
import DebugLogs from '@/reflections/2022/DebugLogs.js'
import FinalStretches from '@/reflections/2022/FinalStretches.js'
import LaunchDates from '@/reflections/2022/LaunchDates.js'
import BAYCOthersideMint from '@/reflections/2022/BAYCOthersideMint.js'
import AnalogComputing from '@/reflections/2022/AnalogComputing.js'
import Moonbirds from '@/reflections/2022/Moonbirds.js'
import ImpossibleLists from '@/reflections/2022/ImpossibleLists.js'
import ConvenientFood from '@/reflections/2022/ConvenientFood.js'
import ReturnToOffice from '@/reflections/2022/ReturnToOffice.js'
import PlaneCrashes from '@/reflections/2022/PlaneCrashes.js'
import ShortTermCapitalGains from '@/reflections/2022/ShortTermCapitalGains.js'
import EmergencyRoomVisits from '@/reflections/2022/EmergencyRoomVisits.js'
import RussianInvasionOfUkraine from '@/reflections/2022/RussianInvasionOfUkraine.js'
import Timing from '@/reflections/2022/Timing.js'
import Twenties from '@/reflections/2022/Twenties.js'
import PTO from '@/reflections/2022/PTO.js'
import BlackHistoryMonth from '@/reflections/2022/BlackHistoryMonth.js'
import FirstImpressions from '@/reflections/2022/FirstImpressions.js'
import TheLatticework from '@/reflections/2022/TheLatticework.js'
import Streaks from '@/reflections/2022/Streaks.js'
import SlowingDown from '@/reflections/2022/SlowingDown.js'

import MovieTheaters from '@/reflections/2021/MovieTheaters.js'
import SocialFatigue from '@/reflections/2021/SocialFatigue.js'
import TwoADays from '@/reflections/2021/TwoADays.js'
import IAmThat from '@/reflections/2021/IAmThat.js'
import MonkeySeeMonkeyDo from '@/reflections/2021/MonkeySeeMonkeyDo.js'
import ChoosingMedia from '@/reflections/2021/ChoosingMedia.js'
import ABZ from '@/reflections/2021/ABZ.js'
import FeelingSick from '@/reflections/2021/FeelingSick.js'
import Meta from '@/reflections/2021/Meta.js'
import SelfMade from '@/reflections/2021/SelfMade.js'
import FitnessClasses from '@/reflections/2021/FitnessClasses.js'
import Blank from '@/reflections/2021/Blank.js'
import Outages from '@/reflections/2021/Outages.js'
import GettingStuck from '@/reflections/2021/GettingStuck.js'
import ReadingChallenges from '@/reflections/2021/ReadingChallenges.js'
import Gaming from '@/reflections/2021/Gaming.js'
import Logitech from '@/reflections/2021/Logitech.js'
import Spectrum from '@/reflections/2021/Spectrum.js'
import Chapters from '@/reflections/2021/Chapters.js'
import TheTaliban from '@/reflections/2021/TheTaliban.js'
import Aspen from '@/reflections/2021/Aspen.js'
import Rent from '@/reflections/2021/Rent.js'
import TheMetaverse from '@/reflections/2021/TheMetaverse.js'
import Transitoriness from '@/reflections/2021/Transitoriness.js'
import Twitter from '@/reflections/2021/Twitter.js'
import Sacrifices from '@/reflections/2021/Sacrifices.js'
import Years from '@/reflections/2021/Years.js'
import SelfReviews from '@/reflections/2021/SelfReviews.js'
import MasksOff from '@/reflections/2021/MasksOff.js'
import MemeStonks from '@/reflections/2021/MemeStonks.js'
import Friendship from '@/reflections/2021/Friendship.js'
import Weekends from '@/reflections/2021/Weekends.js'
import RadicalCandor from '@/reflections/2021/RadicalCandor.js'
import AccordingToPlan from '@/reflections/2021/AccordingToPlan.js'
import NFTs from '@/reflections/2021/NFTs.js'
import Systems from '@/reflections/2021/Systems.js'
import Time from '@/reflections/2021/Time.js'
import Bitcoin from '@/reflections/2021/Bitcoin.js'
import PublicReviews from '@/reflections/2021/PublicReviews.js'
import TheStoryteller from '@/reflections/2021/TheStoryteller.js'
import MaintainingSoftware from '@/reflections/2021/MaintainingSoftware.js'
import TechnicalExplanations from '@/reflections/2021/TechnicalExplanations.js'
import Main from '@/reflections/2021/Main.js'
import Perfectionism from '@/reflections/2021/Perfectionism.js'
import Bubbles from '@/reflections/2021/Bubbles.js'
import RampingUp from '@/reflections/2021/RampingUp.js'
import TomBrady from '@/reflections/2021/TomBrady.js'
import Sleep from '@/reflections/2021/Sleep.js'
import TheHillWeClimb from '@/reflections/2021/TheHillWeClimb.js'
import Doomscrolling from '@/reflections/2021/Doomscrolling.js'
import Finishing from '@/reflections/2021/Finishing.js'
import Golf from '@/reflections/2021/Golf.js'

import TheFourAgreements from '@/reflections/2020/TheFourAgreements.js'
import TwentyTwenty from '@/reflections/2020/2020.js'
import PlanetaryResponsibility from '@/reflections/2020/PlanetaryResponsibility.js'
import VirtualOffsites from '@/reflections/2020/VirtualOffsites.js'
import Resuming from '@/reflections/2020/Resuming.js'
import TheGrandCanyon from '@/reflections/2020/TheGrandCanyon.js'
import Homelessness from '@/reflections/2020/Homelessness.js'
import ParkinsonsLaw from '@/reflections/2020/ParkinsonsLaw.js'
import Media from '@/reflections/2020/Media.js'
import Baseball from '@/reflections/2020/Baseball.js'
import Consistency from '@/reflections/2020/Consistency.js'
import Priorities from '@/reflections/2020/Priorities.js'
import InnerScorecard from '@/reflections/2020/InnerScorecard.js'
import Mundane from '@/reflections/2020/Mundane.js'
import PhoneAddiction from '@/reflections/2020/PhoneAddiction.js'
import Breakthroughs from '@/reflections/2020/Breakthroughs.js'
import Phobias from '@/reflections/2020/Phobias.js'
import ImperfectEnvironments from '@/reflections/2020/ImperfectEnvironments.js'
import EightTwentyFour from '@/reflections/2020/8-24.js'
import DormantTies from '@/reflections/2020/DormantTies.js'
import Overconfidence from '@/reflections/2020/Overconfidence.js'
import Noise from '@/reflections/2020/Noise.js'
import Splendor from '@/reflections/2020/Splendor.js'
import Listening from '@/reflections/2020/Listening.js'
import Exponentials from '@/reflections/2020/Exponentials.js'
import Scale from '@/reflections/2020/Scale.js'
import AdHominem from '@/reflections/2020/AdHominem.js'
import No from '@/reflections/2020/No.js'
import Impermanence from '@/reflections/2020/Impermanence.js'
import WhiteFragility from '@/reflections/2020/WhiteFragility.js'
import WhitePrivilege from '@/reflections/2020/WhitePrivilege.js'
import Solitude from '@/reflections/2020/Solitude.js'
import Habits from '@/reflections/2020/Habits.js'
import Predictions from '@/reflections/2020/Predictions.js'
import Measurability from '@/reflections/2020/Measurability.js'
import DistributedWork from '@/reflections/2020/DistributedWork.js'
import SoundBites from '@/reflections/2020/SoundBites.js'
import PhysicalDistancing from '@/reflections/2020/PhysicalDistancing.js'
import Sunrise from '@/reflections/2020/Sunrise.js'
import SelfCare from '@/reflections/2020/SelfCare.js'
import Rehearsals from '@/reflections/2020/Rehearsals.js'
import Panic from '@/reflections/2020/Panic.js'
import Outbreaks from '@/reflections/2020/Outbreaks.js'
import KuntaKinte from '@/reflections/2020/KuntaKinte.js'
import Scope from '@/reflections/2020/Scope.js'
import Curiosity from '@/reflections/2020/Curiosity.js'
import New from '@/reflections/2020/New.js'
import PassiveVoice from '@/reflections/2020/PassiveVoice.js'
import Kobe from '@/reflections/2020/Kobe.js'
import Attention from '@/reflections/2020/Attention.js'
import BlindSpots from '@/reflections/2020/BlindSpots.js'
import Impossibility from '@/reflections/2020/Impossibility.js'

import Routines from '@/reflections/2019/Routines.js'
import Recharging from '@/reflections/2019/Recharging.js'
import PlayingDown from '@/reflections/2019/PlayingDown.js'
import Curation from '@/reflections/2019/Curation.js'
import Unlearning from '@/reflections/2019/Unlearning.js'
import Gratitude from '@/reflections/2019/Gratitude.js'
import TurningPoints from '@/reflections/2019/TurningPoints.js'
import ModernEducation from '@/reflections/2019/ModernEducation.js'
import CrowdFolly from '@/reflections/2019/CrowdFolly.js'
import Persistence from '@/reflections/2019/Persistence.js'
import Aimlessness from '@/reflections/2019/Aimlessness.js'
import TheSelf from '@/reflections/2019/TheSelf.js'
import Injuries from '@/reflections/2019/Injuries.js'
import Conviction from '@/reflections/2019/Conviction.js'
import Velocity from '@/reflections/2019/Velocity.js'
import Brevity from '@/reflections/2019/Brevity.js'
import PredatoryDelay from '@/reflections/2019/PredatoryDelay.js'
import Finiteness from '@/reflections/2019/Finiteness.js'
import Externalities from '@/reflections/2019/Externalities.js'
import GymRespect from '@/reflections/2019/GymRespect.js'
import Inversion from '@/reflections/2019/Inversion.js'
import Metaphors from '@/reflections/2019/Metaphors.js'
import TheFrog from '@/reflections/2019/TheFrog.js'
import Edges from '@/reflections/2019/Edges.js'

const mapReflectionIdsToReflections = (reflections) => {
  const reflectionIdToReflection = {}
  reflections.forEach(reflection => {
    reflectionIdToReflection[reflection.id.toString()] = reflection
  })
  return reflectionIdToReflection;
}

export default mapReflectionIdsToReflections([
  MakeItBloom,
  ANewChapter,
  PeaceAndChaos,
  Growth,
  BeginnersMind,
  DevX,
  IvyCoveredIntellectualRot,
  Antisemitism,
  TheLastHamasAttack,
  FindingYourVoice,
  MetaConnect2023,
  SinglePointsOfFailure,
  BetterLeftUnsaid,
  GoPositiveAndGoFirst,
  Chicago,
  DeathNote,
  SeedOils,
  EmbraceChaos,
  FiguringItOut,
  CarbonDioxideTolerance,
  SteppingIntoTheUnknown,
  Threads,
  _200HourYogaTeacherTraining,
  Satsang,
  FacingFears,
  JetLag,
  AppleVisionPro,
  Succession,
  ChangeOfFortune,
  Pugs,
  SoundBaths,
  Tetris,
  Layoffs,
  AIRisk,
  PlayingPossum,
  Patience,
  MinimumEffectiveDose,
  GPT4,
  BankRuns,
  OriginOfThoughts,
  PrixFixe,
  BirthdayWishes,
  GameDecidingCalls,
  TunnelVision,
  WritersBlock,
  Escapism,
  SecondBrain,
  Survival,
  MondayMusings,
  BeavisAndButthead,
  Messi,
  ChatGPT,
  Isolation,
  WhatMattersMost,
  FishBones,
  FamilyWeddings,
  PunchingClocks,
  HealthScares,
  Depersonalization,
  InhalingFood,
  CaffeineCrashes,
  Kona,
  Boredom,
  YellowCards,
  PowerOutages,
  InfiniteLearning,
  NetworkStates,
  Serendipity,
  OnceInALifetime,
  VinScully,
  HomemadeSauerkraut,
  OnSites,
  Airports,
  JamesWebbSpaceTelescope,
  OceanSwims,
  ProChoice,
  ScoutMindset,
  BearMarketsAndTheUSD,
  PullupBars,
  MassShootings,
  DebugLogs,
  FinalStretches,
  LaunchDates,
  BAYCOthersideMint,
  AnalogComputing,
  Moonbirds,
  ImpossibleLists,
  ConvenientFood,
  ReturnToOffice,
  PlaneCrashes,
  ShortTermCapitalGains,
  EmergencyRoomVisits,
  RussianInvasionOfUkraine,
  Timing,
  Twenties,
  PTO,
  BlackHistoryMonth,
  FirstImpressions,
  TheLatticework,
  Streaks,
  SlowingDown,
  MovieTheaters,
  SocialFatigue,
  TwoADays,
  IAmThat,
  MonkeySeeMonkeyDo,
  ChoosingMedia,
  ABZ,
  FeelingSick,
  Meta,
  SelfMade,
  FitnessClasses,
  Blank,
  Outages,
  GettingStuck,
  ReadingChallenges,
  Gaming,
  Logitech,
  Spectrum,
  Chapters,
  TheTaliban,
  Aspen,
  Rent,
  TheMetaverse,
  Transitoriness,
  Twitter,
  Sacrifices,
  Years,
  SelfReviews,
  MasksOff,
  MemeStonks,
  Friendship,
  Weekends,
  RadicalCandor,
  AccordingToPlan,
  NFTs,
  Systems,
  Time,
  Bitcoin,
  PublicReviews,
  TheStoryteller,
  MaintainingSoftware,
  TechnicalExplanations,
  Main,
  Perfectionism,
  Bubbles,
  RampingUp,
  TomBrady,
  Sleep,
  TheHillWeClimb,
  Doomscrolling,
  Finishing,
  Golf,
  TheFourAgreements,
  TwentyTwenty,
  PlanetaryResponsibility,
  VirtualOffsites,
  Resuming,
  TheGrandCanyon,
  Homelessness,
  ParkinsonsLaw,
  Media,
  Baseball,
  Consistency,
  Priorities,
  InnerScorecard,
  Mundane,
  PhoneAddiction,
  Breakthroughs,
  Phobias,
  ImperfectEnvironments,
  EightTwentyFour,
  DormantTies,
  Overconfidence,
  Noise,
  Splendor,
  Listening,
  Exponentials,
  Scale,
  AdHominem,
  No,
  Impermanence,
  WhiteFragility,
  WhitePrivilege,
  Solitude,
  Habits,
  Predictions,
  Measurability,
  DistributedWork,
  SoundBites,
  PhysicalDistancing,
  Sunrise,
  SelfCare,
  Rehearsals,
  Panic,
  Outbreaks,
  KuntaKinte,
  Scope,
  Curiosity,
  New,
  PassiveVoice,
  Kobe,
  Attention,
  BlindSpots,
  Impossibility,
  Routines,
  Recharging,
  PlayingDown,
  Curation,
  Unlearning,
  Gratitude,
  TurningPoints,
  ModernEducation,
  CrowdFolly,
  Persistence,
  Aimlessness,
  TheSelf,
  Injuries,
  Conviction,
  Velocity,
  Brevity,
  PredatoryDelay,
  Finiteness,
  Externalities,
  GymRespect,
  Inversion,
  Metaphors,
  TheFrog,
  Edges
])
