import Reflection from '@/stores/models/reflection'
import {HISTORY} from '@/tags'

const title = 'Vin Scully'
const html = `<div>The greatest sports broadcaster of all time passed away last week.</div><div><br/></div><div>His 67-year career ended <a href="https://www.youtube.com/watch?v=psLIIOhdqo8" rev="en_rl_none">in 2016</a>.</div><div><br/></div><div>When I first started watching baseball as a kid, Vin had already been broadcasting for over 40 years.</div><div><br/></div><div>I remember he'd tell the most engaging stories, not just about baseball but about life.</div><div><br/></div><div>A rookie from the other team would walk up to the plate, and Vin would start talking about the player's backstory and childhood.</div><div><br/></div><div>I always wondered how he did research before games.</div><div><br/></div><div>Nothing I can write can fully express his craft, his charm, and his impact on the millions who were lucky enough to tune in to listen to his wisdom.</div>`

export default new Reflection({
  html,
  id: 'vin-scully',
  tags: [HISTORY],
  title,
  words: 111,
  written: '8/9/22'
})
