import Reflection from '@/stores/models/reflection'

const title = 'Self-made'
const html = `<div>Nobody's success is entirely self-made.</div><div><br/></div><div>I'm not discounting individuals that have climbed to great heights from humble beginnings.</div><div><br/></div><div>They're certainly more impressive and deserving than people who inherit their wealth, but we all rely on countless past innovations.</div><div><br/></div><div>Few can thrive in our time without agriculture, the electrical grid, cars, information technology, and most importantly, a democratic government.</div><div><br/></div><div>The government protects private property and enforces contractual agreements.</div><div><br/></div><div>The ability to achieve fame or success requires a society that has such opportunities.</div><div><br/></div><div>Capitalist societies protect their citizens, giving them the freedom to pursue ventures that may reap rewards.</div><div><br/></div><div>Those who are "self-made" create something from nothing, but they can't do it without customers to buy their products and services.</div><div><br/></div><div>The vast library of human knowledge in the form of books, newspapers, online writing, and media enable people to synthesize these ideas and build upon them.</div><div><br/></div><div>The internet created endless opportunities by connecting the world.</div><div><br/></div><div>There's never been a better time to invest in yourself.</div>`

export default new Reflection({
  html,
  id: 'self-made',
  tags: null,
  title,
  words: 163,
  written: '10/26/21'
})
