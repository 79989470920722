import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Self-care'
const html = `<div>Did you get enough sleep last night?</div><div><br/></div><div>How is your diet?</div><div><br/></div><div>Did you exercise today?</div><div><br/></div><div>The answers to these three questions have a substantive impact on your well-being.</div><div><br/></div><div>It's easy to forget the basics and feel overwhelmed when life is chaotic.<br/></div><div><br/></div><div>Simple things make all the difference.</div><div><br/></div><div>You can’t pour from an empty cup.</div><div><br/></div><div>Take care of yourself so you can take care of others.</div>`

export default new Reflection({
  html,
  id: 'self-care',
  tags: [HEALTH],
  title,
  words: 66,
  written: '3/31/20'
})
