import Reflection from '@/stores/models/reflection'

const title = '"The Hill We Climb"'
const html = `<div>I don't think many Americans can answer how our nation will possibly unite after the January 6th storming of the Capitol.</div><div><br/></div><div><a href="https://www.cnn.com/2021/01/20/politics/amanda-gorman-inaugural-poem-transcript/index.html">Amanda Gorman's poem</a> during Biden's inauguration, though, was an inspiring start.</div><div><br/></div><div>Just, wow.</div><div><br/></div><div>I'm actually having a hard time thinking about what to write about it.</div><div><br/></div><div>Her words and performance stand on their own.</div><div><br/></div><div>Words have immense power, and she gives me hope that our country and world will rise.</div><div><br/></div><div>"There is always light,</div><div><br/></div><div>if only we're brave enough to see it</div><div><br/></div><div>if only we're brave enough to be it."</div><div><br/></div><div>Our challenge, then, is to get others to see the light.</div>`

export default new Reflection({
  html,
  id: 'the-hill-we-climb',
  tags: null,
  title,
  words: 102,
  written: '1/26/21'
})
