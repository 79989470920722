import Reflection from '@/stores/models/reflection'

const title = 'Scale'
const html = `<div>This week is my first week as a software engineer at Facebook.</div><div><br/></div><div>Due to COVID-19, onboarding remotely with 400+ people around the globe has been quite an experience.</div><div><br/></div><div>For Facebook, whose mission is "to give people the power to build community and bring the world closer together," designing systems and processes that scale is critical.</div><div><br/></div><div>A virtual orientation for hundreds of people is a unique challenge, but connecting billions of people through software is on another level.</div><div><br/></div><div>Giving 7.8 billion people the power to communicate is both beneficial and rife with risk.</div><div><br/></div><div>More voices means more perspectives, but more noise means more difficulty spotting the bad actors.</div><div><br/></div><div>As technology and society continue to evolve, solutions create both opportunities and problems.</div><div><br/></div><div>No complex system is perfect.</div><div><br/></div><div>To solve the world’s problems, solutions must scale to serve billions of people.</div><div><br/></div><div>We live in the age of leverage.</div><div><br/></div><div>Resolving the social issues facing the world today requires the global community to communicate and collaborate.</div><div><br/></div><div>MLK famously said, "The arc of the moral universe is long, but it bends toward justice."</div><div><br/></div><div>The lever of the internet in a connected world bends the arc faster toward justice.</div><div><br/></div><div>Scaled problems need scaled solutions.</div>`

export default new Reflection({
  html,
  id: 'scale',
  tags: null,
  title,
  words: 198,
  written: '7/8/20'
})
