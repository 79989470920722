import Reflection from '@/stores/models/reflection'

const title = 'A New Chapter'
const html = `<div>In one of the recent yoga classes I attended, the teacher said something along the lines of, "Turn over a new page with each new breath."</div><div><br/></div><div>Each breath is an opportunity to begin again.</div>`

export default new Reflection({
  html,
  id: 'a-new-chapter',
  tags: null,
  title,
  words: 34,
  written: '11/28/23'
})
