import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Two-a-days'
const html = `<div>On Sunday I did 2 hot yoga classes, which was a first.</div><div><br/></div><div>Usually when I finish heated yoga, I'm completely exhausted after sweating out over a liter of water.</div><div><br/></div><div>I didn't plan on taking 2 classes, but my brother invited me to one in the late afternoon.</div><div><br/></div><div>I've been sleeping well and feeling great, so I decided — what the hell, why not?</div><div><br/></div><div>The second class was a little tough, but I was hydrated and ready.</div><div><br/></div><div>I felt amazing when it was done.</div><div><br/></div><div>A lot of people struggle with health and fitness goals because they lack the time, energy, or the will to hold themselves accountable.</div><div><br/></div><div>Desk jobs prevent people from challenging themselves physically, and when they do have time, other responsibilities can get in the way.</div><div><br/></div><div>Resting is also easier than exerting.</div><div><br/></div><div>When you do have the time and energy to push yourself, take advantage of the opportunity.</div>`

export default new Reflection({
  html,
  id: 'two-a-days',
  tags: [HEALTH],
  title,
  words: 149,
  written: '12/14/21'
})
