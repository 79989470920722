import { MONTHS } from '@/constants'

export const addClass = (el, className) => {
  let classNames = []
  let elClassNames = el.className ? el.className.split(' ') : []
  elClassNames.forEach((elClassName) => {
    if (elClassName !== className) {
      classNames.push(elClassName)
    }
  })
  classNames.push(className)
  el.className = classNames.join(' ').trim()
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getMonthYearStr = (date) => {
  const d = new Date(date)
  return `${MONTHS[d.getMonth()]} ${d.getFullYear()}`
}

export const removeClass = (el, className) => {
  let classNames = []
  let elClassNames = el.className ? el.className.split(' ') : []
  elClassNames.forEach((elClassName) => {
    if (elClassName !== className) {
      classNames.push(elClassName)
    }
  })
  el.className = classNames.length ? classNames.join(' ').trim() : ''
}

export const wordCountText = (words) => {
  return `${words} words`
}

export const writtenDateToYear = (written) => {
  // This will break in 2100 lol
  return `20${written.slice(-2)}`
}
