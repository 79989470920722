import Reflection from '@/stores/models/reflection'

const title = 'Getting Stuck'
const html = `<div>I've been working on a complex problem at work that I planned to have finished by now.</div><div><br/></div><div>I can get frustrated when I'm not making a lot of progress.</div><div><br/></div><div>If you're doing something that has been done before, which is the case 99.9% of the time, then there are answers to your questions.</div><div><br/></div><div>There's a choice between doing things the hard way — trying all the possible ideas you have and seeing if they work — or just asking someone who may be able to help you.</div><div><br/></div><div>Depending on the situation, either choice can be better.</div><div><br/></div><div>Of course the latter is easier, but there's a tradeoff between figuring things out independently and relying on others.</div><div><br/></div><div>The person you ask may not have the answer readily available, so they'll have to switch contexts and delay their priorities.</div><div><br/></div><div>Independence is especially important as remote work continues to prevail.</div><div><br/></div><div>The answer you're looking for is out there.</div><div><br/></div><div>It's only a matter of time until you find it.</div>`

export default new Reflection({
  html,
  id: 'getting-stuck',
  tags: null,
  title,
  words: 164,
  written: '9/28/21'
})
