import Reflection from '@/stores/models/reflection'

const title = 'Conviction'
const html = `<div>You are what you believe.</div><div><br/></div><div>Our beliefs guide our actions, and our actions define us.</div><div><br/></div><div>To improve over time, we need to grasp the foundations of how we form beliefs.<br/></div><div><br/></div><div>During my time in college studying mechanical engineering, I wondered how scientists and mathematicians originally conceived of complex physics equations.</div><div><br/></div><div>4 years isn’t enough time to test every scientific model.</div><div><br/></div><div>One piece of evidence can render a scientific theory invalid.</div><div><br/></div><div>The statistician George Box stated, “all models are wrong, but some are useful."</div><div><br/></div><div>Even scientific laws such as Newton’s laws of motion and the universal law of gravitation are not absolutely true.</div><div><br/></div><div>We should not have uncompromising conviction in our beliefs.</div><div><br/></div><div>In Zen, the concept of "don't-know mind" expresses that everything we believe is an illusion constructed on the hollow foundation of language. </div><div><br/></div><div>If we come from a place of humility and curiosity, we can be open to experiencing the wholeness of life, beyond the rigidity of stubborn perspectives.</div><div><br/></div><div>Changing one’s mind is a sign of adaptability, not shortcoming.</div><div><br/></div><div>As the radio journalist Celeste Headlee suggests — if you don’t know something, say you don’t know.</div><div><br/></div><div>Only then can you learn.</div>`

export default new Reflection({
  html,
  id: 'conviction',
  tags: null,
  title,
  words: 191,
  written: '10/2/19'
})
