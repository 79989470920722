import Reflection from '@/stores/models/reflection'

const title = 'The Last Hamas Attack'
const html = `<div>On October 7, Hamas terrorists murdered hundreds of innocent civilians in Israel.</div><div><br/></div><div>The videos I've seen sicken me — barbarians parading mutilated, dead bodies from cars while they scream religious cries.</div><div><br/></div><div>Other stories are even worse.</div><div><br/></div><div>Tragedies like this attack make it difficult to have hope of peace in the Middle East.</div><div><br/></div><div>Organizations like Hamas indoctrinate their children with false narratives.</div><div><br/></div><div>The original Charter of Hamas states: <i>"The Day of Judgment will not come about until Moslems fight Jews and kill them. Then, the Jews will hide behind rocks and trees, and the rocks and trees will cry out: 'O Moslem, there is a Jew hiding behind me, come and kill him.'"</i></div><div><br/></div><div>Since changing the hearts and minds of these ignorant people is likely impossible, war becomes the only viable strategy of self-defense.</div><div><br/></div><div>Israel is surrounded by nations that want it wiped off the map, so passive self-defense is a ticking time bomb until the next attack.</div><div><br/></div><div>If a terrorist organization rapes, kidnaps, and murders your women, children, and elderly, retaliation is justifiable.</div><div><br/></div><div>War is bad for everyone, but how do you respond to such a cold-blooded disregard for human life?</div><div><br/></div><div>I have no doubt that Israel will survive and thrive.</div><div><br/></div><div>Am Israel Chai.</div>`

export default new Reflection({
  html,
  id: 'the-last-hamas-attack',
  tags: null,
  title,
  words: 204,
  written: '10/10/23'
})
