import Reflection from '@/stores/models/reflection'

const title = 'Layoffs'
const html = `<div>Last week I survived the 2nd round of layoffs at Meta since last November.</div><div><br/></div><div>Thousands of people received an email saying they lost their jobs.</div><div><br/></div><div>The paradox of capitalism is that it can provide the greatest opportunities and freedoms, but it can be ruthless.</div>`

export default new Reflection({
  html,
  id: 'layoffs',
  tags: null,
  title,
  words: 44,
  written: '4/25/23'
})
