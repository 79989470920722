import Reflection from '@/stores/models/reflection'

const title = 'Spectrum'
const html = `<div>I'm referring to Spectrum the internet company, not electromagnetic wavelengths or a range between 2 points.</div><div><br/></div><div>I called to sign up for internet 2 weeks ago, and the salesman said the previous tenant used their services so I just had to pick up equipment and plug it in.</div><div><br/></div><div>I moved in a few days ago, picked up the modem and router, and, lo and behold, they didn't work.</div><div><br/></div><div>Apparently Spectrum deactivated the coax cable and a technician has to come fix it, and they don't have any available appointments for 3 days.</div><div><br/></div><div>Luckily I live next to the landlord, who generously let me use his router in the meantime.</div><div><br/></div><div>I'm writing and publishing this note using their connection, so I'm grateful.</div><div><br/></div><div>Spectrum is notorious for bad customer service, but I haven't had my own story until now.</div><div><br/></div><div>Note to self: don't trust Spectrum.</div><div><br/></div><div>Take matters into your own hands and make sure your internet works before it's too late — especially when you need it to do your job.</div>`

export default new Reflection({
  html,
  id: 'spectrum',
  tags: null,
  title,
  words: 169,
  written: '8/31/21'
})
