import React from 'react'
import Particles from 'react-particles-js'
import { orange300, orange900 } from '@/styles/settings/variables.scss'

import './Particles.scss'

export default function () {
  return (
    <div className='Particles'>
      <Particles
        params={{
          'particles': {
            'color': {
              'value': orange900
            },
            'number': {
              'value': 60,
              'density': {
                'enable': true,
                'value_area': 1500
              }
            },
            'line_linked': {
              'color': {
                'value': orange300
              },
              'enable': true,
              'opacity': 0.32
            },
            'move': {
              'direction': 'right',
              'speed': 0.08
            },
            'size': {
              'value': 1
            }
          },
          'interactivity': {
            'events': {
              'onclick': {
                'enable': true,
                'mode': 'push'
              }
            },
            'modes': {
              'push': {
                'particles_nb': 1
              }
            }
          },
          'retina_detect': true
        }}
      />
    </div>
  )
}
