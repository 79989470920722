import Reflection from '@/stores/models/reflection'

const title = 'Growth'
const html = `<div>If it doesn't challenge you, you're not growing.</div>`

export default new Reflection({
  html,
  id: 'growth',
  tags: null,
  title,
  words: 8,
  written: '11/13/23'
})
