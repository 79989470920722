import Reflection from '@/stores/models/reflection'

const title = 'Feeling Sick'
const html = `<div>I don't remember the last time I got sick.</div><div><br/></div><div>Fevers, sore throats, congestion, and dizziness are no fun.</div><div><br/></div><div>When you're unwell, all you want is to feel normal again.</div><div><br/></div><div>All of people's supposed problems — financial, relationship, career, etc. — would pale in comparison to any serious health concern.</div><div><br/></div><div>If you wake up with no physical pain and feel mentally balanced, you have a lot to be thankful for.</div>`

export default new Reflection({
  html,
  id: 'feeling-sick',
  tags: null,
  title,
  words: 69,
  written: '11/9/21'
})
