import Reflection from '@/stores/models/reflection'

const title = 'Chicago'
const html = `<div>I went to Chicago this past weekend for my cousin's wedding.</div><div><br/></div><div>It was the first time I stayed in the heart of the city, and I love how walkable it is compared to LA.</div><div><br/></div><div>Besides the wedding, the highlight of the trip was visiting the Museum of Science and Industry, which was founded by one of my ancestors Julius Rosenwald.</div><div><br/></div><div>Most museums just have art to look at, but MSI has interactive exhibits that allow you to experience scientific concepts firsthand.</div><div><br/></div><div>You can easily spend a full day there, but my family had to make the visit short to continue our bus tour to a few other landmarks associated with Julius, including the Rosenwald Courts and the Wabash Avenue YMCA.</div><div><br/></div><div>Chicago is such a beautiful city.</div><div><br/></div><div>I look forward to returning someday and walking along the river and Lake Michigan.</div>`

export default new Reflection({
  html,
  id: 'chicago',
  tags: null,
  title,
  words: 140,
  written: '8/29/23'
})
