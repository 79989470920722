import Reflection from '@/stores/models/reflection'

const title = 'Antisemitism'
const html = `<div>There are 23 countries where Islam is the official religion.</div><div><br/></div><div>Judaism has only one.</div><div><br/></div><div>The Kingdom of Israel existed 3000 years ago.</div><div><br/></div><div>Israelis didn't colonize the land like Europeans did in America.</div><div><br/></div><div>For three millennia, the Jewish people have faced different levels of opposition in Israel from various empires: Assyrians around 722 BCE, Babylonians around 586 BCE, Greeks under Antiochus IV in 167 BCE, Romans starting in 63 BCE, Byzantines from 324–638, the Muslim Caliphate from 638–1099, Crusaders from 1099–1260, Mamluks from 1260–1517, and Ottomans until 1917. <sup>[1]</sup></div><div><br/></div><div>In 1947, the U.N. recommended to split the land into Arab and Jewish states.</div><div><br/></div><div>Israelis accepted, but the Arabs rejected it, leading to Egypt, Iraq, Jordan, Lebanon, and Syria invading Israel.</div><div><br/></div><div>Only after defending itself did Israel gain international recognition in 1948.</div><div><br/></div><div>Time and time again, the Jewish people have had to defend their right to exist.</div><div><br/></div><div>If you criticize Israel for colonizing the land, please either learn the history or admit you're antisemitic.</div><div><br/></div><div>If you deny the right for Israel to exist, you are antisemitic.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> In her book <i>Israel: A Simple Guide to the Most Misunderstood Country on Earth</i>, Noa Tishby explains which groups of people had control over the land of Israel for the last 3000 years.</div>`

export default new Reflection({
  html,
  id: 'antisemitism',
  tags: null,
  title,
  words: 209,
  written: '10/18/23'
})
