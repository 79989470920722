import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@/components'
import reflections from '@/reflections'
import { wordCountText, writtenDateToYear } from '@/utils/helpers'

import './ReflectionPage.scss'

const refIds = Object.keys(reflections)

export default class ReflectionsPage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
    if (this.$reflection) {
      const aTags = this.$reflection.querySelectorAll('a')
      let i = 0
      for (; i < aTags.length; i++) {
        aTags[i].setAttribute('target', '_blank')
      }
    }
  }
  setRef = (el) => {
    this.$reflection = el
  }
  handlePrevReflection = (e) => {
    const { match } = this.props
    const { params } = match
    const { refId } = params
    const refIndex = refIds.indexOf(refId)
    const prevRefId = refIds[refIndex - 1]
    const prevReflection = reflections[prevRefId]
    const { written } = prevReflection
    this.props.history.push(`/reflections/${writtenDateToYear(written)}/${prevRefId}`)
    window.scrollTo(0, 0)
    e.target.blur()
  }
  handleNextReflection = (e) => {
    const { match } = this.props
    const { params } = match
    const { refId } = params
    const refIndex = refIds.indexOf(refId)
    const nextRefId = refIds[refIndex + 1]
    const nextReflection = reflections[nextRefId]
    const { written } = nextReflection
    this.props.history.push(`/reflections/${writtenDateToYear(written)}/${nextRefId}`)
    window.scrollTo(0, 0)
    e.target.blur()
  }
  renderReflection (reflection) {
    const {
      html,
      title,
      words,
      written
    } = reflection
    return (
      <div className='ReflectionPage__reflection'>
        <h2 className='ReflectionPage__reflection__title'>{title}</h2>
        <span className='ReflectionPage__reflection__info'>{wordCountText(words)}&nbsp;&nbsp;—&nbsp;&nbsp;{written}</span>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: html }} ref={this.setRef} />
      </div>
    )
  }
  renderTags (tags) {
    return (
      <div className='ReflectionPage__tags'>
        <hr />
        <h4>Tags</h4>
        <div className='ReflectionPage__tags__list'>
          {tags.map(tag => (
            <Link
              className='ReflectionPage__tags__list__tag'
              key={`ReflectionPage__tag-${tag}`}
              to={`/tags/${tag}`}
            >
              {tag}
            </Link>
          ))}
        </div>
      </div>
    )
  }
  renderPrevNextButtons (refId) {
    const refIndex = refIds.indexOf(refId)
    const hasPrev = refIndex > 0
    const hasNext = refIndex < refIds.length - 1
    return (
      <div className='ReflectionPage__nextPrevButtons'>
        <Button
          disabled={!hasPrev}
          onClick={this.handlePrevReflection}
          text='Prev'
          type='submit'
        />
        <Button
          disabled={!hasNext}
          onClick={this.handleNextReflection}
          text='Next'
          type='submit'
        />
      </div>
    )
  }
  render () {
    const { match } = this.props
    const { params } = match
    const { refId } = params
    const reflection = reflections[refId]
    const { tags, title } = reflection
    return (
      <div className='ReflectionPage'>
        <div className='ReflectionPage__breadcrumb'>
          <Link to='/reflections'>Reflections</Link>
          <i className='fas fa-angle-right' />
          <span>{title}</span>
        </div>
        {this.renderReflection(reflection)}
        {tags && tags.length && this.renderTags(tags)}
        {this.renderPrevNextButtons(refId)}
      </div>
    )
  }
}
