import Reflection from '@/stores/models/reflection'

const title = 'Game-Deciding Calls'
const html = `<div>No referee can catch every violation.</div><div><br/></div><div>Sports fans just want consistency and fairness.</div><div><br/></div><div>Above all, fans want refs to <i>not</i> determine the outcome of a game with a questionable call, especially in a championship game.</div><div><br/></div><div>Unless a foul is blatant or has a tangible impact on the outcome of a play, refs shouldn't interfere.</div>`

export default new Reflection({
  html,
  id: 'game-deciding-calls',
  tags: null,
  title,
  words: 54,
  written: '2/14/23'
})
