import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Image } from '@/components'
import PropTypes from 'prop-types'

import './Books.scss'

class Books extends Component {
  renderBook (book) {
    const {
      // amazonLink,
      author,
      evernoteLink,
      img,
      tags,
      title
    } = book
    return (
      <tr key={`${author}-${title}`} className='Books__book'>
        <td><Image className='Books__book__cover' src={img} /></td>
        {/* AMAZON ASSOCIATE LINK  <td><a target='_blank' href={amazonLink}>{title}</a></td>/ */}
        <td>{title}</td>
        <td>{author}</td>
        <td className='Books__book__tags'>
          {tags && tags.map(tag => <span key={`${author}-${title}-${tag}`} className='Books__book__tag'><Link to={`/tags/${tag}`}>{tag}</Link></span>)}
        </td>
        <td><a target='_blank' href={evernoteLink}>View in Evernote</a></td>
      </tr>
    )
  }

  render () {
    const { books } = this.props
    return (
      <div className='Books'>
        <table>
          <thead>
            <tr>
              <th />
              <th>Title</th>
              <th>Author</th>
              <th>Tags</th>
              <th>Notebook</th>
            </tr>
          </thead>
          <tbody>
            {books.map(book => this.renderBook(book))}
          </tbody>
        </table>
      </div>
    )
  }
}

Books.propTypes = {
  books: PropTypes.array
}

export default Books
