import { capitalizeFirstLetter } from '@/utils/helpers'

export const ACTING = 'acting'
export const BIOLOGY = 'biology'
export const BITCOIN = 'bitcoin'
export const BLOCKCHAIN = 'blockchain'
export const BUDDHISM = 'buddhism'
export const BUSINESS = 'business'
export const COMMUNITY = 'community'
export const CRYPTOCURRENCY = 'cryptocurrency'
export const ECONOMICS = 'economics'
export const ENERGY = 'energy'
export const ENVIRONMENT = 'environment'
export const INVESTING = 'investing'
export const HEALTH = 'health'
export const HISTORY = 'history'
export const MILITARY = 'military'
export const MYTHOLOGY = 'mythology'
export const PHILOSOPHY = 'philosophy'
export const PHYSICS = 'physics'
export const POKER = 'poker'
export const POLITICS = 'politics'
export const PROGRAMMING = 'programming'
export const PSYCHOLOGY = 'psychology'
export const SYSTEMS = 'systems'
export const WRITING = 'writing'
export const YOGA = 'yoga'

function createOption (value) {
  return { value: value, label: capitalizeFirstLetter(value) }
}

export const TAGS_OPTIONS = [
  createOption(ACTING),
  createOption(BIOLOGY),
  createOption(BITCOIN),
  createOption(BLOCKCHAIN),
  createOption(BUSINESS),
  createOption(CRYPTOCURRENCY),
  createOption(ECONOMICS),
  createOption(ENERGY),
  createOption(ENVIRONMENT),
  createOption(HEALTH),
  createOption(HISTORY),
  createOption(MILITARY),
  createOption(MYTHOLOGY),
  createOption(PHILOSOPHY),
  createOption(POKER),
  createOption(POLITICS),
  createOption(PROGRAMMING),
  createOption(PSYCHOLOGY),
  createOption(SYSTEMS),
  createOption(WRITING),
  createOption(YOGA)
]
