import Reflection from '@/stores/models/reflection'
import {INVESTING} from '@/tags'

const title = 'Short-term Capital Gains'
const html = `<div>I made a rookie mistake last year.</div><div><br/></div><div>I sold an investment a month short of holding it for a full year.</div><div><br/></div><div>My brother needed more capital for an investment opportunity, and I foolishly sold to reinvest.</div><div><br/></div><div>The difference between short and long term capital gains rates can vary widely depending on your tax bracket.</div><div><br/></div><div>When taking a profit, it's better to wait for a year to avoid paying additional federal income tax.</div><div><br/></div><div>If you have a choice, don't sell early.</div>`

export default new Reflection({
  html,
  id: 'short-term-capital-gains',
  tags: [INVESTING],
  title,
  words: 80,
  written: '3/15/22'
})
