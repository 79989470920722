import Reflection from '@/stores/models/reflection'

const title = 'First Impressions'
const html = `<div>I went on a first date this past weekend and my first impression of the person was not great.</div><div><br/></div><div>I was a little bummed because I was excited to meet her.</div><div><br/></div><div>I don't mean to be negative at all — maybe she was having a bad day.</div><div><br/></div><div>You only get one shot to make a first impression, and you may lose a chance to make a second.</div><div><br/></div><div>Whether it's a date, an interview, or a chance encounter in an elevator or coffee shop, always be at your best.</div>`

export default new Reflection({
  html,
  id: 'first-impressions',
  tags: null,
  title,
  words: 88,
  written: '1/25/22'
})
