import Reflection from '@/stores/models/reflection'
import {COMMUNITY} from '@/tags'

const title = 'Family Weddings'
const html = `<div>I'm starting to write this week's reflection in Philadelphia after a weekend celebrating my cousin Sarah's wedding.</div><div><br/></div><div>It's only been a few months since the family was all together in LA for my other cousin <a href="https://zachgallagher.com/reflections/2022/once-in-a-lifetime">Amanda's wedding</a>.</div><div><br/></div><div>I'm lucky to have such a large family that has stayed so close.</div><div><br/></div><div>My great grandmother Kemah's four daughters have brought their children and grandchildren together over several generations.</div><div><br/></div><div>I don't get to see my extended family often, so I truly appreciate these special moments.</div><div><br/></div><div>I want to fill my life with more moments like these.</div><div><br/></div><div>Big family gatherings don't need to only happen on holidays and formal events.</div><div><br/></div><div>You can plan a trip to bring the family together anytime.</div>`

export default new Reflection({
  html,
  id: 'family-weddings',
  tags: [COMMUNITY],
  title,
  words: 116,
  written: '11/15/22'
})
