import Reflection from '@/stores/models/reflection'

import {
  HEALTH
} from '@/tags'

const title = 'Routines'
const html = `<div>Some people dislike repetition.</div><div><br/></div><div>I find it grounding.</div><div><br/></div><div>If something is working, setting it as a default saves you time and energy.</div><div><br/></div><div>The human brain uses over 20% of the body's total energy.</div><div><br/></div><div>It is cumbersome to make unique decisions for every small detail throughout the day.</div><div><br/></div><div>When you repeat the same action, each iteration becomes easier until it feels automatic.</div><div><br/></div><div>Establishing routines opens up the time and energy to focus on your priorities.</div><div><br/></div><div>However, routines — as with anything in life — can be taken to an extreme.</div><div><br/></div><div>I’m not advocating a "Groundhog Day”-esque life.</div><div><br/></div><div>Breaking routines makes life interesting.</div><div><br/></div><div>I love the serendipity of traveling and experiencing new things.</div><div><br/></div><div>When you have the self-awareness to know which routines help you function at your best, make them non-negotiables.</div><div><br/></div><div>A non-negotiable of mine is exercise.</div><div><br/></div><div>I always feel better after working out, so it’s part of my regular routine.</div><div><br/></div><div>In the last year, I’ve also incorporated a daily 10 minute meditation that has made a noticeable difference in my normal level of equanimity.</div><div><br/></div><div>Lower your cognitive burden and improve your productivity by setting default decisions and sticking to them.</div><div><br/></div><div>Repeat what works.</div>`

export default new Reflection({
  html,
  id: 'routines',
  tags: [HEALTH],
  title,
  words: 194,
  written: '12/31/19'
})
