import Reflection from '@/stores/models/reflection'

const title = 'Pugs'
const html = `<div>This past weekend, I went to a pug adoption event that my mom helped host with the <a href="https://www.thepugqueen.org/">Pug Queen</a>.</div><div><br/></div><div>When I was little, my brother and I wanted to get a pug for years.</div><div><br/></div><div>Luckily, when I was in high school, my dad's friend was fostering one and gave him to us.</div><div><br/></div><div>Rocky was the sweetest dog.</div><div><br/></div><div>I love all dogs, but there's something that's so funny and cute about pugs, you can't help but smile in their presence.</div>`

export default new Reflection({
  html,
  id: 'pugs',
  tags: null,
  title,
  words: 79,
  written: '5/16/23'
})
