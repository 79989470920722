import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'Launch Dates'
const html = `<div>The launch date for a project I'm working on is in a couple weeks.</div><div><br/></div><div>Deadlines have a way of hyper-focusing your brain.</div><div><br/></div><div>External accountability is much more powerful than a self-imposed deadline.</div><div><br/></div><div>Nobody wants to let their team down.</div>`

export default new Reflection({
  html,
  id: 'launch-dates',
  tags: [BUSINESS],
  title,
  words: 39,
  written: '5/10/22'
})
