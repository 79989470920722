import React from 'react'
import ReactSelect from 'react-select'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {
  orange,
  orange100,
  white
} from '@/styles/settings/variables.scss'

import './Select.scss'

const Select = (props) => {
  const {
    className,
    isDisabled,
    isMulti,
    isSearchable,
    label,
    onChange,
    options,
    name,
    placeholder,
    subLabel,
    value
  } = props
  return (
    <div className={cx('Select', className)}>
      <span className='Select__label'>{label}</span>
      {subLabel && <span className='Select__sublabel'>{subLabel}</span>}
      <ReactSelect
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={!!isSearchable}
        name={name}
        label={label}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        theme={{
          colors: {
            neutral0: white,
            primary: orange,
            primary25: orange100
          }
        }}
        value={value}
      />
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired, // [ { value: 'environment', label: 'Environment' } ]
  name: PropTypes.string,
  placeholder: PropTypes.string,
  subLabel: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number, // null
    PropTypes.object, // { value: 'environment', label: 'Environment' }
    PropTypes.array // when isMulti
  ])
}

export default Select
