import Reflection from '@/stores/models/reflection'
import {COMMUNITY} from '@/tags'

const title = 'The Latticework'
const html = `<div>A few years ago I stumbled upon <a href="https://blas.com/" rev="en_rl_none">The Rabbit Hole</a>, Blas Moros' personal website.</div><div><br/></div><div>He created <a href="https://ltcwrk.com/" rev="en_rl_none">The Latticework</a>, an online learning community I recently joined.</div><div><br/></div><div>It's primarily an online resource that distills the core ideas from the major disciplines into a learning roadmap.</div><div><br/></div><div>Developing a foundation of mental models is critical to making multidisciplinary connections.</div><div><br/></div><div>I'm so happy this exists and thankful to have found it.</div><div><br/></div><div>My goal is to apply theory to real life and share during community discussions and deep dives.</div><div><br/></div><div>I'm excited to continue the infinite game of learning.</div>`

export default new Reflection({
  html,
  id: 'the-latticework',
  tags: [COMMUNITY],
  title,
  words: 92,
  written: '1/18/22'
})
