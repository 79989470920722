import Reflection from '@/stores/models/reflection'
import {SYSTEMS} from '@/tags'

const title = 'Single Points of Failure'
const html = `<div>I've been studying system design for software, and one of the main patterns to avoid is having a single point of failure.</div><div><br/></div><div>The saying "Two is one and one is none" seems ridiculous but it's practical. </div><div><br/></div><div>In distributed systems, you never want to rely on one database or server.</div><div><br/></div><div>Any component can break.</div><div><br/></div><div>Build fault tolerance into your systems, both in software and in life.</div>`

export default new Reflection({
  html,
  id: 'single-points-of-failure',
  tags: [SYSTEMS],
  title,
  words: 65,
  written: '9/19/23'
})
