import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Image } from '@/components'

import './KeelingCurvePage.scss'

export default class KeelingCurvePage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render () {
    return (
      <div className='KeelingCurvePage'>
        <div className='KeelingCurvePage__keelingCurve'>
          <div className='KeelingCurvePage__keelingCurve__text'>
            <p>If you're not familiar with the graph below, it is called the Keeling Curve. It shows the carbon dioxide concentration in the atmosphere since measurements began in 1958 at the Mauna Loa Observatory in Hawaii.</p>
            <Image src='https://scripps.ucsd.edu/programs/keelingcurve/wp-content/plugins/sio-bluemoon/graphs/mlo_full_record.png' alt='Keeling Curve' />
            <div className='KeelingCurvePage__keelingCurve__source'>Source: https://scripps.ucsd.edu/programs/keelingcurve/wp-content/plugins/sio-bluemoon/graphs/mlo_full_record.png</div>
            <p>It may seem like a steady, cyclic rise, but scientists have approximated historical CO<sub>2</sub> concentrations using carbon dating on air bubbles trapped in ice columns.</p>
            <p>For comparison, this second graph dates back <strong>10,000 years</strong>.</p>
            <Image src='https://scripps.ucsd.edu/programs/keelingcurve/wp-content/plugins/sio-bluemoon/graphs/co2_10k.png' alt='Keeling Curve 10,000 years' />
            <div className='KeelingCurvePage__keelingCurve__source'>Source: https://scripps.ucsd.edu/programs/keelingcurve/wp-content/plugins/sio-bluemoon/graphs/co2_10k.png</div>
            <p>That spike on the right side of the chart is the last 150 years since the Industrial Revolution.</p>
            <p>Evidence that dates as far back as <a target='_blank' href='http://news.bbc.co.uk/2/hi/science/nature/5314592.stm'>800,000 years</a> has shown similar results.</p>
            <p>In a mere century and a half — a blip of time on the cosmic scale — we have managed to release a colossal amount of stored energy from the sun (in the form of coal, oil, natural gas, wood, etc.) into the atmosphere.</p>
            <p>We have emitted over 375 billion tons of carbon as CO<sub>2</sub>.</p>
            <p>Reversing the human-induced effects of greenhouse gases is the single greatest challenge of our time.</p>
            <p>It’s our generation’s responsibility to fulfill this task.</p>
            <p>Thanks for reading,</p>
            <p>Zach</p>
            <p><Link to='/'><i className='fas fa-arrow-left' />&nbsp;Back to Home Page</Link></p>
          </div>
        </div>
      </div>
    )
  }
}
