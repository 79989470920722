import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'Distributed Work'
const html = `<div>Most jobs can be done from anywhere.</div><div><br/></div><div>Why should companies pay exorbitant rents when they can instead invest more into their businesses?</div><div><br/></div><div>As real estate costs continue to rise, the business case for office space will diminish.</div><div><br/></div><div>Time in the office does not equal output.</div><div><br/></div><div>How often do coworkers actually need to meet face to face?</div><div><br/></div><div>Most meetings are a waste time.</div><div><br/></div><div>In-person meetings are useful for developing trust and camaraderie, but they don’t have to happen everyday.</div><div><br/></div><div><a href="https://automattic.com/">Automattic</a>, a fully distributed company with ~1200 employees as of this post, hosts one “Grand Meetup” and at least one team meetup per year.</div><div><br/></div><div>A few gatherings per year will suffice.</div><div><br/></div><div>Digital tools enable productivity from anywhere.</div><div><br/></div><div>There are several other reasons why remote work is more productive.</div><div><br/></div><div>Working from home removes distractions and allows <a href="https://www.calnewport.com/blog/2012/11/21/knowledge-workers-are-bad-at-working-and-heres-what-to-do-about-it/">deep work</a>.</div><div><br/></div><div>Written communication results in clearer thinking.</div><div><br/></div><div>Removing the location requirement broadens access to talent.</div><div><br/></div><div>No commute means less stress and more time.</div><div><br/></div><div>The future of work is distributed.</div>`

export default new Reflection({
  html,
  id: 'distributed-work',
  tags: [BUSINESS],
  title,
  words: 164,
  written: '4/28/20'
})
