import Reflection from '@/stores/models/reflection'

const title = 'ChatGPT'
const html = `<div>OpenAI released <a href="https://openai.com/blog/chatgpt/" rev="en_rl_none">ChatGPT</a> a couple weeks ago, and it already took the world by storm.</div><div><br/></div><div>You have to experiment with it to believe in its capabilities.</div><div><br/></div><div>I've asked it to write poems, explanations of Maxwell's equations, and a Seinfeld scene.</div><div><br/></div><div>All the prompts it can answer and the creative output it generates never cease to amaze me.</div><div><br/></div><div>Large language models like ChatGPT will have a profound effect on the world.</div><div><br/></div><div>This release was just a preview of what's to come.</div>`

export default new Reflection({
  html,
  id: 'chatgpt',
  tags: null,
  title,
  words: 80,
  written: '12/13/22'
})
