import Reflection from '@/stores/models/reflection'

const title = 'Prix Fixe'
const html = `<div>I went to the restaurant Ad Hoc this weekend in Yountville, CA.</div><div><br/></div><div>It was the first time I'd been to a restaurant that doesn't present you with options, and it was a great experience.</div><div><br/></div><div>They have a preset four-course menu that changes daily.</div><div><br/></div><div>Imagine a world where most restaurants had a similar format.</div><div><br/></div><div>Instead of chefs churning out dozens of different recipes, they can focus on a few exceptional ones.</div><div><br/></div><div>Like omakase.</div><div><br/></div><div>I'd love to see this format become more popular.</div>`

export default new Reflection({
  html,
  id: 'prix-fixe',
  tags: null,
  title,
  words: 81,
  written: '2/28/23'
})
