import Reflection from '@/stores/models/reflection'

const title = `"Gym Respect"`
const html = '<div>I used to lift weights about 5-6 days a week in college.</div><div><br /></div><div>When you maintain a routine schedule, you start to notice familiar faces.</div><div><br /></div><div>My friend Ray is pretty gregarious, and he became friends with a lot of the regulars at the campus gym.</div><div><br /></div><div>He coined a term, “gym respect,” to refer to the mutual acknowledgment among the weightlifters.</div><div><br /></div><div>Every person who begins their weightlifting journey understands what it takes to get stronger.</div><div><br /></div><div>The grind. The commitment. Day in and day out.</div><div><br /></div><div>Nobody sets foot in the gym for the first time and benches 225 lb.</div><div><br /></div><div>When I first encountered a weight room, I was a skinny soccer player in high school, struggling to hold up 95 lb.</div><div><br /></div><div>We all start somewhere.</div><div><br /></div><div>I love the concept of “gym respect” because it’s about acknowledging each other for showing up to improve ourselves in some way.</div><div><br /></div><div>I believe that in a world where everyone strives to be their best self (beyond weightlifting) and encourages others as well, we are all better off.</div><div><br /></div><div>We should uplift each other.</div><div><br /></div><div>A rising tide lifts all boats.</div>'

export default new Reflection({
  html,
  id: 'gym-respect',
  tags: null,
  title,
  words: 182,
  written: '8/20/19'
})
