import Reflection from '@/stores/models/reflection'

const title = 'GPT-4'
const html = `<div>3 months ago, I <a href="https://zachgallagher.com/reflections/2022/chatgpt">reflected</a> on the original release of ChatGPT.</div><div><br/></div><div>GPT-4 came out last week, and it's ability to write code is unbelievable.</div><div><br/></div><div>I used it to write a program that converts an Evernote exported note (.enex file) into the HTML snippet that I use on this page.</div><div><br/></div><div>With one prompt, it output the entire program that worked like a charm on the first try.</div><div><br/></div><div>It can handle 25,000 word inputs, whereas the previous model allowed 8,000.</div><div><br/></div><div>Leveraging AI is a cheat code for life, like <a href="https://en.wikipedia.org/wiki/GameShark">GameShark</a>.</div>`

export default new Reflection({
  html,
  id: 'gpt-4',
  tags: null,
  title,
  words: 88,
  written: '3/21/23'
})
