import Reflection from '@/stores/models/reflection'

const title = 'Logitech'
const html = `<div>As a software engineer, I spend most of my day on a computer.</div><div><br/></div><div>My mechanical keyboard brings me so much joy.</div><div><br/></div><div>The audible clicking sound.</div><div><br/></div><div>The tactile feedback on the keys.</div><div><br/></div><div>The customizable backlight.</div><div><br/></div><div>Your work environment affects your energy, so invest in things that improve your workflow.</div><div><br/></div><div>The little things make all the difference.</div>`

export default new Reflection({
  html,
  id: 'logitech',
  tags: null,
  title,
  words: 55,
  written: '9/7/21'
})
