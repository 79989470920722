import Reflection from '@/stores/models/reflection'
import {PROGRAMMING} from '@/tags'

const title = 'Debug Logs'
const html = `<div>With VR app development, some tests require full app builds.</div><div><br/></div><div>As a result, the feedback delay between writing a line of code and testing it can be hours.</div><div><br/></div><div>If a feature doesn't work the way you expected, you'll have to check the logs to see what happened.</div><div><br/></div><div>Forgetting to add debug logs can set back your development workflow.</div><div><br/></div><div>Log everything you need the first time, so you don't have to wait for the next build.</div>`

export default new Reflection({
  html,
  id: 'debug-logs',
  tags: [PROGRAMMING],
  title,
  words: 75,
  written: '5/24/22'
})
