import Reflection from '@/stores/models/reflection'

const title = 'Beavis and Butt-Head'
const html = `<div>I just watched the new Beavis and Butt-Head movie, the sequel to the one in 1996.</div><div><br/></div><div>Something about those two characters is absolutely hysterical.</div><div><br/></div><div>I laugh by just looking at them.</div><div><br/></div><div>The humor may not be everyone's type, especially in today's world where people take things so seriously.</div><div><br/></div><div>It's good to be able to laugh at crude humor that has no agenda except to provide joy.</div>`

export default new Reflection({
  html,
  id: 'beavis-and-butthead',
  tags: null,
  title,
  words: 66,
  written: '12/27/22'
})
