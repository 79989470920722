import Reflection from '@/stores/models/reflection'

import {
  HEALTH
} from '@/tags'

const title = 'Recharging'
const html = `<div>Taking time off is invaluable for long-term success.</div><div><br/></div><div>Breaks of different time scales have wonderful effects.</div><div><br/></div><div>Taking a single deep belly breath activates the parasympathetic nervous system, which rests your heart rate and calms the mind.</div><div><br/></div><div>A 10 minute meditation can restore mental balance.</div><div><br/></div><div>Getting 8 hours of sleep is the single most effective method to improve your well-being.</div><div><br/></div><div>Holiday vacations open up the time and space for you to review priorities, your current path, and your goals.</div><div><br/></div><div>Prioritize rest.</div><div><br/></div><div>Don’t burn out.</div><div><br/></div><div>Schedule downtime.</div>`

export default new Reflection({
  html,
  id: 'recharging',
  tags: [HEALTH],
  title,
  words: 87,
  written: '12/23/19'
})
