import Reflection from '@/stores/models/reflection'

const title = 'Change of Fortune'
const html = `<div>"One day you're the rooster, the next you're the feather duster."</div><div><br/></div><div>That's one of my brother's favorite quotes.</div><div><br/></div><div>This past Sunday was a reminder that fortunes can change at any moment.</div><div><br/></div><div>My mom competed in the half Ironman 70.3 in Chattanooga, TN, and won her age group.</div><div><br/></div><div>On her way back to the hotel she got into a bike accident and broke her clavicle…</div><div><br/></div><div>One moment at the top of a podium, and the next at the emergency room.</div><div><br/></div><div>Don't take the good times for granted.</div>`

export default new Reflection({
  html,
  id: 'change-of-fortune',
  tags: null,
  title,
  words: 86,
  written: '5/23/23'
})
