import Reflection from '@/stores/models/reflection'
import {BLOCKCHAIN} from '@/tags'

const title = 'BAYC Otherside Mint'
const html = `<div>YugaLabs sold $320 million worth of their Bored Ape Yacht Club "Otherdeeds" NFTs this past weekend.</div><div><br/></div><div>However, customers collectively wasted $100 million worth of ETH in gas fees because the developers didn't optimize the smart contract.</div><div><br/></div><div>Mistakes like this make it clear just how early it still is for web3.</div><div><br/></div><div>Crypto whales and speculators may be willing to burn thousands per transaction, but everyone else in the world isn't.</div><div><br/></div><div>For blockchain technology to succeed against incumbent web2 companies, transaction prices will have to be negligible.</div>`

export default new Reflection({
  html,
  id: 'bayc-otherside-mint',
  tags: [BLOCKCHAIN],
  title,
  words: 85,
  written: '5/3/22'
})
