import Reflection from '@/stores/models/reflection'

const title = 'I Am That'
const html = `<div>Sri Nisargadatta Maharaj's 1973 book <i>I Am That</i> reiterated the same ideas for over 500 pages.</div><div><br/></div><div>You can only describe yourself as a negation: I am not this, I am not that.</div><div><br/></div><div>You're not your mind or your body.</div><div><br/></div><div>Your true nature is a spaceless, timeless awareness that cannot be described in words, but only experienced.</div><div><br/></div><div>Words are merely symbols that do not convey the truth.</div><div><br/></div><div>Detach yourself from your identity as a person with memories and hopes.</div><div><br/></div><div>Desire and fear lead to suffering.</div><div><br/></div><div>Wisdom is knowing you are ignorant.</div><div><br/></div><div>I don't necessarily agree with all of his ideas, but they can be practical in some circumstances.</div>`

export default new Reflection({
  html,
  id: 'i-am-that',
  tags: null,
  title,
  words: 107,
  written: '12/7/21'
})
