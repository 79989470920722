import Reflection from '@/stores/models/reflection'

const title = 'Twenties'
const html = `<div>This is the last week of my twenties.</div><div><br/></div><div>In 2014, my friend James recommended that I read <i>The Defining Decade</i>, a book about how your twenties are the most pivotal years of your life.</div><div><br/></div><div>I was 22 at the time, had just graduated college, and had no idea what I wanted to do with my career.</div><div><br/></div><div>Even though I had a degree in mechanical engineering, I felt blocked by a massive obstacle, my anxiety.</div><div><br/></div><div>I'd avoided it since I started having panic attacks in high school.</div><div><br/></div><div>I was terrified of my mind's ability to dissociate and I thought something was wrong with me.</div><div><br/></div><div>It didn't seem possible to overcome.</div><div><br/></div><div>To make matters worse, I also had a chronic back injury that affected me much more than my mental health problems.</div><div><br/></div><div>My twenties was my journey through these two challenges.</div><div><br/></div><div>Through years of yoga practice, reading, meditation, and overall self-care, I've overcome them and I continue to improve.</div><div><br/></div><div>I wake up everyday with no back pain and I absolutely do not take that for granted.</div><div><br/></div><div>I took 3 improv comedy classes, and although I was probably embarrassingly bad, the experience was a win over my fear of public speaking.</div><div><br/></div><div>When I look back at my twenties, I'm incredibly grateful for my life now.</div><div><br/></div><div>A day doesn't go by where I don't have gratitude for my health and well-being.</div><div><br/></div><div>If there's one thing I learned over my twenties, it's to never give up, no matter the circumstances.</div>`

export default new Reflection({
  html,
  id: 'twenties',
  tags: null,
  title,
  words: 245,
  written: '2/15/22'
})
