import Reflection from '@/stores/models/reflection'

const title = 'Scout Mindset'
const html = `<div>I highly recommend Julia Galef's book <i>The Scout Mindset</i>.</div><div><br/></div><div>She defines a scout mindset as the motivation to see things as they are, not as you wish they were.</div><div><br/></div><div>The opposite is a soldier mindset, where unconscious motivated reasoning leads us to defend our beliefs.</div><div><br/></div><div>One of my favorite frameworks is the "Can I believe it?" vs. "Must I believe it?" dichotomy, which she attributes to the psychologist Tom Gilovich.</div><div><br/></div><div>We tend to ask ourselves "Can I believe this?" with information that aligns with our existing beliefs, but we ask "Must I believe this?" with opposing information.</div><div><br/></div><div>Both questions are signs of a soldier mindset.</div><div><br/></div><div>A scout would simply ask, "Is it true?"</div><div><br/></div><div>Galef also differentiates two types of confidence: epistemic and social.</div><div><br/></div><div>Epistemic confidence is your certainty about what's true.</div><div><br/></div><div>Social confidence is everything else: self-assuredness.</div><div><br/></div><div>People judge you on social confidence.</div><div><br/></div><div>You can be uncertain about the future while still being socially confident.</div><div><br/></div><div>The universe is complex and there's too much information available to know all the facts, so having 100% epistemic confidence about the future is impossible.</div><div><br/></div><div>I strive to have a scout mindset and to be self-aware enough to know when a soldier mindset starts creeping in.</div>`

export default new Reflection({
  html,
  id: 'scout-mindset',
  tags: null,
  title,
  words: 201,
  written: '6/21/22'
})
