import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import cx from 'classnames'

import './Header.scss'

const headerItems = [
  {
    text: 'Now',
    to: '/now'
  }
  /* {
    text: 'Blog',
    to: '/blog'
  }, */
  // {
  //   text: 'Notes',
  //   to: '/notes'
  // },
  // {
  //   text: 'Reflections',
  //   to: '/reflections'
  // }
]

class Header extends Component {
  openMobileSideNav = () => {
    const { openMobileSideNav } = this.props
    openMobileSideNav()
  }
  render () {
    const { location } = this.props
    const { pathname } = location
    return (
      <div className='Header'>
        <div className='Header__name'>
          <Link to='/'>Zach Gallagher</Link>
        </div>
        <div className='Header__right'>
          {headerItems.map(item => <Link key={`Header-${item.to}`} className={cx({ 'is-active': pathname === item.to })} to={item.to}>{item.text}</Link>)}
        </div>
        <div className='Header__hamburger'>
          <i className='fas fa-bars' onClick={this.openMobileSideNav} />
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
