import Reflection from '@/stores/models/reflection'

const title = 'Solitude'
const html = `<div>There are nearly 8 billion people on Earth, and half live on only 1% of the land.</div><div><br/></div><div>Finding a quiet place to be alone with your thoughts is not always easy.</div><div><br/></div><div>When you do have space to yourself, disconnecting from the internet is also critical.</div><div><br/></div><div>Solitude isn’t just physical separation.</div><div><br/></div><div>Phones are instant hits of dopamine that lure us into never-ending scrolling.</div><div><br/></div><div>Unplugging from the world gives us the mental space to investigate who we really are.</div><div><br/></div><div>We shift attention away from ourselves when we're caught up in day-to-day worldly affairs.</div><div><br/></div><div>All external information has a purpose – to teach you, to sell to you, to entertain you, to help you, to deceive you, etc.</div><div><br/></div><div>Watching a biased broadcast news channel is like dissolving into a massive crowd.</div><div><br/></div><div>Decision makers filter their messaging through their motives, forcing viewers into tunnel vision.</div><div><br/></div><div>If you’re always in a group, <a href="https://zachgallagher.com/reflections/2019/crowd-folly">crowd folly</a> can entrench you.</div><div><br/></div><div>Until you tune out, you will always be reacting.</div><div><br/></div><div>Without extended time away from others, independent thought is impossible.</div><div><br/></div><div>Introverts like myself enjoy solitude, but everyone can benefit from introspection.</div><div><br/></div><div>Nobody has the same vantage point as you.</div><div><br/></div><div>Develop your unique perspective.</div><div><br/></div><div>Spend time alone.</div>`

export default new Reflection({
  html,
  id: 'solitude',
  tags: null,
  title,
  words: 199,
  written: '5/26/20'
})
