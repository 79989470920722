import Reflection from '@/stores/models/reflection'

const title = 'Better Left Unsaid'
const html = `<div>Sometimes people say things just to fill the silence.</div><div><br/></div><div>Or they just blurt out thoughts without realizing why they're speaking.</div><div><br/></div><div>Communication should have a purpose — implicit or explicit.</div><div><br/></div><div>If what you say doesn't align with your intention, it's better to remain silent.</div>`

export default new Reflection({
  html,
  id: 'better-left-unsaid',
  tags: null,
  title,
  words: 43,
  written: '9/12/23'
})
