import Reflection from '@/stores/models/reflection'

const title = 'Boredom'
const html = `<div>Curiosity cures boredom.</div><div><br/></div><div>There's an infinite amount of things to do and learn, and not enough time to get to everything.</div><div><br/></div><div>If you feel listless and unmotivated, just find something you're interested in and pursue it.</div><div><br/></div><div>Intrinsic motivation is key.</div>`

export default new Reflection({
  html,
  id: 'boredom',
  tags: null,
  title,
  words: 40,
  written: '9/27/22'
})
