import React, { Component } from 'react'
import {
  // Link,
  withRouter
} from 'react-router-dom'

import './Footer.scss'

const CURRENT_YEAR = new Date().getFullYear()

class Footer extends Component {
  renderButterAttributionLink () {
    const { location } = this.props
    const { pathname } = location
    const isBlogPage = pathname.substring(1, 5) === 'blog'
    if (!isBlogPage) return null
    // Email nikki@buttercms.com to get free personal plan
    return (
      <div className='Footer__butterCMS'>
        <span>Blog powered by&nbsp;&nbsp;<a href='https://buttercms.com' target='_blank'><img src='https://cdn.buttercms.com/PGJPyIwaQ2KnOA8UyKfH' alt='Butter CMS' /></a></span>
      </div>
    )
  }
  // <div className='Footer__contact__text'><Link to='/contact'>Contact</Link></div>
  render () {
    return (
      <div className='Footer'>
        <div className='Footer__contact'>
          {/* <a target='_blank' href='https://twitter.com/zachagallagher'><i className='fab fa-twitter' /></a>
          <a target='_blank' href='https://www.instagram.com/zachagallagher/'><i className='fab fa-instagram' /></a>
          <a target='_blank' href='https://www.linkedin.com/in/zachagallagher/'><i className='fab fa-linkedin' /></a>
          <a target='_blank' href='https://github.com/zgallagher08'><i className='fab fa-github' /></a> */}
        </div>
        {this.renderButterAttributionLink()}
        <div className='Footer__copyright'>
          <span>
            &copy;&nbsp;{CURRENT_YEAR}&nbsp;
            <span className='Footer__copyright__initials'>ZG</span>
            <span className='Footer__copyright__name'>Zach Gallagher</span>
          </span>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
