import Reflection from '@/stores/models/reflection'

const title = 'Minimum Effective Dose'
const html = `<div>What's the minimum amount you need to do to achieve your desired goal?</div><div><br/></div><div>What's the marginal cost and return of doing more?</div><div><br/></div><div>If you commit to something, take at least the minimum effective dose before you come to any conclusions.</div>`

export default new Reflection({
  html,
  id: 'minimum-effective-dose',
  tags: null,
  title,
  words: 40,
  written: '3/28/23'
})
