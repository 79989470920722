import Reflection from '@/stores/models/reflection'

const title = 'Tetris'
const html = `<div>A coworker of mine speaks with such passion about video games that his joy is contagious.</div><div><br/></div><div>He raved about the Tetris movie that just came out in March and said I had to watch it.</div><div><br/></div><div>The story of Alexey Pajitnov creating Tetris in the Soviet Union and Henk Rogers discovering it and attempting to distribute it worldwide are remarkable.</div><div><br/></div><div>The original Nintendo Game Boy makes an appearance in the movie, and it reminded me of how much time I spent as a kid playing on Game Boy consoles.</div><div><br/></div><div>I used to be a huge gamer, but the last console I bought was the Xbox 360 in 2005.</div><div><br/></div><div>Games put you into a state of flow where you're so immersed, everything else disappears.</div><div><br/></div><div>The game doesn't even have to be complex.</div><div><br/></div><div>It's incredible what a simple game can do.</div>`

export default new Reflection({
  html,
  id: 'tetris',
  tags: null,
  title,
  words: 138,
  written: '5/2/23'
})
