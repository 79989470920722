import Reflection from '@/stores/models/reflection'

const title = 'Escapism'
const html = `<div>People distract themselves from reality in all sorts of ways.</div><div><br/></div><div>TV, movies, video games, social media — these are the usual suspects.</div><div><br/></div><div>Drugs and alcohol are darker options.</div><div><br/></div><div>Even reading can be a form of escapism.</div><div><br/></div><div>I'd like to think that I always confront reality head on and make the most of my time, but that would be a delusion.</div><div><br/></div><div>Staying present in every moment is one of life's greatest challenges.</div><div><br/></div><div>When you want to distract yourself, ask yourself why.</div><div><br/></div><div>What feeling are you avoiding?</div>`

export default new Reflection({
  html,
  id: 'escapism',
  tags: null,
  title,
  words: 85,
  written: '1/24/23'
})
