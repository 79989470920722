import Reflection from '@/stores/models/reflection'

const title = 'Facing Fears'
const html = `<div>This past week was the first of a three week yoga teacher training in Germany.</div><div><br/></div><div>I've had a public speaking phobia my whole life, and it became severe after I started having panic attacks in high school.</div><div><br/></div><div>I confronted it and overcome a lot by taking improv comedy classes at Upright Citizens Brigade in LA, but the fear has still limited me from living a free, authentic life.</div><div><br/></div><div>I went to my first yoga class in 2014, and over the years yoga has become a central part of my life.</div><div><br/></div><div>When I go to yoga classes, I sometimes imagine myself as the instructor, but the thought of performing has made me feel anxious.</div><div><br/></div><div>I'm writing this reflection at Seminarhaus Holzmannstett in Pfaffing, Germany, where I'm learning how to teach along with 28 other aspiring teachers.</div><div><br/></div><div>We're already a close-knit group, and I'm confident that we'll all grow substantially in the next couple weeks.</div><div><br/></div><div>My mindset is to jump into the fear, to feel it and act anyway.</div>`

export default new Reflection({
  html,
  id: 'facing-fears',
  tags: null,
  title,
  words: 167,
  written: '6/19/23'
})
