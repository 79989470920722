import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Image, Loader } from '@/components'
import { getMonthYearStr } from '@/utils/helpers'
import { Helmet } from 'react-helmet'
import { contentStoreOperations } from '@/stores/contentStore'

import './BlogPostPage.scss'

const { getBlogPost } = contentStoreOperations

class BlogPostPage extends Component {
  state = {
    isLoadingBlogPost: false,
    slug: null
  }
  componentDidMount () {
    window.scrollTo(0, 0)
    const {
      blogPosts,
      dispatch,
      match
    } = this.props
    const { params } = match
    const { slug } = params
    this.setState({ slug })
    const hasCachedBlogPost = blogPosts && blogPosts[slug] && blogPosts[slug].body
    window.scrollTo(0, 0)
    if (!hasCachedBlogPost) {
      this.setState({ isLoadingBlogPost: true }, () => {
        getBlogPost(slug, dispatch).then(() => {
          this.setState({ isLoadingBlogPost: false })
          this.addTargetBlanks()
        }).catch(err => {
          console.log('Error fetching blog post', err)
          this.setState({ isLoadingBlogPost: false })
        })
      })
    }
  }
  addTargetBlanks = () => {
    if (this.$blogPost) {
      const aTags = this.$blogPost.querySelectorAll('a')
      let i = 0
      for (; i < aTags.length; i++) {
        aTags[i].setAttribute('target', '_blank')
      }
    }
  }
  setRef = (el) => {
    const { blogPosts, match } = this.props
    const { params } = match
    const { slug } = params
    const hasCachedBlogPost = blogPosts && blogPosts[slug] && blogPosts[slug].body
    this.$blogPost = el
    if (hasCachedBlogPost) {
      this.addTargetBlanks()
    }
  }
  renderBlogPost () {
    const {
      blogPosts,
      location,
      match
    } = this.props
    const { pathname } = location
    const { params } = match
    const { slug } = params
    const {
      body,
      featuredImage,
      published,
      summary,
      title
    } = blogPosts[slug]
    return (
      <div className='BlogPostPage__content'>
        <Helmet>
          <meta name='description' content={summary} />
          <meta property='og:description' content={summary} />
          <meta property='og:title' content={`${title} – Zach Gallagher`} />
          <meta property='og:url' content={`https://zachgallagher.com${pathname}`} />
          <meta name='twitter:description' content={summary} />
          <meta name='twitter:image' content={featuredImage} />
          <meta name='twitter:title' content={`${title} – Zach Gallagher`} />
        </Helmet>
        <Image
          alt={`Image for ${title}`}
          src={featuredImage}
        />
        <h1>{title}</h1>
        <div className='BlogPostPage__content__date'>{getMonthYearStr(published)}</div>
        <hr />
        <div
          className='BlogPostPage__content__body'
          dangerouslySetInnerHTML={{ __html: body }}
          ref={this.setRef}
        />
      </div>
    )
  }
  renderNotFound () {
    return (
      <div className='BlogPostPage__empty'>
        There was an error fetching the blog post content.
      </div>
    )
  }
  render () {
    const { blogPosts } = this.props
    const { isLoadingBlogPost, slug } = this.state
    const hasCachedBlogPost = blogPosts && blogPosts[slug] && blogPosts[slug].body
    return (
      <div className='BlogPostPage'>
        {isLoadingBlogPost ? (
          <Loader />
        ) : (
          hasCachedBlogPost ? this.renderBlogPost() : this.renderNotFound()
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ contentStore }) => {
  const { blogPosts } = contentStore
  return { blogPosts }
}

export default connect(mapStateToProps)(BlogPostPage)
