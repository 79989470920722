import Reflection from '@/stores/models/reflection'

const title = 'Social Fatigue'
const html = `<div>This past Saturday, I went to 2 social events back-to-back.</div><div><br/></div><div>I was exhausted by the end.</div><div><br/></div><div>As an introvert, I lose energy by being around a lot of people and I recharge by being alone.</div><div><br/></div><div>I've worked from home for the past 2 years, so I'm probably more sensitive to big groups than usual.</div><div><br/></div><div>Loud music made the day even more draining, since I had to raise my voice to have a conversation.</div><div><br/></div><div>Extroverts probably feel the opposite.</div><div><br/></div><div>They suffered during the pandemic, isolated and unable to have everyday human interactions.</div><div><br/></div><div>People adapt differently to their environment.</div><div><br/></div><div>It's important to have the self-awareness to know what gives you energy and what depletes it.</div>`

export default new Reflection({
  html,
  id: 'social-fatigue',
  tags: null,
  title,
  words: 113,
  written: '12/21/21'
})
