import Reflection from '@/stores/models/reflection'

const title = 'Perfectionism'
const html = `<div>There's no such thing as perfect.</div><div><br/></div><div>Even if you reach a peak there will always be a taller mountain to climb in the distance.</div><div><br/></div><div>Striving for perfection can be an inspiring goal, but we have accept each moment in stride.</div><div><br/></div><div>Everything comes to an end, so while continuous growth is great in theory, it's impossible in practice.</div><div><br/></div><div>Life is about balance, so while you may be seeking perfection in one area you can be damaging another, like a relationship or your health.</div><div><br/></div><div>We've all struggled with being imperfect.</div><div><br/></div><div>When I was growing up, I was too hard on myself because I thought my face was imperfect.</div><div><br/></div><div>Why do we want perfection?</div><div><br/></div><div>Is there something we think will happen if we temporarily achieve it?</div><div><br/></div><div>There is beauty in imperfection.</div>`

export default new Reflection({
  html,
  id: 'perfectionism',
  tags: null,
  title,
  words: 128,
  written: '3/2/21'
})
