import Reflection from '@/stores/models/reflection'

const title = 'Slowing Down'
const html = `<div>Life seems to be going by faster.</div><div><br/></div><div>Don't rush to get where you want to go.</div><div><br/></div><div>Enjoy this moment.</div><div><br/></div><div>Breathe.</div>`

export default new Reflection({
  html,
  id: 'slowing-down',
  tags: null,
  title,
  words: 20,
  written: '1/4/22'
})
