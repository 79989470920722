import Reflection from '@/stores/models/reflection'

const title = 'Threads'
const html = `<div>Last week, Meta released Threads on mobile, and within 5 days, it reached 100 million sign-ups.</div><div><br/></div><div>Network effects are real.</div><div><br/></div><div>The Threads team started building the app this year, so they went from 0 to launching an MVP in 6 months.</div><div><br/></div><div>When you focus on improving and building every day, success can happen all at once.</div><div><br/></div><div>Of course, Threads has an unfair advantage by leveraging Instagram, but the principle is still true.</div><div><br/></div><div>Just as success can happen in an instant, so can failure.</div><div><br/></div><div>You need to be consistent, humble, and persevere.</div>`

export default new Reflection({
  html,
  id: 'threads',
  tags: null,
  title,
  words: 91,
  written: '7/11/23'
})
