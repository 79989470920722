import Reflection from '@/stores/models/reflection'
import {BITCOIN, BLOCKCHAIN, CRYPTOCURRENCY} from '@/tags'

const title = 'Bitcoin'
const html = `<div>I first heard about Bitcoin in 2013, but I didn't start learning about the underlying technology until a few years later.</div><div><br/></div><div>I remember looking at the price on Coinbase as it rocketed toward $1000.</div><div><br/></div><div>We all wish we could go back in time to set a recurring buy order and then promptly forget it.</div><div><br/></div><div>As the Coinbase IPO is set for tomorrow, crypto companies are becoming more mainstream.</div><div><br/></div><div>There are a ton of BTC skeptics and fanatics, but I wouldn't classify myself as either.</div><div><br/></div><div>Software has a lifecycle, so a single cryptocurrency or token probably won't live forever.</div><div><br/></div><div>If Bitcoin or Ethereum are missing some key feature such as scalability or privacy, another application can replace them.</div><div><br/></div><div>Bitcoin will always be the first cryptocurrency, and Ethereum the first Turing complete one.</div><div><br/></div><div>Regardless of which coin or token will stand the test of time, decentralized software will dominate the web's future.</div>`

export default new Reflection({
  html,
  id: 'bitcoin',
  tags: [BITCOIN, BLOCKCHAIN, CRYPTOCURRENCY],
  title,
  words: 150,
  written: '4/13/21'
})
