import Reflection from '@/stores/models/reflection'
import { WRITING } from '@/tags'

const title = 'Passive Voice'
const html = `<div/><div>Most words are superfluous.</div><div><br/></div><div>I read the book <i>Revising Prose</i> by Richard Lanham a couple weeks ago after <a href="https://twitter.com/jasonfried">Jason Fried</a> recommended it.</div><div><br/></div><div>Lanham defines a funny metric called the Lard Factor (LF), the percent of useless words in a sentence.</div><div><br/></div><div>For example, consider the following:</div><div><br/></div><div><span>    </span>Sentences with passive voice cannot be easily understood through visualization.</div><div><br/></div><div>Now, the shortened active version:</div><div><br/></div><div><span>    </span>Readers cannot visualize passive sentences.</div><div><br/></div><div>The LF for this example is (10 - 5)/10 = 50%</div><div><br/></div><div>Lanham claims the typical LF is around 1/3 to 1/2.</div><div><br/></div><div>Since I read the book, I’ve noticed passive voice everywhere.</div><div><br/></div><div>I am more cognizant when writing, including formal emails and informal Slack messages.</div><div><br/></div><div>As an engineer, I haven't thought much about writing style.</div><div><br/></div><div>Content was more important than structure.</div><div><br/></div><div>I used to claim to have writer’s block, so I worried more about quantity than quality.</div><div><br/></div><div>Now I'm learning to let ideas flow onto the page, and revise later.</div><div><br/></div><div>The novelist Nathaniel Hawthorne once quipped, “easy reading is damn hard writing.”</div><div><br/></div><div>Revising complex ideas takes time.</div><div><br/></div><div>We write so others can comprehend.</div><div><br/></div><div>Remove words that do not communicate your idea.</div><div><br/></div><div>Portray reality, not abstraction.</div>`

export default new Reflection({
  html,
  id: 'passive-voice',
  tags: [WRITING],
  title,
  words: 190,
  written: '2/4/20'
})
