import Reflection from '@/stores/models/reflection'

const title = 'Figuring It Out'
const html = `<div>If you're not attempting something new, you're not learning.</div><div><br/></div><div>Most days at my job at Meta, I have to solve some problem I've never encountered before.</div><div><br/></div><div>Some days it's easy to find a solution.</div><div><br/></div><div>The internet, now including large language models, simplifies the search for an answer.</div><div><br/></div><div>On many days it takes more time, determination, and perseverance.</div><div><br/></div><div>I can get frustrated if something takes a long time to solve, but the most important thing is to never give up.</div><div><br/></div><div>You have to believe that you can eventually figure it out.</div>`

export default new Reflection({
  html,
  id: 'figuring-it-out',
  tags: null,
  title,
  words: 90,
  written: '8/1/23'
})
