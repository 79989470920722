import Reflection from '@/stores/models/reflection'
import {HEALTH} from '@/tags'

const title = 'Sleep'
const html = `<div>About a month ago, I watched my brother's new puppy for 3 days.</div><div><br/></div><div>After 2 nights of interrupted sleep, I was noticeably fatigued and lethargic.</div><div><br/></div><div>My mind was in a haze.</div><div><br/></div><div>All that from just a couple nights of poor sleep.</div><div><br/></div><div>I can't imagine what raising a baby must be like.</div><div><br/></div><div>I recently finished the book <i>Why We Sleep</i> by Matthew Walker, and I was surprised to learn how catastrophic it is to lose sleep.</div><div><br/></div><div>The U.S. recognizes sleep deprivation as an illegal form of torture.</div><div><br/></div><div>Even a seemingly negligible amount of sleep loss has an indisputable effect on health and performance.</div><div><br/></div><div>7 hours a night is not enough.</div><div><br/></div><div>If you don't get adequate sleep, you're depriving your brain and body of the benefits that sleep has given us over our evolutionary history.</div><div><br/></div><div>Logically, we are more vulnerable when we're asleep, so if we still require it for a third of our existence, it must be pretty damn important.</div><div><br/></div><div>So if you're reading this late at night, please put away the phone or computer, and get some shut-eye.</div>`

export default new Reflection({
  html,
  id: 'sleep',
  tags: [HEALTH],
  title,
  words: 178,
  written: '2/3/21'
})
