import Reflection from '@/stores/models/reflection'

const title = 'Pull-up Bars'
const html = `<div>Grip strength is an "indispensable biomarker" for health and longevity. <sup>[1]</sup></div><div><br/></div><div>Simply hanging from a pull-up bar stretches your shoulders, decompresses your spine, and strengthens your hands.</div><div><br/></div><div>Before March 2020, I'd do pull-ups at a local gym almost every day.</div><div><br/></div><div>I never restarted my gym membership because I decided to deepen my yoga practice instead.</div><div><br/></div><div>Since I haven't had as much access to a pull-up bar as I've liked, last week I installed one in my place.</div><div><br/></div><div>At less than $100, it was the best investment I've made this year.</div><div><br/></div><div>Just a couple steps and a jump away from my desk, it's super easy to just hang whenever I feel like it.</div><div><br/></div><div>Adding a few sets of pull-ups doesn't hurt too.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6778477/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6778477/</a></div>`

export default new Reflection({
  html,
  id: 'pull-up-bars',
  tags: null,
  title,
  words: 125,
  written: '6/7/22'
})
