import Reflection from '@/stores/models/reflection'

import { PHILOSOPHY } from '@/tags'

const title = 'Metaphors'
const html = '<div>Become aware of all of the sounds and colors around you at this very moment.</div><div><br /></div><div>Our brains require an immense amount of information processing to make sense of our surroundings.</div><div><br /></div><div>We filter the stream of information by giving names to forms and patterns.</div><div><br /></div><div>To understand and interact with the world, we use metaphors to represent the essence of things in reality.</div><div><br /></div><div>Through language, we tie together moments to represent our actual experiences.</div><div><br /></div><div>Once we make the transition from reality to language, we immediately lose almost all of the information.</div><div><br /></div><div>The map is not the territory.</div><div><br /></div><div>However, another powerful thing happens.</div><div><br /></div><div>Since language itself does not delineate reality precisely, the action of reading or listening creates a unique experience for every individual.</div><div><br /></div><div>The subject-object interaction between the reader/listener and language causes different experiences for every person.</div><div><br /></div><div>We all have unique perspectives and memories.</div><div><br /></div><div>One word, one smell, one sound, one taste, one touch - elicits different experiences for every individual.</div><div><br /></div><div>We view the world through incessant subconscious filters.</div><div><br /></div><div>If we look at the world with a beginner’s mind — without attachments to name and form — we open up the possibility of truly seeing reality, which is identical for universal consciousness.</div>'

export default new Reflection({
  html,
  id: 'metaphors',
  tags: [PHILOSOPHY],
  title,
  words: 199,
  written: '8/7/19'
})
