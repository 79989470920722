import Reflection from '@/stores/models/reflection'

const title = 'Go Positive and Go First'
const html = `<div>Earlier this year, I put a post-it note on my cupboard that says "Go positive and go first."</div><div><br/></div><div>I strive to live by this motto, which I first heard from Peter Kaufman in <a href="https://soundcloud.com/user-339685480/peter-kaufman-on-the-multi-disciplinary-approach-to-thinking">his talk</a> about the multidisciplinary approach to thinking.</div><div><br/></div><div>Peter Kaufman is the CEO of Glenair and editor of the book <i>Poor Charlie's Almanack</i>, which is one of the best books I've ever read.</div><div><br/></div><div>In the talk, he discusses how "mirrored reciprocation" can explain how everything in the universe works — from Newton's third law to human behavior.</div><div><br/></div><div>Since most people are risk-averse and want to avoid looking foolish, they tend not to initiate in social settings even though others will respond positively most of the time.</div><div><br/></div><div>The post-it note occasionally reminds me, and it cannot be said enough.</div><div><br/></div><div>"Go positive and go first!"</div>`

export default new Reflection({
  html,
  id: 'go-positive-and-go-first',
  tags: null,
  title,
  words: 136,
  written: '9/5/23'
})
