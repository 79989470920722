import reducer from './reducers'

import uiStoreActions from './actions'
import uiStoreOperations from './operations'
import uiStoreSelectors from './selectors'
import uiStoreTypes from './types'

export {
  uiStoreActions,
  uiStoreOperations,
  uiStoreSelectors,
  uiStoreTypes
}

export default reducer
