import Reflection from '@/stores/models/reflection'

const title = 'Listening'
const html = `<div>Feeling unheard is uniquely painful.</div><div><br/></div><div>It’s human nature to have a voice, whether spoken or written.</div><div><br/></div><div>The lack of communication in the world today causes hate and suffering.</div><div><br/></div><div>Empathy is impossible without listening.</div><div><br/></div><div>Celeste Headlee, who I mentioned in my reflection about <a href="https://zachgallagher.com/reflections/2019/conviction">conviction</a>, delivered a great <a href="https://www.ted.com/talks/celeste_headlee_10_ways_to_have_a_better_conversation">TEDx talk</a> about 10 ways to have a better conversation.</div><div><br/></div><div>I am copying Celeste's 10 ways below as a reminder to be a better communicator.</div><div><br/></div><ol><li><div>Don't multitask.</div></li><li><div>Don't pontificate.</div></li><li><div>Use open-ended questions.</div></li><li><div>Go with the flow.</div></li><li><div>If you don't know, say you don't know.</div></li><li><div>Don't equate your experience with theirs.</div></li><li><div>Try not to repeat yourself.</div></li><li><div>Stay out of the weeds.</div></li><li><div>Listen.</div></li><li><div>Be brief.<br/></div></li></ol><div><br/></div><div>Although “Listen” is #9 on the list, all ten are crucial to giving others the feeling of being heard.</div><div><br/></div><div>We can all do better.</div><div><br/></div><div>Be truly present and engaged with others.</div><div><br/></div><div>Listening is active, not passive.</div><div/>`

export default new Reflection({
  html,
  id: 'listening',
  tags: null,
  title,
  words: 146,
  written: '7/21/20'
})
