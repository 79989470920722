import Reflection from '@/stores/models/reflection'
import {INVESTING} from '@/tags'

const title = 'Exponentials'
const html = `<div>Improve 1% a day for a year and you are over 37 times better.</div><div><br/></div><div>Decline 1% a day for a year and you end up at less than 3% of your initial level. <sup>[1]</sup></div><div><br/></div><div>This simple math is why <a href="https://zachgallagher.com/reflections/2020/habits">habits</a> are so important.</div><div><br/></div><div><i>    “The greatest shortcoming of the human race is our inability to understand the exponential function.”</i></div><div>    – Albert Allen Bartlett, professor of physics at the University of Colorado at Boulder</div><div><br/></div><div>Bartlett, who was referring to the negative aspect of exponentials regarding population growth and civilization’s unsustainability, believed "sustainable growth" is an oxymoron.</div><div><br/></div><div>Although I agree with him on our collective need to correct course, I like to view exponentials in a positive light, like Albert Einstein.</div><div><br/></div><div>Einstein referred to compound interest as "the eighth wonder of the world. He who understands it, earns it … he who doesn’t … pays it."</div><div><br/></div><div>The choices you make today may seem small, but the long-term effects have far-reaching consequences.</div><div><br/></div><div>Don’t underestimate small changes.</div><div><br/></div><hr/><div>FOOTNOTES</div><div><br/></div><div><sup>[1]</sup> Here’s the math <a href="https://www.google.com/search?q=1.01%5E365&amp;oq=1.01%5E365">for improvement</a> and <a href="https://www.google.com/search?q=0.99%5E365&amp;oq=0.99%5E365">for decline</a>.</div>`

export default new Reflection({
  html,
  id: 'Exponentials',
  tags: [INVESTING],
  title,
  words: 170,
  written: '7/14/20'
})
