import React, { Component } from 'react'

import './NowPage.scss'

const LAST_UPDATED_DATE = 'December 11, 2023'

export default class NowPage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render () {
    // <p>I just added a new post about carbon taxes to my <Link to='/blog'>Blog</Link>, and I will continue to explore topics related to climate change to write about.</p>
    return (
      <div className='NowPage'>
        <h1>What I'm doing now</h1>
        <p>I develop new features for <a target='_blank' href='https://www.meta.com/horizon-worlds/'>Horizon Worlds</a>.</p>
        <p>{LAST_UPDATED_DATE}</p>
        <hr />
        <p>Hat tip to Derek Sivers for the <a target='_blank' href='https://sivers.org/nowff'>/now</a> page idea. </p>
      </div>
    )
  }
}
