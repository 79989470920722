/* eslint no-eval: 0 */
import React, { Component } from 'react'
import gtmParts from 'react-google-tag-manager'

export default class GoogleTagManager extends Component {
  componentDidMount () {
    const dataLayerName = this.props.dataLayerName || 'dataLayer'
    const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm'
    if (!window[dataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId)
      eval(gtmScriptNode.textContent)
    }
  }

  render () {
    const {
      additionalEvents,
      dataLayerName,
      gtmId,
      previewVariables,
      scriptId
    } = this.props
    const gtm = gtmParts({
      id: gtmId,
      dataLayerName: dataLayerName || 'dataLayer',
      additionalEvents: additionalEvents || {},
      previewVariables: previewVariables || false
    })
    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={scriptId || 'react-google-tag-manager-gtm'}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    )
  }
}
