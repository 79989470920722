import Reflection from '@/stores/models/reflection'

const title = 'DevX'
const html = `<div>I've been working on Horizon Worlds for over two and a half years now, and I've learned what good and bad developer experiences look like.</div><div><br/></div><div>Good devX accelerates your workflow.</div><div><br/></div><div>It's seamless, fast, and stays out of your way.</div><div><br/></div><div>Bad devX adds friction, becomes a bottleneck, and can ruin your day.</div><div><br/></div><div>Investing in developer tooling pays dividends in the long term, but it has an upfront cost that can be hard to justify.</div><div><br/></div><div>I can't overstate the importance of developer experience.</div>`

export default new Reflection({
  html,
  id: 'devx',
  tags: null,
  title,
  words: 81,
  written: '10/31/23'
})
