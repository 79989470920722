import Reflection from '@/stores/models/reflection'

const title = 'Ocean Swims'
const html = `<div>Some people love swimming in the ocean.</div><div><br/></div><div>I'm not one of them.</div><div><br/></div><div>Last Friday I went to Santa Monica with a group of triathletes to swim, and I realized ocean swims just aren't for me.</div><div><br/></div><div>And that's okay.</div><div><br/></div><div>Swimming laps in a pool can be meditative, but getting through the surf isn't.</div><div><br/></div><div>I love to do a ton of things to stay active, so I'll happily choose them over something I dislike.</div>`

export default new Reflection({
  html,
  id: 'ocean-swims',
  tags: null,
  title,
  words: 72,
  written: '7/5/22'
})
