import Reflection from '@/stores/models/reflection'

const title = 'Yellow Cards'
const html = `<div>I recently joined a 9v9 soccer league.</div><div><br/></div><div>This past Sunday I had to come out of our second game after a defender barreled into my right side, striking my jaw with his arm.</div><div><br/></div><div>I heard a pop and my teeth felt a bit misaligned.</div><div><br/></div><div>This was just a casual league and we didn't have any previous altercations, so why was this guy so violent?</div><div><br/></div><div>Sometimes yellow cards are warranted, to save a goal for instance.</div><div><br/></div><div>This one wasn't.</div><div><br/></div><div>Don't be that guy.</div>`

export default new Reflection({
  html,
  id: 'yellow-cards',
  tags: null,
  title,
  words: 82,
  written: '9/20/22'
})
