import Reflection from '@/stores/models/reflection'

const title = 'The Metaverse'
const html = `<div>Yesterday, Facebook announced a reorg establishing a new Metaverse product group.</div><div><br/></div><div>I'm thankful for the opportunity to be on a team that's helping to build towards the vision.</div><div><br/></div><div>The Metaverse (beyond FB's role in it) will not be only a virtual reality like <i>Ready Player One</i>.</div><div><br/></div><div>AR/VR will intertwine the physical and the digital.</div><div><br/></div><div>You'll be able to access it via headset or web browsers, including mobile phones.</div><div><br/></div><div>Creators will foster its economy and it'll support any imaginable service that doesn't require physical colocation.</div><div><br/></div><div>Matthew Ball, a venture capitalist, has a great <a href="https://www.matthewball.vc/the-metaverse-primer" rev="en_rl_none">9-part essay</a> describing the future of the Metaverse.</div><div><br/></div><div>The interoperability and open standards of virtual worlds will be critical in making the best ecosystem for users, creators, and developers.</div><div><br/></div><div>AR/VR technology is still in its early days.</div><div><br/></div><div>20 years ago, high-speed internet was below 1Mbps.</div><div><br/></div><div>5G networks today deliver download speeds over 1 Gbps, a 1000x improvement — not to mention without wires.</div><div><br/></div><div>What will the Metaverse look like in 20 years?</div><div><br/></div><div><br/></div>`

export default new Reflection({
  html,
  id: 'the-metaverse',
  tags: null,
  title,
  words: 164,
  written: '7/27/21'
})
