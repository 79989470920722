import Reflection from '@/stores/models/reflection'
import {WRITING} from '@/tags'

const title = 'Monday Musings'
const html = `<div><a href="https://perell.com/" rev="en_rl_none">David Perell</a> just sent the <a href="https://ckarchive.com/b/8kuqhoh0xoox9" rev="en_rl_none">last edition</a> of Monday Musings, an email newsletter he's sent weekly since March 2018.</div><div><br/></div><div>He's been advocating and teaching online writing for years.</div><div><br/></div><div>His success with his online writing course, <a href="https://writeofpassage.school/" rev="en_rl_none">Write of Passage</a>, makes keeping up the arbitrary weekly deadline counterproductive.</div><div><br/></div><div>While I haven't participated in the course (at least not yet), he inspired me to improve my writing skills.</div><div><br/></div><div>I write these reflections every week in part due to his advocacy that everyone should write online.</div><div><br/></div><div>David has written a lot about the "<a href="https://ageofleverage.com/" rev="en_rl_none">age of leverage</a>."</div><div><br/></div><div>Maximizing leverage in output is the most productive use of time.</div><div><br/></div><div>Write once, share infinitely at zero marginal cost.</div><div><br/></div><div>Although David discontinued his Monday Musings newsletter, I'm positive he'll make a greater impact with the additional bandwidth.</div>`

export default new Reflection({
  html,
  id: 'monday-musings',
  tags: [WRITING],
  title,
  words: 129,
  written: '1/3/23'
})
