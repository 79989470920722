import Reflection from '@/stores/models/reflection'
import { SYSTEMS } from '@/tags'

const title = 'Inversion'
const html = '<div>“If every instinct you have is wrong, then the opposite would have to be right.”</div><div><br /></div><div>In the classic Seinfeld episode, Jerry convinces George that he should do the exact opposite of what he would naturally do.</div><div><br /></div><div>Although the show is a comedy, we can learn a lot from the principle of inversion.</div><div><br /></div><div>Think about things in reverse.</div><div><br /></div><div>Counterintuitive ideas can be powerful.</div><div><br /></div><div>Fear dissolves by stepping into it.</div><div><br /></div><div>Embarrassment disappears when you acknowledge and own its source.</div><div><br /></div><div>Impostor syndrome erodes when you disclose it.</div><div><br /></div><div>Vulnerability becomes strength.</div><div><br /></div><div>Visualize the future as you want it to be and define the steps necessary to get there.</div><div><br /></div><div>When creating something, envision the final product and work backwards.</div><div><br /></div><div>Some steps may be hidden at first which seem obvious when backtracking.</div><div><br /></div><div>Charlie Munger, vice chairman of Berkshire Hathaway, spoke about the company’s success:</div><div><br /></div><div>"We look like people who have found a trick. It’s not brilliance. It’s just avoiding stupidity.”</div><div><br /></div><div>When making decisions for a best path forward, a great strategy is to think of all the bad paths and to simply avoid them.</div><div><br /></div><div>George Costanza ends up getting a job for the New York Yankees through his antics.</div><div><br /></div><div>Although you may not see immediate results like George, we should all follow Charlie Munger’s advice by thinking like the mathematician Carl Jacobi:</div><div><br /></div><div>“Invert. Always invert."</div>'

export default new Reflection({
  html,
  id: 'inversion',
  tags: [SYSTEMS],
  title,
  words: 219,
  written: '8/13/19'
})
