import Reflection from '@/stores/models/reflection'

const title = 'Choosing Media'
const html = `<div>The U.S. has never been more polarized.</div><div><br/></div><div>People may blame Facebook, Twitter, or TV networks, but digital media just exposed the division, it didn't create it.</div><div><br/></div><div>Social media puts you in a filter bubble if you only follow people you agree with, but you have a choice.</div><div><br/></div><div>Depending on who you pay attention to, you can believe any perspective of a story.</div><div><br/></div><div>We tend to anchor our beliefs on the first thing we hear.</div><div><br/></div><div>If you only watch CNN, you probably believe most of what they say.</div><div><br/></div><div>Likewise with Fox News.</div><div><br/></div><div>Personally, I avoid mass media news networks.</div><div><br/></div><div>I don't want to give the executives write-access to my brain.</div><div><br/></div><div>I look for varying opinions around the political spectrum.</div><div><br/></div><div>Different mediums also make a difference.</div><div><br/></div><div>Long form podcasts have much more nuance than a tweet.</div><div><br/></div><div>If you're going to engage with the news, don't limit yourself to one side of the story.</div>`

export default new Reflection({
  html,
  id: 'choosing-media',
  tags: null,
  title,
  words: 151,
  written: '11/23/21'
})
