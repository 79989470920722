import React, { Component } from 'react'

import './ContactPage.scss'

export default class ContactPage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }
  render () {
    return (
      <div className='ContactPage'>
        <h1>Contact Me</h1>
        <p>Email form coming soon...</p>
        <p>For now, you can DM me on <a target='_blank' href='https://twitter.com/zachagallagher'>Twitter</a>.</p>
      </div>
    )
  }
}
