import Reflection from '@/stores/models/reflection'
import {BUSINESS} from '@/tags'

const title = 'Punching Clocks'
const html = `<div>In summer 2014 I had to document my internship hours in SAP every day.</div><div><br/></div><div>I'd record what time I arrived, how long of a lunch break I took, what time I left, and how much of that time I spent on specific projects.</div><div><br/></div><div>The system allocated every hour to a project so management can attempt to see which ones are over or under budget.</div><div><br/></div><div>Bureaucracy and control may work in the short term, but they add friction and brittleness.</div><div><br/></div><div>Red tape will suffocate your organization.</div>`

export default new Reflection({
  html,
  id: 'punching-clocks',
  tags: [BUSINESS],
  title,
  words: 85,
  written: '11/8/22'
})
