import Reflection from '@/stores/models/reflection'
import {MILITARY} from '@/tags'

const title = 'Russian Invasion of Ukraine'
const html = `<div>It feels weird to live life as usual while there's an ongoing full-scale war on the other side of the globe.</div><div><br/></div><div>On February 24, Russia invaded Ukraine in what was the largest combat operation in Europe since World War II.</div><div><br/></div><div>While Ukrainians fight to defend their homeland and civilians die in the crossfire, people in the West have the privilege to go about their lives relatively carefree.</div><div><br/></div><div>Nearly all problems are trivial when compared to war.</div><div><br/></div><div>Although there's usually a deemed winner, both sides lose in war.</div><div><br/></div><div>One can only hope that this conflict is a short chapter and not the beginning of a longer story.</div><div><br/></div><div>Glory to Ukraine.</div>`

export default new Reflection({
  html,
  id: 'russian-invasion-of-ukraine',
  tags: [MILITARY],
  title,
  words: 109,
  written: '3/1/22'
})
