import Reflection from '@/stores/models/reflection'

const title = 'Monkey See, Monkey Do'
const html = `<div>Humans naturally form tribes.</div><div><br/></div><div>Subconscious processes drive most of our behavior.</div><div><br/></div><div>We don't choose the thoughts that arise into consciousness.</div><div><br/></div><div>Some people even deny the theory of free will.</div><div><br/></div><div>The majority of people imitate others — in style, speech, beliefs, behavior, etc.</div><div><br/></div><div>To be an independent thinker, you have to deliberately oppose what you see and hear, and view the world from a detached perspective.</div>`

export default new Reflection({
  html,
  id: 'monkey-see-monkey-do',
  tags: null,
  title,
  words: 65,
  written: '11/30/21'
})
