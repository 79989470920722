import types from './types'

const contentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CACHE_BLOG_POST:
      const { slug } = action.payload
      const blogPosts = state.blogPosts || {}
      if (blogPosts[slug]) {
        const { body } = action.payload
        blogPosts[slug].addBody(body)
      } else {
        blogPosts[slug] = action.payload
      }
      return { ...state, blogPosts }
    case types.CACHE_BLOG_POSTS:
      const cachedBlogPosts = state.blogPosts || {}
      const dispatchedBlogPosts = action.payload
      for (let i = 0; i < action.payload.length; i++) {
        if (!cachedBlogPosts[dispatchedBlogPosts[i].slug]) {
          cachedBlogPosts[dispatchedBlogPosts[i].slug] = dispatchedBlogPosts[i]
        }
      }
      return { ...state, blogPosts: cachedBlogPosts }
  }
  return state
}

export default contentReducer
