import Reflection from '@/stores/models/reflection'

const title = 'Curation'
const html = `<div>I just counted the number of podcasts that I subscribe to, and the total was startling: 38.</div><div><br/></div><div>Although I delete a fair share of episodes, I consistently sort and get through the queue.</div><div><br/></div><div>Among the 700,000+ podcasts, selecting 38 is not straightforward.</div><div><br/></div><div>Finding signal in the noise is getting difficult as more data flows onto the internet.</div><div><br/></div><div>With the sheer extent of possibilities, it's helpful to ask yourself whether something is in line with your values and guides you forward, or if it detracts you.</div><div><br/></div><div>The continual release of massive amounts of new media creates a gravity that pulls you in to stay up-to-date with the latest movements.</div><div><br/></div><div>Resisting the enticing distractions is crucial if you want to make progress on the things that matter most to you.</div><div><br/></div><div>Sure you can get daily news from Fox News, MSNBC, or CNN, but are they adding substantial value to your life?</div><div><br/></div><div>Personally, I ignore the daily news.</div><div><br/></div><div>If something is important enough, I’ll hear about it.</div><div><br/></div><div>After reading about the <a href="https://en.wikipedia.org/wiki/Lindy_effect">Lindy effect</a> in Nassim Taleb’s <span style="font-style: italic;">Antifragile</span>, I began to focus on older, well established books.</div><div><br/></div><div>To reconcile the fact that there are too many books to read, I’ve found Tim Ferriss' strategy for book selection to be helpful: focus on just-in-time information, not just-in-case information.</div><div><br/></div><div>If it isn't applicable in the short term, ignore it.</div><div><br/></div><div>Saying yes to one thing means saying no to another.</div><div><br/></div><div>Choose what fulfills you.</div>`

export default new Reflection({
  html,
  id: 'curation',
  tags: null,
  title,
  words: 243,
  written: '12/11/19'
})
