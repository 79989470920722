import Reflection from '@/stores/models/reflection'
import { ENVIRONMENT } from '@/tags'


const title = 'The Grand Canyon'
const html = `<img src="https://i.imgur.com/qBut0LQ.jpg"> <p>I spent the last few days at the Grand Canyon.</p> <p>I had heard it&apos;s beautiful but I didn&apos;t realize how magnificent it is until I saw it.</p> <p>It&apos;s truly breathtaking.</p> <p>When you&apos;re in such a vast, expansive place, you realize just how small you are.</p> <p>One fascinating thing about the Grand Canyon is the &quot;Great Unconformity,&quot; a 1.2 billion year gap between two adjacent rocks.</p> <p>Scientists are still unable to explain how the unconformity exists.</p> <p>The most interesting thing about the universe is its mystery.</p>`

export default new Reflection({
  html,
  id: 'grand-canyon',
  tags: [ENVIRONMENT],
  title,
  words: 85,
  written: '11/26/20'
})
