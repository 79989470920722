import Reflection from '@/stores/models/reflection'
import {PHYSICS} from '@/tags'

const title = 'Analog Computing'
const html = `<div>This Sunday I met up with a couple people in LA from <a href="https://zachgallagher.com/reflections/2022/the-latticework">The Latticework</a>.</div><div><br/></div><div>We talked about crypto, AI, and recent technological developments.</div><div><br/></div><div>One of the guys mentioned analog computers and how they can perform computations faster and more efficiently than digital computers.</div><div><br/></div><div>He sent me <a href="https://www.youtube.com/watch?v=GVsUOuSjvcg" rev="en_rl_none">this YouTube video</a> from the channel Veritasium about analog computing and the company <a href="https://mythic.ai/" rev="en_rl_none">Mythic</a>.</div><div><br/></div><div>Using Ohm's law, analog chips perform multiplication by applying a voltage through configurable resistors.</div><div><br/></div><div>The resulting currents mirror a dot product operation.</div><div><br/></div><div>Each column of cells in series add up to an output current value.</div><div><br/></div><div>The technology is perfect for deploying trained neural networks.</div><div><br/></div><div>Mythic's chips perform matrix multiplication by tuning each solid state cell's resistance according to the weights of a neural network.</div><div><br/></div><div>Instead of running trillions of math operations using 1's, 0's, and logic gates, they leverage the physics-based multiplication of Ohm's law.</div><div><br/></div><div>For machine vision applications that process raw video data, the energy savings is significant.</div><div><br/></div><div>The 21st century brought the digital computing revolution, and the last 20 years of technological innovations have transformed society.</div><div><br/></div><div>The change in the next 20 years will be even more massive.</div><div><br/></div><div>Analog computing and perhaps quantum computing may bring even greater step changes.</div>`

export default new Reflection({
  html,
  id: 'analog-computing',
  tags: [PHYSICS],
  title,
  words: 190,
  written: '4/26/22'
})
