import React, { Component } from 'react'
import {
  Footer,
  Header,
  MobileSideNav
} from '@/components'
import throttle from 'lodash/throttle'
import { addClass, removeClass } from '@/utils/helpers'

import './AppLayout.scss'

const noScrollClass = 'MobileSideNav__noScroll'

export default class AppLayout extends Component {
  state = {
    isOpenMobileSideNav: false
  }
  componentDidMount () {
    window.addEventListener('scroll', this.resizeHeaderOnScroll)
    let ua = navigator && navigator.userAgent
    if (ua) {
      let iOS = /iPad|iPhone|iPod/.test(ua)
      if (iOS) {
        addClass(document.documentElement, 'iOS')
        addClass(document.body, 'iOS')
      }
    }
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.resizeHeaderOnScroll)
  }
  onCloseMobileSideNav = () => {
    removeClass(document.documentElement, noScrollClass)
    removeClass(document.body, noScrollClass)
    this.setState({ isOpenMobileSideNav: false })
    this.scrollY(null, this.scrollTop)
  }
  openMobileSideNav = () => {
    this.setScrollTop()
    addClass(document.documentElement, noScrollClass)
    addClass(document.body, noScrollClass)
    this.setState({ isOpenMobileSideNav: true })
  }
  resizeHeaderOnScroll = throttle(() => {
    const headerEl = document.querySelector('.Header')
    const layoutEl = document.querySelector('.AppLayout')
    const shrinkOn = 40
    const distanceY = window.pageYOffset || document.documentElement.scrollTop
    const shrunkClass = 'is-shrunk'
    if (distanceY > shrinkOn) {
      headerEl.classList.add(shrunkClass)
      layoutEl.classList.add(shrunkClass)
    } else {
      headerEl.classList.remove(shrunkClass)
      layoutEl.classList.remove(shrunkClass)
    }
  }, 250)
  setScrollTop = () => {
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
  }
  scrollY = (el, scrollTop = 0) => {
    if (!el) {
      setTimeout(() => {
        document.documentElement.scrollTo(0, scrollTop)
        document.body.scrollTo(0, scrollTop)
        this.setScrollTop(scrollTop)
      }, 0)
    } else {
      el.scrollTo(0, scrollTop)
    }
  }
  render () {
    const { isOpenMobileSideNav } = this.state
    return (
      <div className='AppLayout'>
        <Header openMobileSideNav={this.openMobileSideNav} />
        <MobileSideNav isOpen={isOpenMobileSideNav} onClose={this.onCloseMobileSideNav} />
        {this.props.children}
        <Footer />
      </div>
    )
  }
}
