import Reflection from '@/stores/models/reflection'

const title = 'Impermanence'
const html = `<div>Everything is temporary.</div><div><br/></div><div>Thoughts appear and fade away.</div><div><br/></div><div>Waves crash on the ocean shores, each a unique combination of molecules never to repeat again.</div><div><br/></div><div>The Sun emits energy from a limited supply of hydrogen atoms.</div><div><br/></div><div>Everything comes to an end.</div><div><br/></div><div>I keep impermanence on my mind every day by hanging a Kobe jersey in my room, which serves as both a reminder of death and a call to seize the day.</div><div><br/></div><div>Don’t take today for granted.</div>`

export default new Reflection({
  html,
  id: 'impermanence',
  tags: null,
  title,
  words: 76,
  written: '6/17/20'
})
