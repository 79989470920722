import Reflection from '@/stores/models/reflection'

const title = 'Gaming'
const html = `<div>I grew up playing video games.</div><div><br/></div><div>Pokemon.</div><div><br/></div><div>Sonic the Hedgehog.</div><div><br/></div><div>Zelda.</div><div><br/></div><div>Counter Strike.</div><div><br/></div><div>Metal Gear Solid.</div><div><br/></div><div>Crash Bandicoot.</div><div><br/></div><div>Mega Man Battle Network.</div><div><br/></div><div>Worms Armageddon.</div><div><br/></div><div>Super Smash Bros.</div><div><br/></div><div>Paper Mario.</div><div><br/></div><div>Mario Kart.</div><div><br/></div><div>Mario Golf.</div><div><br/></div><div>Tony Hawk's Pro Skater.</div><div><br/></div><div>NBA Street.</div><div><br/></div><div>Halo.</div><div><br/></div><div>Grand Theft Auto.</div><div><br/></div><div>Maplestory.</div><div><br/></div><div>Gunbound.</div><div><br/></div><div>Call of Duty.</div><div><br/></div><div>FIFA.</div><div><br/></div><div>Madden.</div><div><br/></div><div>MVP Baseball.</div><div><br/></div><div>NBA 2K.</div><div><br/></div><div>Guitar Hero.</div><div><br/></div><div>Rock Band.</div><div><br/></div><div>These are just a handful of the memorable names.</div><div><br/></div><div>I spent a lot of time gaming, to say the least.</div><div><br/></div><div>These days when I'm not working I focus more on reading books though.</div><div><br/></div><div>I don't remember the last time I played a game longer than a FIFA match.</div><div><br/></div><div>I may have grown out of the video game stage of my life, but the gaming industry is stronger than ever.</div><div><br/></div><div>Graphics are becoming lifelike, Esports has more fans than the MLB and NBA, and VR is just beginning to take off.</div><div><br/></div><div>New business models using blockchain technology, like Axie Infinity's play-to-earn model, are revolutionizing the industry.</div><div><br/></div><div>The metaverse will host a new economy with gaming as a core pillar.</div>`

export default new Reflection({
  html,
  id: 'gaming',
  tags: null,
  title,
  words: 176,
  written: '9/14/21'
})
