import Reflection from '@/stores/models/reflection'

const title = 'Depersonalization'
const html = `<div>I first experienced a severe anxiety symptom called depersonalization when I was in high school.</div><div><br/></div><div>I would have full-blown panic attacks that also caused a state of derealization.</div><div><br/></div><div>I still occasionally feel dissociated and detached, but I've learned to breathe through the experience and cope.</div><div><br/></div><div>After reading <i>The Body Keeps the Score</i> earlier this year, I've been looking for an EMDR practitioner so I can get to the root of the problem and truly heal.</div><div><br/></div><div>My journey with EMDR began a couple weeks ago.</div><div><br/></div><div>Over the past 10+ years I've overcome a lot, but I still have a ways to go.</div>`

export default new Reflection({
  html,
  id: 'depersonalization',
  tags: null,
  title,
  words: 101,
  written: '10/25/22'
})
