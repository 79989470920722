import Reflection from '@/stores/models/reflection'

const title = 'Kunta Kinte'
const html = `<div>I just finished the book <span style="font-style: italic;">Roots: The Saga of an American Famil</span><i>y</i> by Alex Haley.</div><div><br/></div><div>If you’re unfamiliar, it tells the story of a Gambian named Kunta Kinte whom slave traders abduct, torture, and ship to America.</div><div><br/></div><div>It also inspired the popular miniseries in 1977, and another in 2016.</div><div><br/></div><div>The long narrative, from Kunta’s exciting childhood to his descendants’ entire lives as slaves, will make you feel every emotion — anger, fear, disgust, sadness, and occasional joy.</div><div><br/></div><div>One clear theme is the importance of tradition.</div><div><br/></div><div>The griots of Western Africa, who maintained the history of their communities through storytelling, were among the most respected elders.</div><div><br/></div><div>Like griots, Kunta’s descendants always retold the story of his capture and enslavement to their children.</div><div><br/></div><div>Alex Haley’s drive to uncover and verify details of his ancestor’s story was due to the meaning his family placed in that tradition.</div><div><br/></div><div>Passing down stories from generation to generation creates meaning.</div><div><br/></div><div>Having just finished reading the book, I’ve been thinking more about being in other people’s shoes.</div><div><br/></div><div>Although modern America has evolved since the times of slavery, groups with us-vs-them mindsets still predominate.</div><div><br/></div><div>People are much more alike than we are different.</div><div><br/></div><div>Ignorance causes discrimination.</div><div><br/></div><div>We’re all from the same place, Earth.</div><div><br/></div><div>When the only stories that live are from the winners' perspectives, we lose a valuable piece of history.</div><div><br/></div><div>Value all perspectives.</div>`

export default new Reflection({
  html,
  id: 'kunta-kinte',
  tags: null,
  title,
  words: 226,
  written: '3/4/20'
})
